import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NavHeader, Page } from '../../../components';
import FixedTwoButtons from '../../../components/fixed-two-buttons';
import { IconUpdateSoftware } from '../../../components/icons/update-software';
import { ROUTES } from '../../../routes';
import { useBluetoothContext } from '../context';
import { UpdateDialog } from './components/update-dialog';
import './success.scss';

const DeviceAddBluetoothUpdate: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const [dialog, setDialog] = useState<boolean>(false);
  const { checkIfConnected, updateByWifi, updatePayload } = useBluetoothContext();

  useEffect(() => {
    checkIfConnected();
  }, []);

  const onUpdateSuccess = () => {
    setDialog(false);

    if (updatePayload.status) {
      history.push(ROUTES.DeviceUpdateBluetoothSuccess());
    }
  };

  const updateDevice = () => {
    updateByWifi();
    setDialog(true);
  };

  const cancel = useCallback(() => {
    history.go(-3);
  }, []);

  return (
    <Page className="bluetooth-success" header={<NavHeader />}>
      <div className="icon-container">
        <IconUpdateSoftware />
      </div>
      <div className="text-container">
        <p className="text-header">{t('addDeviceUpdate.header')}</p>
      </div>

      <FixedTwoButtons
        titlePrimary={t('addDeviceUpdate.update')}
        titleSecondary={tc('buttons.cancel')}
        handleClickPrimary={updateDevice}
        handleClickSecondary={cancel}
        separator={false}
      />
      <UpdateDialog show={dialog} onUpdateSuccess={onUpdateSuccess} />
    </Page>
  );
};

export default DeviceAddBluetoothUpdate;
