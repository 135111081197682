import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  CurrentWindThresholdDeviceState,
  WindSpeedDeviceState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { WindControlHook } from '../types';

export const useWindControl = (channel?: IDeviceChannel, disabled?: boolean): WindControlHook => {
  if (disabled) return { windThreshold: null, windSpeed: null, time: '' };

  const windThresholdData = useMemo(() => {
    const windState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.CurrentWindThreshold,
    ) as CurrentWindThresholdDeviceState;

    if (windState) {
      return windState.Data;
    }

    return null;
  }, [channel?.States]);

  const windSpeed = useMemo(() => {
    const windState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.WindSpeedState,
    ) as WindSpeedDeviceState;

    if (windState) {
      return windState.Data.Value;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (windThresholdData?.Time) return format(Date.parse(windThresholdData.Time), 'dd.MM, HH:mm');
    return '';
  }, [windThresholdData]);

  return {
    windThreshold: windThresholdData?.WindThreshold ?? null,
    windSpeed,
    time,
  };
};
