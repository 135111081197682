import React, { useState, PointerEvent, useEffect, useMemo } from 'react';
import { DeviceTaskType, IDeviceTaskTypeInfo } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { DeviceControlFeature } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import {
  BlindActionEnum,
  SetBlindPosition,
  SetBlindPositionSimple,
} from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { BlindChannelSettings } from '../../../../../../../action-create/components/channel-settings/blind';
import { canChannelDo } from '../../../../../../utils';
import { useExalusCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { BlindStateTask, DeviceTaskParams } from '../../../../types';

export const BlindSettings: React.FC = () => {
  const { channels } = useExalusCreateActionContext();
  const [blindAction, setBlindAction] = useState<BlindActionEnum>(BlindActionEnum.Open);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      const taskState = (task.taskParams as DeviceTaskParams).state as BlindStateTask<BlindActionEnum>;
      setBlindAction(taskState.action);

      switch (taskState.action) {
        case BlindActionEnum.Open:
          setSliderValue(0);
          break;
        case BlindActionEnum.Close:
          setSliderValue(100);
          break;
        default:
          setBlindAction(BlindActionEnum.Percentage);
          if (taskState.position !== null) setSliderValue(taskState.position);
      }
    }
  }, [task]);

  const isViewSimple = useMemo(
    () =>
      channels.some((channel) => {
        return (
          channel.AvailableTaskTypes?.any(
            (a: IDeviceTaskTypeInfo) => a.Type === DeviceTaskType.SetBlindPositionSimple,
          ) ||
          channel
            ?.GetDevice()
            .AvailableTaskTypes?.any((a: IDeviceTaskTypeInfo) => a.Type === DeviceTaskType.SetBlindPositionSimple)
        );
      }),
    [channels],
  );

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    setBlindAction(BlindActionEnum.Percentage);
    setSliderValue(Number((event.target as HTMLInputElement).value));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlindAction(BlindActionEnum.Percentage);
    setSliderValue(Number(event.target.value));
  };

  const handleConstPercentageChange = (pos: number) => {
    setBlindAction(BlindActionEnum.Percentage);
    setSliderValue(pos);
  };

  const handleSubmit = () => {
    let task: SetBlindPositionSimple | SetBlindPosition;

    for (let i = 0; i < channels.length; i++) {
      if (canChannelDo(channels[i], [DeviceTaskType.SetBlindPositionSimple])) {
        task = new SetBlindPositionSimple();
      } else task = new SetBlindPosition();
      task.DeviceGuid = channels[i].GetDevice().Guid;
      task.Channel = channels[i].Number;
      task.TaskType = DeviceTaskType.SetBlindPosition;
      task.FeatureType = DeviceControlFeature.SetBlindPosition;
      task.Action = blindAction;
      if (blindAction === BlindActionEnum.Percentage) task.Position = sliderValue;

      finalizeDeviceTaskSubmit(
        task,
        channels[i],
        { position: sliderValue, action: blindAction } as BlindStateTask<BlindActionEnum>,
        i === channels.length - 1,
      );
    }
  };

  return (
    <BlindChannelSettings
      kind="exalus"
      handleConstPercentageChange={handleConstPercentageChange}
      handleSliderEvent={handleSliderEvent}
      handleSubmit={handleSubmit}
      sliderValue={sliderValue}
      blindAction={blindAction}
      onChange={onChange}
      setBlindAction={setBlindAction}
      setSliderValue={setSliderValue}
      isViewSimple={isViewSimple}
    />
  );
};
