import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { NavHeader, Page } from '../../../components';
import { IconAddDevice } from '../../../components/icons/add-device';
import { ROUTES } from '../../../routes';
import { useBluetoothContext } from '../context';
import './success.scss';

const DeviceAddBluetoothSuccess: React.FC = () => {
  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();
  const { disconnect, releaseLock, returnToStartPage } = useBluetoothContext();
  const { t } = useTranslation('device-add');

  useEffect(() => {
    releaseLock();
    disconnect();
  }, []);

  const goToDeviceSettings = () => {
    history.push(ROUTES.DeviceSettings(deviceId));
  };

  const goToChannels = () => {
    history.push(ROUTES.Channel());
  };

  return (
    <Page
      containerClassName="page__container--flex-height"
      className="bluetooth-success"
      header={<NavHeader onClick={goToChannels} />}
    >
      <div>
        <div className="icon-container">
          <IconAddDevice />
        </div>
        <div className="text-container">
          <p className="text-header">{t('addDeviceSuccess.header')}</p>
          <p>{t('addDeviceSuccess.message')}</p>
        </div>
      </div>
      <div className="success-buttons">
        <button className="button button--secondary" onClick={returnToStartPage}>
          {t('addDeviceSuccess.addNext')}
        </button>
        <button className="button" onClick={goToDeviceSettings}>
          {t('addDeviceSuccess.goToDeviceSettings')}
        </button>
        <button className="text-button" onClick={goToChannels}>
          {t('addDeviceSuccess.ready')}
        </button>
      </div>
    </Page>
  );
};

export default DeviceAddBluetoothSuccess;
