import {
  BlindChannelStateResponse,
  ChannelTypeInternal,
  GateChannelStateResponse,
  LightChannelStateResponse,
  SwitchChannelStateResponse,
} from '../../../../data-access/gql-types/graphql';

export interface BindingListItem {
  inputTrigger: InputBindingTrigger | null;
  actionType: ActionBindingType | null;
  [ChannelActions.SetOnTimeValue]?: string;
  [ChannelActions.Position]?: number;
  [ChannelActions.Dir]?: number;
  [ChannelActions.Brightness]?: number;
  [ChannelActions.Temperature]?: number;
  [ChannelActions.R]?: number;
  [ChannelActions.G]?: number;
  [ChannelActions.B]?: number;
}

export type ControllerInputPayload =
  | (SwitchChannelStateResponse & { id: string })
  | (LightChannelStateResponse & { id: string });
export type ControllerInputsPayload =
  | (BlindChannelStateResponse & { id: string })
  | (GateChannelStateResponse & { id: string });

export interface BindingsItem {
  channelId: string;
  inputId: string;
  slot: number | null;
  list: BindingListItem[];
}

export interface DeviceBindingsForm {
  bindings: BindingsItem[];
}

export enum InputBindingTrigger {
  SingleClick = 0,
  DoubleClick = 1,
}

export enum ActionBindingType {
  Default = 'default',
  Switch = 'Switch',
  Light = 'Light',
  Blind = 'Blind',
  Gate = 'Gate',
}

export const actionOptions = {
  [ChannelTypeInternal.Unknown]: [ActionBindingType.Default],
  [ChannelTypeInternal.Switch]: [ActionBindingType.Default, ActionBindingType.Switch],
  [ChannelTypeInternal.Light]: [ActionBindingType.Default, ActionBindingType.Light],
  [ChannelTypeInternal.Blind]: [ActionBindingType.Default, ActionBindingType.Blind],
  [ChannelTypeInternal.Gate]: [ActionBindingType.Default, ActionBindingType.Gate],
};

export enum ChannelActions {
  SetOnTimeValue = 'setOnTimeValue',
  Position = 'position',
  Dir = 'dir',
  Brightness = 'brightness',
  Temperature = 'temperature',
  R = 'r',
  G = 'g',
  B = 'b',
}

export enum LightActionTypeEnum {
  SetOnTimeValue = 'setOnTimeValue',
  RGB = 'rgb',
  Brightness = 'brightness',
  Temperature = 'temperature',
}

export enum GateActionTypeEnum {
  Position = 'setOnTimeValue',
  Direction = 'rgb',
}

export enum GateDirection {
  Unknown = 0,
  Stop = 1,
  Open = 2,
  Close = 3,
}

export const channelTypeForRequest = {
  [ChannelTypeInternal.Unknown]: 0,
  [ChannelTypeInternal.Switch]: 1,
  [ChannelTypeInternal.Blind]: 2,
  [ChannelTypeInternal.Gate]: 3,
  [ChannelTypeInternal.Light]: 4,
};
