import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/client';
import { useLightSupplyReturnBehaviour } from '../../../../../api/modules/light/light.hooks';
import { useSwitchSupplyReturnBehaviour } from '../../../../../api/modules/switch/switch.hooks';
import { InputSelect } from '../../../../../components';
import {
  ChannelTypeInternal,
  OnSupplyReturnBehaviorChangeSubscription,
  SupplyReturnBehaviorInternal,
  OnSupplyReturnBehaviorChangeSubscriptionVariables,
} from '../../../../../data-access/gql-types/graphql';
import { ON_SUPPLY_RETURN_BEHAVIOR_CHANGE } from '../../../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext, useChannelsState } from '../../../../../hooks';
import { useTimeout } from '../../../../../hooks/timeout/use-timeout';
import {
  ChannelInterface,
  ChannelLightStateInterface,
  ChannelSwitchStateInterface,
  convertedReturnBehavior,
} from '../../../../../types';
import { toastSuccess } from '../../../../../utils/toast';
import Info from '../info';

interface ComponentProps {
  channel: ChannelInterface;
}

const ReturnBehaviorSelect: React.FC<ComponentProps> = <
  T extends ChannelSwitchStateInterface | ChannelLightStateInterface,
>({
  channel,
}) => {
  const { t } = useTranslation('configuration');
  const { mutate: switchMutate } = useSwitchSupplyReturnBehaviour();
  const { mutate: lightMutate } = useLightSupplyReturnBehaviour();
  const { channelState, setChannelState } = useChannelsState();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const { data } = useSubscription<
    OnSupplyReturnBehaviorChangeSubscription,
    OnSupplyReturnBehaviorChangeSubscriptionVariables
  >(ON_SUPPLY_RETURN_BEHAVIOR_CHANGE, {
    variables: { channelId: (channel as ChannelInterface)?.id },
    skip: !channel,
  });

  useEffect(() => {
    if (data?.onSupplyReturnBehaviorChange) {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channel.id] = {
          ...newState[data.onSupplyReturnBehaviorChange.channelId],
          supplyReturnBehavior: data.onSupplyReturnBehaviorChange.supplyReturnBehavior,
        } as T;

        return newState;
      });

      turnOffBackdrop();
      clearTimeoutError();
      toastSuccess({ content: t('returnBehavior.behaviorSaved') });
    }
  }, [data]);

  const onChangeReturnBehavior = (value: SupplyReturnBehaviorInternal) => {
    if (channel.data.type === ChannelTypeInternal.Switch) {
      turnOnBackdrop();

      switchMutate(
        { channelId: channel.id, deviceId: channel.deviceId, value: convertedReturnBehavior[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    } else if (channel.data.type === ChannelTypeInternal.Light) {
      turnOnBackdrop();

      lightMutate(
        { channelId: channel.id, deviceId: channel.deviceId, value: convertedReturnBehavior[value] },
        {
          onSuccess: () => {
            setTimeoutError();
          },
        },
      );
    }
  };

  const value = useMemo(() => {
    return (channelState[channel.id] as T).supplyReturnBehavior;
  }, [(channelState[channel.id] as T).supplyReturnBehavior]);

  return (
    <>
      <Info title={t('returnBehavior.label')} />
      <InputSelect
        value={value}
        onChange={onChangeReturnBehavior}
        options={[
          { label: t('returnBehavior.options.AlwaysOff'), value: SupplyReturnBehaviorInternal.AlwaysOff },
          { label: t('returnBehavior.options.AlwaysOn'), value: SupplyReturnBehaviorInternal.AlwaysOn },
          { label: t('returnBehavior.options.KeepState'), value: SupplyReturnBehaviorInternal.KeepState },
        ]}
        label={''}
      />
    </>
  );
};

export default ReturnBehaviorSelect;
