import { EnvironmentType, IntegrationDefaultEnum } from './types';
import { isLocalhost } from './utils/helpers/environment';

export const environment: EnvironmentType = {
  ENV: 'development',
  PUBLIC_URL: !isLocalhost ? window.configuration.PUBLIC_URL || window.location.hostname : `//localhost:3000`,
  VERSION: window.configuration.VERSION || '',
  API_URL: window.configuration.API_URL || '',
  KEYCLOAK_CHANGE_PASSWORD_URL: window.configuration.KEYCLOAK_CHANGE_PASSWORD_URL || '',
  KEYCLOAK_URL: window.configuration.KEYCLOAK_URL || '',
  KEYCLOAK_CLIENT_ID: window.configuration.KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM: window.configuration.KEYCLOAK_REALM,
  WS_URL: window.configuration.API_WS_URL || '',
  WSS_URL: window.configuration.API_WSS_URL || '',
  UPLOAD_PHOTO: window.configuration.UPLOAD_PHOTO || '',
  USER_REPORT_URL: 'https://sak.userreport.com/lavva/launcher.js' || '',
  USER_REPORT_ID: 'userreport-launcher-script' || '',
  API_LAVVA_MANAGER: window.configuration.API_LAVVA_MANAGER || '',
  API_LAVVA_MANAGEMENT: window.configuration.API_LAVVA_MANAGEMENT || '',
  MAPS_API_KEY: window.configuration.MAPS_API_KEY || '',
  INTEGRATION_DEFAULT:
    (window.configuration.INTEGRATION_DEFAULT as IntegrationDefaultEnum) || IntegrationDefaultEnum.Lavva,
  NATIVE_APP_ID: window.configuration.NATIVE_APP_ID || '',
  DISCOVERY_URL: window.configuration.DISCOVERY_URL || '',
};
