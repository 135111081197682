import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../../../components';
import ConditionListItem from '../../../../../trigger-form/components/condition-list-item';
import { useActionConditions } from '../../hooks/use-action-conditions';
import { actionConditionTypes } from '../../utils/action-conditions';

const ExalusActionConditionsTypes: React.FC = () => {
  const { t } = useTranslation('action');
  const { selectConditionType } = useActionConditions();

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.conditions.selectCondition')} />
        </>
      }
    >
      <div className="grid-list-24">
        {actionConditionTypes.map((x) => (
          <ConditionListItem
            key={x.title}
            title={t(x.title)}
            content={t(x.content)}
            icon={x.icon}
            className="m-b-24"
            disabled={x.disabled}
            onClick={() => selectConditionType(x.type)}
            noBackgroundIcon
          />
        ))}
      </div>
    </Page>
  );
};

export default ExalusActionConditionsTypes;
