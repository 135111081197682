import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { DragAndDrop, dragOverlayStyle, GroupItem, GroupItemSortable, SubmitButton } from '../../../components';
import {
  ChangeGroupsOrderMutation,
  ChangeGroupsOrderMutationVariables,
  UserGroup,
} from '../../../data-access/gql-types/graphql';
import { CHANGE_GROUPS_ORDER } from '../../../data-access/mutations/groups';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { useRefetchData } from '../../../hooks/refresh-data';

interface PropsInterface {
  sortableGroups: UserGroup[];
  setSortableGroups: (value: UserGroup[]) => void;
}

export const GroupSortBody: React.FC<PropsInterface> = ({ sortableGroups, setSortableGroups }) => {
  const history = useHistory();
  const [isValid, setIsValid] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { selectedInstallationId } = useInstallation();
  const { refetchDashboard } = useRefetchData();
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);

  const [changeDashboardGroupOrder, { loading }] = useMutation<
    ChangeGroupsOrderMutation,
    ChangeGroupsOrderMutationVariables
  >(CHANGE_GROUPS_ORDER);

  const onDragStart = useCallback((event: dndCore.DragStartEvent) => setDndActiveId(event.active.id), [setDndActiveId]);

  const onDragEnd = useCallback(
    (event: dndCore.DragEndEvent) => {
      if (!event.active || !event.over) {
        return;
      }

      if (event.active.id !== event.over.id && sortableGroups) {
        const newList = Array.from(sortableGroups);
        const oldIndex = newList.findIndex((group: UserGroup) => group.id === event.active.id);
        const newIndex = newList.findIndex((group: UserGroup) => group.id === event.over?.id);

        const sortedGroups = dndSortable.arrayMove(newList, oldIndex, newIndex);
        setSortableGroups(sortedGroups);
      }

      setIsValid(true);
      setDndActiveId(null);
    },
    [sortableGroups],
  );

  const onSave = useCallback(() => {
    if (!sortableGroups || sortableGroups.length === 0) return;
    turnOnBackdrop();
    const dashboardGroupInput = sortableGroups.map((el, index) => ({ groupId: el.id, order: index }));
    changeDashboardGroupOrder({
      variables: {
        input: {
          installationId: selectedInstallationId,
          groups: dashboardGroupInput,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data.changeGroupsOrder.id) {
          refetchDashboard();
          history.goBack();
        }
      },
      onError: () => turnOffBackdrop(),
    });
  }, [sortableGroups, changeDashboardGroupOrder]);

  return (
    <>
      <ul className="group-list grid-list-16">
        <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={sortableGroups}>
          {sortableGroups.map((group: UserGroup) => (
            <GroupItemSortable key={group.id} sortedGroupId={group.id} groups={sortableGroups} />
          ))}
          <dndCore.DragOverlay>
            {dndActiveId && <GroupItem style={dragOverlayStyle} id={dndActiveId} groups={sortableGroups} isDraggable />}
          </dndCore.DragOverlay>
        </DragAndDrop>
      </ul>
      <SubmitButton disabled={!isValid} isLoading={loading} onClick={onSave} />
    </>
  );
};
