import {
  IconHorizontalDoubleGate,
  IconHorizontalGate,
  IconVerticalGate,
  IconWoodenGate,
} from '../../../../../../components/icons';
import { AvailableIcon, ChannelIconTypeEnum } from '../../../../../../types';

export const gateIcons = (position?: number): AvailableIcon[] => [
  {
    iconName: ChannelIconTypeEnum.GATE,
    component: <IconVerticalGate position={position !== undefined ? position : 33} />,
    oldIconName: 'DEVICE_ICON_41',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HORIZONTAL_GATE,
    component: <IconHorizontalGate position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_18',
  },
  {
    iconName: ChannelIconTypeEnum.GATE,
    component: <IconWoodenGate position={position !== undefined ? position : 50} />,
    oldIconName: 'DEVICE_ICON_16',
  },
  {
    iconName: ChannelIconTypeEnum.ICON_HORIZONTAL_DOUBLE_GATE,
    component: <IconHorizontalDoubleGate position={position !== undefined ? position : 33} />,
  },
];
