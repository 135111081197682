import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChannelBox, CustomBackdrop, InputEdit, Switch } from '../../../../components';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';
import { useGateSupportedFeatures } from '../../../channel-details/gate/hooks/use-gate-supported';
import { Calibration } from '../../../device-settings/device-settings-form/sections';
import { useConfiguration } from '../../hooks/use-configuration';
import { InfoPageType } from '../../types';
import Info from '../components/info';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const GateConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel } = useDevicesAndChannels({ channelId });
  const [inputDisabled, setInputDisabled] = useState<boolean>(true);
  const [pinActive, setPinActive] = useState<boolean>(false);
  const [pin, updatePin] = useState<string>('0000');
  const { calibrationSupported } = useGateSupportedFeatures(channel);
  const { editLoading } = useConfiguration({
    channel,
  });

  const togglePin = () => {
    setPinActive(pinActive);
    //TODO Save
  };

  const handlePinUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) return;
    updatePin(e.target.value);
  };

  const handleSavePin = () => {
    alert(`TODO ${pin}`);
  };

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <hr />

      <Info title={t('security')} />
      <div className="row_container m-b-24">
        <p>{t('blockByPin')}</p>
        <Switch checked={pinActive} onChange={togglePin} />
      </div>
      <InputEdit
        defaultValue={pin}
        onChange={handlePinUpdate}
        label={t('passcode')}
        disabled={inputDisabled}
        setDisabled={setInputDisabled}
        inputType={inputDisabled ? 'password' : 'text'}
        onSave={handleSavePin}
      />

      {calibrationSupported && (
        <>
          <hr />
          <Info title={t('calibration')} onClick={() => openInfoPage(InfoPageType.CALIBRATION)} />
          <Calibration channel={channel} />
        </>
      )}
      {editLoading && <CustomBackdrop loading />}
    </>
  );
};

export default GateConfiguration;
