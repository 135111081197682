import { TFunction } from 'react-i18next';
import { NotificationCode, Notification } from '../../../data-access/gql-types/graphql';
import {
  NotificationInterface,
  NotificationInvitationType,
  NotificationOtherInterface,
  NotificationTypeEnum,
} from '../../../types';

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const ONE_MONTH_IN_MS = ONE_DAY_IN_MS * 30;

export const addedOrRemovedOrExpired = [
  NotificationCode.DeviceRemovedOrFactoryReset,
  NotificationCode.DeviceAdded,
  NotificationCode.ChannelsAccessAdded,
  NotificationCode.ChannelsAccessRemoved,
  NotificationCode.ChannelsAccessExpired,
  NotificationCode.GroupAccessAdded,
  NotificationCode.GroupAccessRemoved,
  NotificationCode.GroupsAccessExpired,
  NotificationCode.InstallationAccessChanged,
  NotificationCode.RemovedFromInstallation,
  NotificationCode.UserInstallationExpired,
  NotificationCode.PublicGroupAdded,
  NotificationCode.PublicGroupChanged,
  NotificationCode.PublicGroupRemoved,
  NotificationCode.ActionCreated,
  NotificationCode.InstallationPassed,
  NotificationCode.ResourceAccessModified,
];

export const prepareOtherNotification = (
  receivedNotification: Notification,
  title: string,
  message: string,
): NotificationInterface => {
  const createdTimestampUTC = new Date(receivedNotification.createdAt).getTime();

  return {
    id: receivedNotification.correlationId,
    wasDisplayed: receivedNotification.wasDisplayed,
    isHidden: receivedNotification.isHidden,
    createdTimestampUTC: createdTimestampUTC,
    data: {
      type: NotificationTypeEnum.OTHER,
      params: receivedNotification.params,
      title,
      message,
    } as NotificationOtherInterface,
  };
};

export const getInvitationType = (code: NotificationCode): NotificationInvitationType | undefined => {
  switch (code) {
    case NotificationCode.InvitationReceived:
      return NotificationInvitationType.InvitationReceived;
    case NotificationCode.InvitationAcceptedByInvitee:
      return NotificationInvitationType.InvitationAcceptedByInvitee;
    case NotificationCode.InvitationRejectedByInvitee:
      return NotificationInvitationType.InvitationRejectedByInvitee;
    case NotificationCode.InvitationCanceledByCreator:
      return NotificationInvitationType.InvitationCanceledByCreator;
    default:
      // console.warn('Invitation code notification is not supported:', code);
      return undefined;
  }
};

export const getInvitationId = (receivedNotification: Notification) =>
  receivedNotification.params.find((x) => x.name === 'InvitationId')?.value;

export const mapNotification = (receivedNotification: Notification, t: TFunction<'notifications', undefined>) => {
  const createdTimestampUTC = new Date(receivedNotification.createdAt).getTime();

  if (receivedNotification.code.startsWith('INVITATION')) {
    const invitationType = getInvitationType(receivedNotification.code);
    if (!invitationType) return null;
    const invitationId = getInvitationId(receivedNotification);

    if (invitationId) {
      return {
        id: receivedNotification.correlationId,
        isHidden: receivedNotification.isHidden,
        wasDisplayed: receivedNotification.wasDisplayed,
        createdTimestampUTC: createdTimestampUTC,
        data: {
          type: NotificationTypeEnum.INVITATION,
          invitationType,
          invitationId,
          params: receivedNotification.params,
          userName: receivedNotification.params.find((x) => x.name === 'SenderName')?.value || '',
          installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
        },
      } as NotificationInterface;
    } else {
      console.warn('Invitation id not found:', receivedNotification);
      return null;
    }
  } else {
    switch (receivedNotification.code) {
      case NotificationCode.RemovedFromInstallation:
      case NotificationCode.UserInstallationExpired: {
        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
          }),
        ) as NotificationInterface;
      }
      case NotificationCode.InstallationAccessChanged: {
        const newAccessType = receivedNotification.params.find((x) => x.name === 'NewAccessType')?.value;

        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
            newAccessType: newAccessType ? t(`${newAccessType}`) : '-',
          }),
        );
      }
      case NotificationCode.InstallationPassed: {
        const newAccessType = receivedNotification.params.find((x) => x.name === 'NewAccessType')?.value;

        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
            newAccessType: newAccessType ? t(`${newAccessType}`) : '-',
          }),
        );
      }
      case NotificationCode.ChannelsAccessAdded:
      case NotificationCode.ChannelsAccessRemoved:
      case NotificationCode.ChannelsAccessExpired:
      case NotificationCode.DeviceAdded: {
        const channels = receivedNotification.params
          .filter((x) => x.name.startsWith('Channels_') && x.name.endsWith('_Alias'))
          .map((x) => x.value)
          .join(', ');

        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            channels,
            installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
          }),
        ) as NotificationInterface;
      }
      case NotificationCode.GroupAccessAdded:
      case NotificationCode.GroupAccessRemoved:
      case NotificationCode.GroupsAccessExpired: {
        const groups = receivedNotification.params
          .filter((x) => x.name.startsWith('Groups_') && x.name.endsWith('_Name'))
          .map((x) => x.value)
          .join(', ');
        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            groups,
            installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
          }),
        ) as NotificationInterface;
      }
      case NotificationCode.PublicGroupAdded:
      case NotificationCode.PublicGroupChanged:
      case NotificationCode.PublicGroupRemoved: {
        const groupName = receivedNotification.params.find((x) => x.name === 'GroupName')?.value;
        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            groupName: groupName || '',
          }),
        ) as NotificationInterface;
      }
      case NotificationCode.ResourceAccessModified: {
        const itemType = receivedNotification.params.find((x) => x.name.startsWith('ChannelsProperties_'))?.value
          ? t('channel')
          : t('group');

        let accessType = '';
        let accessValue = '';

        if (itemType === t('channel')) {
          if (
            receivedNotification.params.find((x) => x.name === 'ChannelsProperties_0_SharedFrom')?.value ||
            receivedNotification.params.find((x) => x.name === 'ChannelsProperties_0_SharedUntil')?.value
          ) {
            accessType = t('timeAccess');
            accessValue = `${
              receivedNotification.params
                .find((x) => x.name === 'ChannelsProperties_0_SharedFrom')
                ?.value?.split('+')[0]
            } - ${
              receivedNotification.params
                .find((x) => x.name === 'ChannelsProperties_0_SharedUntil')
                ?.value?.split('+')[0]
            }`;
          } else if (receivedNotification.params.find((x) => x.name === 'ChannelsProperties_0_GrantedUses')?.value) {
            accessType = t('grantedUsesAccess');
            accessValue =
              receivedNotification.params.find((x) => x.name === 'ChannelsProperties_0_GrantedUses')?.value || '';
          } else {
            accessType = t('permanentAccess');
          }
        } else {
          if (
            receivedNotification.params.find((x) => x.name === 'GroupsProperties_0_SharedFrom')?.value ||
            receivedNotification.params.find((x) => x.name === 'GroupsProperties_0_SharedUntil')?.value
          ) {
            accessType = t('timeAccess');
            accessValue = `${
              receivedNotification.params.find((x) => x.name === 'GroupsProperties_0_SharedFrom')?.value?.split('+')[0]
            } - ${
              receivedNotification.params.find((x) => x.name === 'GroupsProperties_0_SharedUntil')?.value?.split('+')[0]
            }`;
          } else {
            accessType = t('permanentAccess');
          }
        }

        return prepareOtherNotification(
          receivedNotification,
          t(`${receivedNotification.code}.title`),
          t(`${receivedNotification.code}.message`, {
            itemType,
            installationName: receivedNotification.params.find((x) => x.name === 'InstallationName')?.value || '',
            accessType,
            accessValue: accessValue ? `: ${accessValue}` : accessValue,
          }),
        ) as NotificationInterface;
      }
      default: {
        // console.warn('Notification code is not supported:', receivedNotification);
        return null;
      }
    }
  }
};
