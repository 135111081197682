import {
  ChannelTypeInternal,
  LightChannelStateResponse,
  LightToggleValueInternal,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum, ChannelInterface, ChannelStateType } from '../../../types';

export const parseLightChannel = (userChannel: UserChannel): ChannelInterface => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.LIGHT,
  deviceId: userChannel.deviceId,
  data: {
    type: ChannelTypeInternal.Light,
    supportedModes: (userChannel.payload as LightChannelStateResponse)?.supportedModes || [],
    measuredBy: (userChannel.payload as LightChannelStateResponse)?.measuredBy || undefined,
    controlledInput: (userChannel.payload as LightChannelStateResponse)?.controlledInput || undefined,
  },
});

export const parseLightChannelState = (userChannel: UserChannel): ChannelStateType => ({
  brightness: (userChannel.payload as LightChannelStateResponse)?.brightness || 0,
  temperature: (userChannel.payload as LightChannelStateResponse)?.temperature || 0,
  toggleValue: (userChannel.payload as LightChannelStateResponse)?.toggleValue ?? LightToggleValueInternal.Off,
  color: (userChannel.payload as LightChannelStateResponse)?.color ?? null,
  supplyReturnBehavior: (userChannel.payload as LightChannelStateResponse)?.supplyReturnBehavior,
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
