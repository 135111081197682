import React, { createContext, useLayoutEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const RouterHistoryStackContext = createContext({});

const RouterHistoryStackProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [stack, setStack] = useState<string[]>([]);

  const dashboardRoutes = useMemo(() => [ROUTES.Root(), ROUTES.Channel(), ROUTES.ActionList(), ROUTES.Settings()], []);

  useLayoutEffect(() => {
    if (history.action === 'POP') {
      if (history.length > 2) {
        const updated = [...stack];
        const lastRoute = [...stack].pop();
        updated.splice(-1, 1);
        setStack(updated);

        if (lastRoute) {
          if (dashboardRoutes.includes(lastRoute)) {
            history.replace(ROUTES.Root());
            setStack([]);
          }
        }
      }
    } else if (history.action === 'PUSH') {
      setStack([...stack, location.pathname]);
    }
  }, [location]);

  return <RouterHistoryStackContext.Provider value={{}}>{children}</RouterHistoryStackContext.Provider>;
};

export default RouterHistoryStackProvider;
