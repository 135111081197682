import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IPicture } from 'lavva.exalushome/build/js/Services/Pictures/IPicture';
import {
  Gender as GenderType,
  AccessLevel as AccessLevelType,
  IUser,
} from 'lavva.exalushome/build/js/Services/Users/IUser';
import { IconPhoto, InputSelect, SubmitButton } from '../../../../../../components';
import { convertBase64 } from '../../../../../../utils/helpers/file';
import { toastError } from '../../../../../../utils/toast';
import FormInput from '../../components/form-input';
import { useUsers } from '../../hooks/use-users';
import { CreateEditUserForm } from '../../types';
import './index.scss';

interface ComponentProps {
  user?: IUser | null;
  image?: IPicture | ResponseResult<Status>;
}

const ExalusFormCreate: React.FC<ComponentProps> = ({ user, image }) => {
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const [base64, setBase64] = useState<string>('');
  const { userId } = useParams<{ userId: string }>();
  const form = useForm<CreateEditUserForm>({
    defaultValues: {
      Gender: 0,
      AccessLevel: 10,
    },
  });
  const { createOrEditUser } = useUsers();

  useEffect(() => {
    if (user) {
      form.setValue('Name', user.Name);
      form.setValue('Surname', user.Surname);
      form.setValue('Email', user.Email);
      form.setValue('Phone', user.Phone || '');
      form.setValue('Gender', user.Gender);
      form.setValue('AccessLevel', user.AccessLevel);
    }

    if ((image as IPicture)?.Base64Image) {
      setBase64((image as IPicture).Base64Image);
    }
  }, [user, image]);

  const { Gender, AccessLevel } = form.watch();

  const onSubmit = form.handleSubmit((values) => {
    createOrEditUser(values, base64);
  });

  const onChangeSelect = (key: 'Gender' | 'AccessLevel', value: GenderType | AccessLevelType) => {
    form.setValue(key, value);
  };

  const onChangePhoto = async (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > 512 * 1042) {
        toastError({ content: t('avatar.fileTooBig') });
      } else {
        const base64 = await convertBase64(event.target.files[0]);
        setBase64(base64 as string);
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form className="form-create-edit" onSubmit={onSubmit}>
        {userId && (
          <div className="change-avatar">
            <span className="input__label">{t('avatar.current')}</span>
            <label>
              <div className="image-container">{base64 ? <img src={base64} alt={''} /> : <IconPhoto />}</div>
              <div className="caption">{t('avatar.change')}</div>
              <div className="edit-avatar__input-file">
                <input type="file" onChange={onChangePhoto} hidden accept="image/*" />
              </div>
            </label>
          </div>
        )}

        <FormInput name="Name" label={t('exalus.firstName')} required />
        <FormInput name="Surname" label={t('exalus.lastName')} required />
        <FormInput name="Email" label={t('exalus.email')} required />
        {!userId && <FormInput name="Password" label={t('exalus.password')} required />}
        <FormInput name="Phone" label={t('exalus.phone')} />
        <InputSelect
          onChange={(val) => onChangeSelect('Gender', val)}
          value={Gender}
          options={[
            { label: t('exalus.gender_0'), value: GenderType.Unknown },
            { label: t('exalus.gender_1'), value: GenderType.Men },
            { label: t('exalus.gender_2'), value: GenderType.Woman },
            { label: t('exalus.gender_3'), value: GenderType.Other },
          ]}
          label={t('exalus.gender')}
        />
        <InputSelect
          onChange={(val) => onChangeSelect('AccessLevel', val)}
          value={AccessLevel}
          options={[
            { label: t('exalus.role_10'), value: AccessLevelType.Guest },
            { label: t('exalus.role_20'), value: AccessLevelType.Standard },
            { label: t('exalus.role_30'), value: AccessLevelType.Admin },
            { label: t('exalus.role_40'), value: AccessLevelType.Installator },
            { label: t('exalus.role_50'), value: AccessLevelType.Support },
          ]}
          label={t('exalus.role')}
        />
        <SubmitButton>{!userId ? t('exalus.create') : tc('buttons.save')}</SubmitButton>
      </form>
    </FormProvider>
  );
};

export default ExalusFormCreate;
