import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  GlobalTimeParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { SwitchConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchService';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import {
  InputOutputDimmerParameters,
  LightTransitionTime,
  MaxLightLevel,
  MinLightLevel,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { Rdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import EnablingTimeForm from '../on-off/enable-time';
import GlobalTimeForm from '../on-off/global-time';
import TurnOnBehaviourForm from '../on-off/turn-behaviour';
import BrighteningTimeForm from './brightening-time';
import DimmingTimeForm from './dimming-time';
import InputModeForm from './input-mode';
import MaxLevelForm from './max-level';
import MinLevelForm from './min-level';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const Rdp21ConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [params, setParams] = useState<
    | InputOutputDimmerParameters
    | LightTransitionTime
    | MinLightLevel
    | MaxLightLevel
    | StateOfTurnOnSwitchDeviceParameters
    | GlobalTimeParameters
    | undefined
  >(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetSwitchParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: string) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRdp21ConfigService>(
      Rdp21ConfigService.ServiceName,
    );

    switch (panel) {
      case 'DimmingTimeParams': {
        if (channel) {
          const data: LightTransitionTime | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetDimmingTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<LightTransitionTime>(data, () => {
            setParams(data as LightTransitionTime);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'BrighteningTimeParams': {
        if (channel) {
          const data: LightTransitionTime | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetBrighteningTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<LightTransitionTime>(data, () => {
            setParams(data as LightTransitionTime);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'MinLevelParams': {
        if (channel) {
          const data: MinLightLevel | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetMinLevelAsync(device, channel.Number);

          handleGetSwitchParamsResponse<MinLightLevel>(data, () => {
            setParams(data as MinLightLevel);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'MaxLevelParams': {
        if (channel) {
          const data: MaxLightLevel | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetMaxLevelAsync(device, channel.Number);

          handleGetSwitchParamsResponse<MaxLightLevel>(data, () => {
            setParams(data as MaxLightLevel);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'TurnOnBehaviourParams': {
        if (channel) {
          const data: StateOfTurnOnSwitchDeviceParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetSwitchParamsResponse<StateOfTurnOnSwitchDeviceParameters>(data, () => {
            setParams(data as StateOfTurnOnSwitchDeviceParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'GlobalTimeParams': {
        if (channel) {
          const data: GlobalTimeParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetGlobalTimeSettingsAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setParams(data as GlobalTimeParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'EnableTimeParams': {
        if (channel) {
          const data: GlobalTimeParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetTurnOnTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setParams(data as GlobalTimeParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'InputModeParams': {
        if (channel) {
          const data: InputOutputDimmerParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetDimmerInputOutputParamAsync(device, channel.Number);

          handleGetSwitchParamsResponse<InputOutputDimmerParameters>(data, () => {
            setParams(data as InputOutputDimmerParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'DimmingTimeParams' && channel && (
            <DimmingTimeForm device={device} channel={channel} dimmingTime={params as LightTransitionTime} />
          )}
          {configType === 'BrighteningTimeParams' && channel && (
            <BrighteningTimeForm device={device} channel={channel} brighteningTime={params as LightTransitionTime} />
          )}
          {configType === 'MinLevelParams' && channel && (
            <MinLevelForm device={device} channel={channel} minLevel={params as MinLightLevel} />
          )}
          {configType === 'MaxLevelParams' && channel && (
            <MaxLevelForm device={device} channel={channel} maxLevel={params as MaxLightLevel} />
          )}
          {configType === 'InputModeParams' && (
            <InputModeForm device={device} inputMode={params as InputOutputDimmerParameters} />
          )}
          {configType === 'TurnOnBehaviourParams' && (
            <TurnOnBehaviourForm device={device} turnOnBehaviour={params as StateOfTurnOnSwitchDeviceParameters} />
          )}
          {configType === 'GlobalTimeParams' && (
            <GlobalTimeForm device={device} timeParams={params as GlobalTimeParameters} />
          )}
          {configType === 'EnableTimeParams' && (
            <EnablingTimeForm device={device} timeParams={params as GlobalTimeParameters} />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.OnOffParams.InputModeParams')}
                onClick={() => handleOpen('InputModeParams')}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.BrighteningTimeParams')}
                onClick={() => handleOpen('BrighteningTimeParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.DimmingTimeParams')}
                onClick={() => handleOpen('DimmingTimeParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.MinLevelParams')}
                onClick={() => handleOpen('MinLevelParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.MaxLevelParams')}
                onClick={() => handleOpen('MaxLevelParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.TurnOnBehaviourParams')}
                onClick={() => handleOpen('TurnOnBehaviourParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.GlobalTimeParams')}
                onClick={() => handleOpen('GlobalTimeParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.EnableTimeParams')}
                onClick={() => handleOpen('EnableTimeParams')}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rdp21ConfigForm;
