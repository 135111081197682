import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useBackdropContext, useNative } from '../../../hooks';
import { useErrors } from '../../../hooks/use-errors';
import { useRestClient } from '../../clients/rest-client';
import {
  UpdateFirmwareBody,
  DeviceSwapInBody,
  DeviceResponse,
  ConnectNetworkBody,
  ConnectUnknownNetworkBody,
  DeviceRestartBody,
  DeviceNetworks,
  ConnectUnknownNetworkBatchBody,
  DeviceBindingsBody,
} from './device.types';

export const useUpdateFirmware = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  UpdateFirmwareBody
> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, UpdateFirmwareBody>(
    ['device-updateFirmware'],
    async (body) => await restClient.post<DeviceResponse>('/device/updateFirmware', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};

export const useDeviceSwapIn = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, DeviceSwapInBody> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, DeviceSwapInBody>(
    ['device-swapIn'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/device/swapIn', body);
    },
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};

export const useGetWiFiNetworks = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, string> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, string>(
    ['device-wifi-networks'],
    async (deviceId) => await restClient.post<DeviceResponse>('/device/getWifiNetworks', { deviceId }),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};

export const useGetWiFiNetworksBatch = (): UseQueryResult<DeviceNetworks[], AxiosError> => {
  const restClient = useRestClient();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleRestErrors } = useErrors();

  return useQuery<DeviceNetworks[], AxiosError>(
    ['device-wifi-networks-batch'],
    async () => {
      turnOnBackdrop();
      const response = await restClient.get('/device/getWifiNetworksBatch');
      return response.data;
    },
    {
      onSuccess: () => {
        turnOffBackdrop();
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
      cacheTime: 0,
    },
  );
};

export const useConnectToNetwork = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  ConnectNetworkBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, ConnectNetworkBody>(
    ['device-connect-to-network'],
    async (body) => await restClient.post<DeviceResponse>('/device/connectToNetwork', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useConnectToUnknownNetwork = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  ConnectUnknownNetworkBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, ConnectUnknownNetworkBody>(
    ['device-connect-to-network'],
    async (body) => await restClient.post<DeviceResponse>('/device/connectToUnknownNetwork', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useConnectToUnknownNetworkBatch = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  ConnectUnknownNetworkBatchBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, ConnectUnknownNetworkBatchBody>(
    ['device-connect-to-network-batch'],
    async (body) => await restClient.post<DeviceResponse>('/device/connectToUnknownNetworkBatch', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useDeviceRestart = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, DeviceRestartBody> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, DeviceRestartBody>(
    ['device-restart'],
    async (body) => await restClient.post<DeviceResponse>('/device/restart', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};

export const useSetActionBindings = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  DeviceBindingsBody
> => {
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, DeviceBindingsBody>(
    ['device-setActionBindings'],
    async (body) => await restClient.post<DeviceResponse>('/device/setActionBindings', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
