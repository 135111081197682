import { gql } from '@apollo/client';

export const DEVICES_GET_ALL = gql`
  query DevicesGetAll($installationId: UUID!) {
    allUserDevices(installationId: $installationId) {
      id
      installationId
      payload {
        id: deviceId
        deviceId
        model
        name
        connectedToSsid
        currentFirmwareVersion
        macAddress
        lastConnectedAtUtc
        registeredAtUtc
        newestFirmwareVersion
        timeZone
        isUpdateAvailable
        channelInfos {
          channelId
          channelNumber
          channelType
        }
      }
    }
  }
`;

export const DEVICE_GET_BINDINGS = gql`
  
  fragment ControlledInput on ControlledInputResponse {
    inputId
    slot
    inputConfig {
      genericInputType
      bistableMode
      monostableMode
    }
  }


  query DeviceGetBindings($installationId: UUID!, $deviceId: UUID!) {
    channelBindings: allUserChannels(
      installationId: $installationId
      where: { deviceId: { eq: $deviceId } }
      order: { deviceId: ASC, alias: ASC }
    ) {
      id
      alias
      deviceId
      channelType
      payload {
        id: channelId
        deviceId
        channelId
        ... on GenericInputChannelStateResponse {
          deviceId
          channelId
          genericInputType
        }
        ... on SwitchChannelStateResponse {
          controlledInput {
            ... ControlledInput
            actionBindings {
              isDefaultAction
              inputTrigger
              ... on SwitchActionConfigResponse {
                setOnTimeValue
              }
            }
          }
        }
        ... on LightChannelStateResponse {
          supportedModes
          controlledInput {
            ... ControlledInput
            actionBindings {
              isDefaultAction
              inputTrigger
              ... on LightActionConfigResponse {
                setOnTimeValue
                brightness
                temperature
                r
                g
                b
              }
            }
          }
        }
        ... on BlindChannelStateResponse {
          controlledInputs {
            ... ControlledInput
            actionBindings {
              isDefaultAction
              inputTrigger
              ... on BlindActionConfigResponse {
                position
              }
            }
          }
        }
        ... on GateChannelStateResponse {
          controlledInputs {
            ... ControlledInput
            actionBindings {
              isDefaultAction
              inputTrigger
              ... on GateActionConfigResponse {
                position
                dir
              }
            }
          }
        }
      }
    }
  }
`;
