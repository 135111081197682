import { environment } from '../../environment';
import { IntegrationDefaultEnum } from '../../types';
import * as storage from '../storage/lavva';

export const setTheme = (): void => {
  const nativeURLTheme = new URLSearchParams(location.search).get('theme')?.replace(/['"]+/g, '');

  if (nativeURLTheme === 'dark') {
    addBodyClass(nativeURLTheme as string);
    storage.setItem('theme', 'dark');
  } else if (nativeURLTheme === 'light') {
    removeBodyClass(nativeURLTheme as string);
    storage.setItem('theme', 'light');
  } else if (nativeURLTheme === 'device') {
    if (getThemeFromDevice() === 'dark') {
      addBodyClass('dark');
      storage.setItem('theme', 'device');
    }
  } else if (!storage.getItem('theme')) {
    storage.setItem('theme', 'device');
  } else {
    if (environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus) {
      toggleBodyClass('exalus');
    }

    toggleBodyClass(
      'dark',
      storage.getItem('theme') === 'dark' || (storage.getItem('theme') === 'device' && getThemeFromDevice() === 'dark'),
    );
  }
};

export const setBodyStyle = (key: string, value: string): void => document.body.style.setProperty(key, value);
export const addBodyClass = (className: string): void => document.body.classList.add(className);
export const removeBodyClass = (className: string): void => document.body.classList.remove(className);
export const toggleBodyClass = (className: string, force?: boolean): void => {
  document.body.classList.toggle(className, force);
};

export const getThemeFromDevice = () => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
    return 'dark';
  } else {
    return 'light';
  }
};

export const getSelectedTheme = () => {
  const storageTheme = storage.getItem('theme');

  if (storageTheme === 'device') return getThemeFromDevice();
  return storageTheme;
};

export const setWisniowskiTheme = () => {
  const wisniowski = new URLSearchParams(window.location.search).get('wisniowski');
  if (wisniowski) localStorage.setItem('wisniowski', 'true');
};
