import {
  ActionConfigResponse,
  ChannelTypeInternal,
  ControlledInputResponse,
  InputTriggerInternal,
  UserChannel,
} from '../../../../data-access/gql-types/graphql';
import { millisecondsToTime } from '../../../../utils/helpers';
import {
  ActionBindingType,
  BindingListItem,
  BindingsItem,
  ControllerInputPayload,
  InputBindingTrigger,
} from '../types';

export const actionBindingTypeByChannelType = {
  [ChannelTypeInternal.Unknown]: ActionBindingType.Default,
  [ChannelTypeInternal.Switch]: ActionBindingType.Switch,
  [ChannelTypeInternal.Light]: ActionBindingType.Light,
  [ChannelTypeInternal.Blind]: ActionBindingType.Blind,
  [ChannelTypeInternal.Gate]: ActionBindingType.Gate,
};
export const inputTriggerMap = {
  [InputTriggerInternal.SingleClick]: InputBindingTrigger.SingleClick,
  [InputTriggerInternal.DoubleClick]: InputBindingTrigger.DoubleClick,
};

const mapActionBindings = (channel: UserChannel, binding: ActionConfigResponse) => {
  return {
    inputTrigger: inputTriggerMap[binding.inputTrigger],
    actionType: binding.isDefaultAction
      ? ActionBindingType.Default
      : actionBindingTypeByChannelType[channel.channelType],
    ...(binding.isDefaultAction ? { actionType: ActionBindingType.Default } : {}),
    ...('setOnTimeValue' in binding && binding.setOnTimeValue !== null
      ? { setOnTimeValue: millisecondsToTime(binding.setOnTimeValue as number) }
      : {}),
    ...('position' in binding && binding.position !== null ? { position: binding.position } : {}),
    ...('dir' in binding && binding.dir !== null ? { dir: binding.dir } : {}),
    ...('brightness' in binding && binding.brightness !== null ? { brightness: binding.brightness } : {}),
    ...('temperature' in binding && binding.temperature !== null ? { temperature: binding.temperature } : {}),
    ...('r' in binding && binding.r !== null ? { r: binding.r } : {}),
    ...('g' in binding && binding.g !== null ? { g: binding.g } : {}),
    ...('b' in binding && binding.b !== null ? { b: binding.b } : {}),
  } as BindingListItem;
};

export const mapFetchBindingDataWithControlledInput = (channel: UserChannel) => {
  return {
    channelId: channel.id,
    inputId: (channel.payload as ControllerInputPayload).controlledInput?.inputId || '',
    slot: (channel.payload as ControllerInputPayload).controlledInput?.slot || null,
    list: (channel.payload as ControllerInputPayload).controlledInput?.actionBindings.map((binding) =>
      mapActionBindings(channel, binding),
    ),
  } as BindingsItem;
};

export const mapFetchBindingDataWithControlledInputs = (channel: UserChannel, input: ControlledInputResponse) => {
  return {
    channelId: channel.id,
    inputId: input.inputId || '',
    slot: input.slot || null,
    list: input.actionBindings.map((binding) => mapActionBindings(channel, binding)),
  } as BindingsItem;
};
