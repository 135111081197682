import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BatteryDeviceState,
  BatteryStateEnum,
  BlindPositionDeviceState,
  BlindStatusCode,
  ConfigurationDeviceState,
  ConfigurationStateEnum,
  FacadePositionDeviceState,
  FloodSensorStates,
  LightThresholdStateEnum,
  LinkQualityEnum,
  OvercurrentProtectionDeviceState,
  SignalStrenghtDeviceState,
  WindThresholdStateEnum,
  GateControllerHealthState,
  IDeviceState as IChannelState,
  ResistanceOrOpticBarStatusEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { toastError, toastInfo } from '../../../../../utils/toast';
import { useBrightnessControl } from '../../../components/channel/hooks/use-brightness-control';
import { useFloodControl } from '../../../components/channel/hooks/use-flood-control';
import { useMovementControl } from '../../../components/channel/hooks/use-movement-control';
import { useWindControl } from '../../../components/channel/hooks/use-wind-control';
import { ChannelType, SensorType } from '../../../enums';
import { getChannelType, getSensorType } from '../../../utils';

interface HookParams {
  detailsView: boolean;
}

export const useSomethingWrong = (channel: IDeviceChannel, params?: HookParams) => {
  const { t: td } = useTranslation('channel-details');
  const { windThreshold } = useWindControl(channel);
  const { brightnessThreshold } = useBrightnessControl(channel);
  const { isTamperProtection } = useMovementControl(channel);
  const { flood } = useFloodControl(channel);

  const showErrorStatusMessageIfNeeded = (status?: BlindStatusCode) => {
    if (status === BlindStatusCode.UnableToMove) {
      toastError({ content: td('exalus.unableToMove') });
    } else if (status === BlindStatusCode.MotorOverheatedPleaseWait) {
      toastError({ content: td('exalus.motorOverheatedPleaseWait') });
    }
  };

  const isStatusError = (status?: BlindStatusCode) => {
    return status === BlindStatusCode.UnableToMove || status === BlindStatusCode.MotorOverheatedPleaseWait;
  };

  useEffect(() => {
    if (params?.detailsView) {
      const signalStrength = channel.States?.find(
        (state) => state.TypeAsEnum === ChannelResponseType.SignalStrength,
      ) as SignalStrenghtDeviceState;

      if (signalStrength?.Data) {
        if (
          signalStrength.Data.Quality === LinkQualityEnum.Acceptable ||
          signalStrength.Data.Quality === LinkQualityEnum.Low ||
          signalStrength.Data.Quality === LinkQualityEnum.Terrible
        ) {
          toastInfo({ content: td('exalus.weakSignal') });
        } else if (signalStrength.Data.Quality === LinkQualityEnum.NoLink) {
          toastInfo({ content: td('exalus.noSignal') });
        }
      }

      const channelType = getChannelType(channel);

      switch (channelType) {
        case ChannelType.Blind: {
          const status = (
            channel.States?.find(
              (state) => state.TypeAsEnum === ChannelResponseType.BlindPosition,
            ) as BlindPositionDeviceState
          )?.GetBlindStatus();

          showErrorStatusMessageIfNeeded(status);
          break;
        }
        default:
          break;
      }
    }
  }, [channel.States, params?.detailsView]);

  const connectionIcon = useMemo(() => {
    const signalStrength = channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.SignalStrength,
    ) as SignalStrenghtDeviceState;

    if (signalStrength?.Data) {
      return (
        signalStrength.Data.Quality === LinkQualityEnum.NoLink ||
        signalStrength.Data.Quality === LinkQualityEnum.Terrible ||
        signalStrength.Data.Quality === LinkQualityEnum.Low
      );
    }
    return false;
  }, [channel.States]);

  const channelOrientedError = useMemo(() => {
    const overcurrentState = channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.OvercurrentProtectionState,
    ) as OvercurrentProtectionDeviceState;

    switch (getChannelType(channel)) {
      case ChannelType.Blind: {
        const state = channel.States?.find(
          (state) => state.TypeAsEnum === ChannelResponseType.BlindPosition,
        ) as BlindPositionDeviceState;

        const status = state?.GetBlindStatus ? state.GetBlindStatus() : null;

        if (!status) return false;
        return isStatusError(status) || overcurrentState?.Data?.OverrcurrentDetected;
      }
      case ChannelType.Switch: {
        return overcurrentState?.Data?.OverrcurrentDetected;
      }
      case ChannelType.Gate: {
        const health = (
          channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.GateControllerHealth) as
            | IChannelState<GateControllerHealthState>
            | undefined
        )?.Data;

        if (health) {
          if (health.AutotestSwitchOn === false) return td('exalus.gateHealth.AutotestSwitchOn');
          if (health.LastAutotestFailed) return td('exalus.gateHealth.LastAutotestFailed');
          if (health.InertionBreaksOk === false) return td('exalus.gateHealth.InertionBreaksOk');
          if (health.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.Active) {
            return td('exalus.gateHealth.ResistanceOrOpticBarActive');
          }
          if (health.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.UnconnectedOrMalfunction) {
            return td('exalus.gateHealth.ResistanceOrOpticBarUnconnected');
          }
          if (health.PhotoCellsActive) return td('exalus.gateHealth.PhotoCellsActive');
        }

        return false;
      }
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Flood:
            return flood === FloodSensorStates.FloodDetected;
          case SensorType.Movement:
            return isTamperProtection;
          default:
            return false;
        }
      default:
        return false;
    }
  }, [channel.States, flood, isTamperProtection]);

  const channelOrientedWarning = useMemo(() => {
    switch (getChannelType(channel)) {
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Wind:
            return windThreshold === WindThresholdStateEnum.Exceeded;
          case SensorType.MeasuredBrightness:
            return brightnessThreshold === LightThresholdStateEnum.Exceeded;
          default:
            return false;
        }
      default:
        return false;
    }
  }, [channel.States]);

  const calibrationNeed = useMemo(() => {
    const calibration = channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.ConfigurationState,
    ) as ConfigurationDeviceState;

    if (calibration?.Data) {
      if (
        calibration.Data.Configuration === ConfigurationStateEnum.OngoingConfiguration ||
        calibration.Data.Configuration === ConfigurationStateEnum.NotConfigured ||
        calibration.Data.Configuration === ConfigurationStateEnum.PartiallyConfigured
      ) {
        return calibration.Data.Configuration;
      }
    }

    return null;
  }, [channel.States]);

  const isUnknownFacadePosition = useMemo(() => {
    const facadeData = (
      channel.States?.find(
        (state) => state.TypeAsEnum === ChannelResponseType.FacadePosition,
      ) as FacadePositionDeviceState
    )?.Data;

    return facadeData?.Position === 255 || facadeData?.Tilt === 255;
  }, [channel.States]);

  const battery = useMemo(() => {
    const batteryState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.BatteryState,
    ) as BatteryDeviceState;

    if (batteryState) return batteryState.Data;
    return null;
  }, [channel?.States]);

  const gateHealth = useMemo(() => {
    return channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.GateControllerHealth) as
      | IChannelState<GateControllerHealthState>
      | undefined;
  }, [channel.States]);

  return {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    channelOrientedWarning,
    isUnknownFacadePosition,
    batteryError: battery?.State === BatteryStateEnum.Low || battery?.State === BatteryStateEnum.Empty,
    gateHealth,
  };
};
