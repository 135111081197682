import React from 'react';
import classNames from 'classnames';
import { GroupTabs, Page } from '../../components';
import { useInstallation, useDevicesAndChannels } from '../../hooks';
import { useNativeFunctionsContext } from '../../hooks/native/native-functions';
import { UpdateDevicesDialog } from '../device-add/components/update-devices-dialog';
import { DashboardBody, DashboardLavvaHeader } from './components';
import { useDashboard } from './hooks/use-dashboard';

const Dashboard: React.FC = () => {
  const { selectedInstallation, installationList } = useInstallation();
  const { channelList } = useDevicesAndChannels();
  const { nativeAppInstalledPopup } = useNativeFunctionsContext();
  const { isListView, toggleListView, activeGroup, handleSetActiveGroupIndex, activeTabIndex } = useDashboard();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      carouselContent={!!(installationList.length !== 0 || selectedInstallation)}
      className={classNames('dashboard', {
        'dashboard--no-selected-installation': !selectedInstallation,
      })}
      header={
        <>
          <DashboardLavvaHeader isListView={isListView} toggleListView={toggleListView} activeGroup={activeGroup} />
          {selectedInstallation && channelList.length > 0 && (
            <GroupTabs activeTabIndex={activeTabIndex} setActiveTabIndex={handleSetActiveGroupIndex} />
          )}
        </>
      }
    >
      <DashboardBody
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={handleSetActiveGroupIndex}
        isListView={isListView}
      />
      {!nativeAppInstalledPopup && <UpdateDevicesDialog />}
    </Page>
  );
};

export default Dashboard;
