import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PopUpNavLinkInterface } from '../../../components';
import {
  IconChannelsOrder,
  IconDelete,
  IconDuplicate,
  IconEdit,
  IconGroups,
} from '../../../components/popup-nav/icons';
import {
  ActionType,
  DashboardItemType,
  InstallationAccessType,
  UserAction,
} from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { useModifyMultipleGroups } from '../../channel-details/hooks/use-modify-multiple-groups';

export const useActionDetails = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: td } = useTranslation('channel-details');
  const { actionId } = useParams<{ actionId: string }>();
  const [userAction, setUserAction] = useState<UserAction | null>();
  const [showDuplicateDialog, setShowDuplicateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { selectedInstallation } = useInstallation();
  const { groupList, handleSaveGroup, toggleGroup, groupPopup, setGroupPopup } = useModifyMultipleGroups({
    itemId: actionId,
    itemType: DashboardItemType.Action,
  });

  const canMakeAction = useMemo(
    () =>
      selectedInstallation?.accessType === InstallationAccessType.Owner ||
      selectedInstallation?.accessType === InstallationAccessType.Admin,
    [selectedInstallation?.accessType],
  );

  const popupLinks: PopUpNavLinkInterface[] = useMemo(() => {
    if (!userAction) return [];
    switch (userAction?.action.actionType) {
      case ActionType.Central:
        return [
          {
            onClick: () => history.push(ROUTES.ActionChannelsSort(userAction.action.id)),
            label: t('sort'),
            icon: <IconChannelsOrder />,
          },
        ];
      case ActionType.Basic:
        return [
          {
            onClick: () => history.push(ROUTES.ActionEditName(userAction.action.id)),
            label: t('changeName'),
            icon: <IconEdit />,
          },
          {
            onClick: () => history.push(ROUTES.ActionEditChannels(userAction.action.id)),
            label: t('edit'),
            icon: <IconEdit />,
          },
          {
            onClick: () => history.push(ROUTES.ActionChannelsSort(userAction.action.id)),
            label: t('sort'),
            hasLineBelow: true,
            icon: <IconChannelsOrder />,
          },
          ...(canMakeAction
            ? [
                {
                  onClick: () => setGroupPopup(true),
                  label: td('groupAddRemove'),
                  icon: <IconGroups />,
                },
              ]
            : []),
          ...(canMakeAction
            ? [{ onClick: () => setShowDeleteDialog(true), label: t('remove'), icon: <IconDelete /> }]
            : []),
        ];
      case ActionType.Advanced:
        return [
          {
            onClick: () => history.push(ROUTES.ExalusActionEdit(userAction.action.id)),
            label: t('edit'),
            icon: <IconEdit />,
          },
          {
            onClick: () => history.push(ROUTES.ActionChannelsSort(userAction.action.id)),
            label: t('sort'),
            hasLineBelow: true,
            icon: <IconChannelsOrder />,
          },
          ...(canMakeAction
            ? [
                {
                  onClick: () => setGroupPopup(true),
                  label: td('groupAddRemove'),
                  icon: <IconGroups />,
                },
              ]
            : []),
          { onClick: () => setShowDuplicateDialog(true), label: t('duplicate'), icon: <IconDuplicate /> },
          ...(canMakeAction
            ? [{ onClick: () => setShowDeleteDialog(true), label: t('remove'), icon: <IconDelete /> }]
            : []),
        ];
      default:
        return [];
    }
  }, [userAction, canMakeAction]);

  return {
    popupLinks,
    groupList,
    handleSaveGroup,
    toggleGroup,
    groupPopup,
    setGroupPopup,
    userAction,
    setUserAction,
    showDeleteDialog,
    showDuplicateDialog,
    setShowDeleteDialog,
    setShowDuplicateDialog,
  };
};
