import React, { ReactNode } from 'react';
import c from 'classnames';
import { IconWrapper } from '../../icon-wrapper';
import { IconDelete } from '../../icons';
import { PopUpNav, PopUpNavLinkInterface } from '../../popup-nav';
import './index.scss';

interface ComponenProps {
  icon: ReactNode;
  title: string;
  repeatOption: string | ReactNode;
  firstLine?: string;
  secondLine?: ReactNode;
  removeCondition?: () => void;
  handleConditionClick?: () => void;
  type?: 'details' | 'list-item';
  options?: PopUpNavLinkInterface[];
}

const ActionConditionItemDetails: React.FC<ComponenProps> = ({
  icon,
  title,
  firstLine,
  secondLine,
  repeatOption,
  removeCondition,
  handleConditionClick,
  type = 'details',
  options = [],
}) => {
  return (
    <div
      className={c('action-condition', {
        'action-condition--with-delete': !!removeCondition,
        [type]: type,
      })}
    >
      <div className="action-condition__wrapper" onClick={handleConditionClick}>
        <IconWrapper>{icon}</IconWrapper>
        <div className="action-condition__content">
          <h4 className="action-condition__title">{title}</h4>
          <p className="action-condition__text">
            {repeatOption} {firstLine && firstLine.trim() !== '' ? `, ${firstLine}` : ''}
          </p>
          {secondLine}
        </div>
      </div>
      {removeCondition ? (
        <IconDelete className="action-advanced-form__device-item-button" onClick={removeCondition} />
      ) : null}
      {options.length ? <PopUpNav dark links={options} /> : null}
    </div>
  );
};

export default ActionConditionItemDetails;
