import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { WindSpeedState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SubmitButton } from '../../../../../../../../components';
import { ComparisonMethodSelect } from '../../../../components/comparison-method-select';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';

export const WindSpeedParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { channels } = useExalusCreateActionContext();
  const [windSpeed, setWindSpeed] = useState<string>('0');
  const {
    triggerMethod,
    atMeetDisabled,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit();

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDeviceState;

    if (argument && onlyParametersEdit) {
      const state = argument.GetCheckDeviceState<WindSpeedState>();
      setWindSpeed(state.Value.toString());
    }
  }, [seq, onlyParametersEdit]);

  const handleSubmit = () => {
    if (channels.length) {
      const deviceArgument = createDeviceArgumentWithComparison();
      deviceArgument.Type = DeviceResponseType.WindSpeedState;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;
      const sensorState = new WindSpeedState();
      sensorState.Channel = channels[0].Number;
      sensorState.Value = parseFloat(windSpeed);
      deviceArgument.SetCheckDeviceState<WindSpeedState>(sensorState);

      saveConditionParams(deviceArgument);
    }
  };

  return (
    <>
      {!atMeetDisabled ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && !atMeetDisabled && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[
          ConditionsTypes.Equal,
          ConditionsTypes.NotEqueal,
          ConditionsTypes.BiggerThan,
          ConditionsTypes.BiggerThanOrEqual,
          ConditionsTypes.SmallerThan,
          ConditionsTypes.SmallerThanOrEqual,
        ]}
      />
      <ComparisonMethodSelect unit="m/s" />
      <InputString
        value={windSpeed}
        setValue={setWindSpeed}
        label={`${t('action.create.conditions.sources.device.windSpeed')} (m/s)`}
        inputType="number"
        step="0.1"
        min={0}
        max={1200}
      />
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
