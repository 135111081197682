import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TabInterface } from '../../../components';
import { IconDeviceList, IconEye, IconGridView, IconListView } from '../../../components/popup-nav/icons';
import { InstallationAccessType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannels, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { isLocalApp } from '../../../utils/helpers/local-app';
import * as storage from '../../../utils/storage/lavva';

export const useChannelList = () => {
  const history = useHistory();
  const { t } = useTranslation('channel-list');
  const { t: tc } = useTranslation('common');
  const { t: tdash } = useTranslation('dashboard');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isListView, setIsListView] = useState<boolean>(!!storage.getItem('isChannelsListView'));
  const { channelGroups } = useDevicesAndChannels();
  const { selectedInstallation } = useInstallation();

  useEffect(() => {
    const channelsTabIndex = storage.getItem('lastActiveTabInChannelList') || 0;
    if (channelsTabIndex < channelGroups.length) {
      setActiveTabIndex(channelsTabIndex);
    }
  }, [channelGroups.length]);

  const handleSetActiveTabIndex = useCallback((index: number) => {
    setActiveTabIndex(index);
    storage.setItem('lastActiveTabInChannelList', index);
  }, []);

  const toggleListView = useCallback(() => {
    storage.setItem('isChannelsListView', !isListView);
    setIsListView(!isListView);
  }, [isListView, setIsListView]);

  const tabs: TabInterface[] = useMemo(
    () => channelGroups.map((channelGroups) => ({ label: tc(`typesPlural.${channelGroups.type}`) })),
    [channelGroups],
  );

  const popUpNavLinks = useMemo(
    () => [
      ...(selectedInstallation?.accessType === InstallationAccessType.Owner ||
      selectedInstallation?.accessType === InstallationAccessType.Admin
        ? [
            {
              onClick: () => history.push(ROUTES.Device()),
              label: t('devicesList'),
              icon: <IconDeviceList />,
            },
          ]
        : []),
      ...(!isLocalApp
        ? [
            {
              onClick: () => history.push(ROUTES.ChannelsVisibility()),
              label: t('editVisibility'),
              hasLineBelow: true,
              icon: <IconEye />,
            },
          ]
        : []),
      {
        onClick: toggleListView,
        label: isListView ? tdash('gridView') : tdash('listView'),
        icon: isListView ? <IconGridView /> : <IconListView />,
      },
    ],
    [selectedInstallation?.accessType, isListView],
  );

  return {
    isListView,
    popUpNavLinks,
    tabs,
    activeTabIndex,
    channelGroups,
    handleSetActiveTabIndex,
  };
};
