import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconEdit, Input } from '../../../../../components';
import { DialogValuePicker } from '../../../../../components/dialog-value-picker';

interface ComponentProps {
  value: number;
  bindingIndex: number;
  bindingListIndex: number;
}

export const PositionAction: React.FC<ComponentProps> = ({ value, bindingIndex, bindingListIndex }) => {
  const { t } = useTranslation('device-settings');
  const { setValue } = useFormContext();
  const [popup, setPopup] = useState<boolean>(false);

  const handleSetPosition = (time: number) => {
    setValue(`bindings.${bindingIndex}.list.${bindingListIndex}.position`, time);
  };

  return (
    <>
      <Input
        defaultValue={value.toString()}
        value={value.toString()}
        required
        readOnly
        label={`${t('bindings.position')} (%)`}
        onClickInput={() => setPopup(true)}
        endIcon={<IconEdit />}
      />
      {popup && (
        <DialogValuePicker
          open
          setOpen={setPopup}
          title={`${t('bindings.position')} (%)`}
          onSave={(value) => handleSetPosition(value)}
          value={value}
          unit="%"
        />
      )}
    </>
  );
};
