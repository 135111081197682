import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  IconLogo,
  IconLogoExalus,
  IconFacebook,
  IconGoogle,
  IconCallButtonCircle,
  IconApple,
  IconLogoWisniowskiSmall,
} from '../../components';
import { environment } from '../../environment';
import { ROUTES } from '../../routes';
import { IntegrationDefaultEnum } from '../../types';
import { isProd } from '../../utils/helpers/environment';
import { isWisniowski } from '../../utils/helpers/wisniowski';
import { useExalusMigrationWhenOnlineUnauthorized } from '../exalus/hooks/common/use-migration-data';
import { useLogin } from './hooks/use-login';
import './index.scss';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('login');
  const { wisniowski, handleLogin, handleSocialLogin, handleRegister } = useLogin();
  const { checkOnlineMigrationWhenUnauthorized } = useExalusMigrationWhenOnlineUnauthorized();

  useEffect(() => {
    checkOnlineMigrationWhenUnauthorized();
  }, []);

  const renderIcon = () => {
    if (isWisniowski) return <IconLogoWisniowskiSmall />;
    else {
      return environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus ? (
        <IconLogoExalus className="exalus" />
      ) : (
        <IconLogo />
      );
    }
  };

  return (
    <div className={classNames('login-page', { wisniowski: wisniowski })}>
      <div className="login-page__up-content">
        <div className={classNames('login-page__logo', { lower: wisniowski })}>{renderIcon()}</div>
        {!wisniowski && <p className="login-page__second-slogan">{t('sloganControlSmartfon')}</p>}
      </div>
      <div className="login-page__buttons">
        <button className="button" onClick={handleLogin}>
          {tc('login')}
        </button>
        <div className="or-separator m-t-24 m-b-24">
          <div>{tc('or')}</div>
        </div>
        <button className="button facebook m-b-16" onClick={() => handleSocialLogin('facebook')}>
          <IconFacebook /> {tc('facebookContinue')}
        </button>
        <button className="button google m-b-16" onClick={() => handleSocialLogin('google')}>
          <IconGoogle />
          {tc('googleContinue')}
        </button>
        {isProd && (
          <button className="button apple m-b-16" onClick={() => handleSocialLogin('apple')}>
            <IconApple />
            {tc('appleContinue')}
          </button>
        )}
        <button className="button button--secondary m-b-16" onClick={handleRegister}>
          {tc('register')}
        </button>

        {wisniowski ? (
          <div className="powered-by">
            <p>Powered by</p>
            <IconLogo />
          </div>
        ) : null}

        <div className="support-link">
          <button onClick={() => history.push(ROUTES.Support())}>
            <IconCallButtonCircle />
            <p>{tc('support')}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
