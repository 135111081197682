import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService, GetParamsErrorCode } from 'lavva.exalushome.extalife';
import { IRob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/IRob21ConfigService';
import {
  LimitSwitchConfiguration,
  TypeOfGateChannel,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigParams';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { toastError } from '../../../../../../utils/toast';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import LimitingSwitchesForm from './limiting-switches';
import OpeningTimeForm from './opening-time';
import PulseTimeForm from './pulse-time';
import TimeBetweenForm from './time-between';
import WorkingModeForm from './working-mode';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const Rob21ConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [mode, setMode] = useState<TypeOfGateChannel | undefined>(undefined);
  const [params, setParams] = useState<number | LimitSwitchConfiguration[] | undefined>(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  useEffect(() => {
    const getMode = async () => {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
        Rob21ConfigService.ServiceName,
      );

      if (channel) {
        const data: TypeOfGateChannel | ResponseResult<GetParamsErrorCode> = await configService.GetGateModeAsync(
          device,
          channel.Number,
        );

        handleGetParamsResponse<TypeOfGateChannel>(data, () => {
          if (
            (data as TypeOfGateChannel) !== TypeOfGateChannel.Gate &&
            (data as TypeOfGateChannel) !== TypeOfGateChannel.Gateway &&
            (data as TypeOfGateChannel) !== TypeOfGateChannel.Monostable
          ) {
            toastError({ content: 'Error' });
            setMode(undefined);
          } else {
            setMode(data as TypeOfGateChannel);
          }
        });
      }

      turnOffBackdrop();
    };

    getMode();
  }, [channel]);

  const getDefaultParams = async (panel: string) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
      Rob21ConfigService.ServiceName,
    );

    switch (panel) {
      case 'WorkingMode': {
        setOpen(true);
        setConfigType(panel);
        break;
      }
      case 'PulseTimeParams': {
        if (channel) {
          const data: number | ResponseResult<GetParamsErrorCode> = await configService.GetPulseTimeAsync(
            device,
            channel.Number,
          );

          handleGetParamsResponse<number>(data, () => {
            setParams(data as number);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'LimitingSwitches': {
        if (channel) {
          const data: LimitSwitchConfiguration[] | ResponseResult<GetParamsErrorCode> =
            await configService.GetLimitingSwitchesConfigurationsAsync(device, channel.Number);

          handleGetParamsResponse<LimitSwitchConfiguration[]>(data, () => {
            setParams(data as LimitSwitchConfiguration[]);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'TimeBetweenPulsesParams': {
        if (channel) {
          const data: number | ResponseResult<GetParamsErrorCode> = await configService.GetTimeBetweenPulsesAsync(
            device,
            channel.Number,
          );

          handleGetParamsResponse<number>(data, () => {
            setParams(data as number);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'OpeningTimeParams': {
        if (channel) {
          const data: number | ResponseResult<GetParamsErrorCode> = await configService.GetOpeningTime(
            device,
            channel.Number,
          );

          handleGetParamsResponse<number>(data, () => {
            setParams(data as number);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'WorkingMode' && channel && (
            <WorkingModeForm device={device} workingMode={mode as TypeOfGateChannel} channel={channel} />
          )}
          {configType === 'PulseTimeParams' && channel && (
            <PulseTimeForm device={device} pulseTime={params as number} channel={channel} />
          )}
          {configType === 'TimeBetweenPulsesParams' && channel && (
            <TimeBetweenForm device={device} timeBetween={params as number} channel={channel} />
          )}
          {configType === 'OpeningTimeParams' && channel && (
            <OpeningTimeForm device={device} openingTime={params as number} channel={channel} />
          )}
          {configType === 'LimitingSwitches' && channel && (
            <LimitingSwitchesForm device={device} switches={params as LimitSwitchConfiguration[]} />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.Rob21Params.WorkingMode')}
                onClick={() => handleOpen('WorkingMode')}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rob21Params.PulseTimeParams')}
                onClick={() => handleOpen('PulseTimeParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rob21Params.LimitingSwitches')}
                onClick={() => handleOpen('LimitingSwitches')}
              />
              {mode === TypeOfGateChannel.Gateway && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.Rob21Params.TimeBetweenPulsesParams')}
                  onClick={() => handleOpen('TimeBetweenPulsesParams')}
                />
              )}
              {mode === TypeOfGateChannel.Gateway && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.Rob21Params.OpeningTimeParams')}
                  onClick={() => handleOpen('OpeningTimeParams')}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rob21ConfigForm;
