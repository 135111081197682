import React from 'react';
import { useTranslation } from 'react-i18next';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { useBackdropContext } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { MeterAnalysis } from './analysis';
import { MeterCurrent } from './current';
import { MeterMeasurements } from './measurements/meter';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsMeter: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { carouselDisabled } = useBackdropContext();

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={carouselDisabled}
      tabs={[{ label: t('currently') }, { label: t('measurement') }, { label: t('analysis') }]}
      additionalTabs={[<MeterMeasurements key="2" channel={channel} />, <MeterAnalysis key="3" channel={channel} />]}
    >
      <MeterCurrent channel={channel} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsMeter;
