import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { SelectOptionInterface } from '../../../../../components';
import {
  AggregateVectorParameterTypeInternal,
  MeasurementChannelKind,
  MeasurementDataRangeInternal,
  MeterAggregatedMeasurementsQueryVariables,
  Query,
} from '../../../../../data-access/gql-types/graphql';
import { METER_AGGREGATED_MEASUREMENTS } from '../../../../../data-access/queries/meter';
import { useApi, useInstallation } from '../../../../../hooks';
import { ChannelMeterInterface, MeasurementRange } from '../../../../../types';
import { SelectedPhase } from '../../../measurement/types';
import { ChartDataItem } from '../index';
import { AnalysisHook, AnalysisHookParams } from '../types';

export const ANALYSIS_SELECTED_PHASES: SelectedPhase[] = [
  {
    value: 1,
    label: '',
    color: '#FF4016',
  },
];

export const useMeterAnalysis = ({ channel }: AnalysisHookParams): AnalysisHook => {
  const { t } = useTranslation('channel-details');
  const { selectedInstallationId } = useInstallation();
  const { convertMeasurementToNumber } = useApi();
  const [activeAggregatedParameter, setActiveAggregatedParameter] = useState<AggregateVectorParameterTypeInternal>(
    AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
  );
  const [activeMeasurementRange, setActiveMeasurementRange] = useState<MeasurementRange>(MeasurementRange.DayInHours);
  const [page, setPage] = useState<number>(0);
  const [getAggregatedMeasurements, { data, loading }] = useLazyQuery<Query, MeterAggregatedMeasurementsQueryVariables>(
    METER_AGGREGATED_MEASUREMENTS,
  );

  const supported = useMemo(() => {
    return (channel?.data as ChannelMeterInterface)?.supportedAggregateVectorParameters;
  }, [(channel?.data as ChannelMeterInterface)?.supportedAggregateVectorParameters]);

  const options = useMemo(
    () =>
      [
        {
          label: t('selectOptions.totalForwardActiveEnergy'),
          value: AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
          enabled: supported.includes(
            convertMeasurementToNumber(MeasurementChannelKind.Meter)(
              'aggregates',
              AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
            ),
          ),
        },
        {
          label: t('selectOptions.totalReverseActiveEnergy'),
          value: AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
          enabled: supported.includes(
            convertMeasurementToNumber(MeasurementChannelKind.Meter)(
              'aggregates',
              AggregateVectorParameterTypeInternal.ReverseActiveEnergy,
            ),
          ),
        },
        {
          label: t('selectOptions.totalForwardReactiveEnergy'),
          value: AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
          enabled: supported.includes(
            convertMeasurementToNumber(MeasurementChannelKind.Meter)(
              'aggregates',
              AggregateVectorParameterTypeInternal.ForwardReactiveEnergy,
            ),
          ),
        },
        {
          label: t('selectOptions.totalReverseReactiveEnergy'),
          value: AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
          enabled: supported.includes(
            convertMeasurementToNumber(MeasurementChannelKind.Meter)(
              'aggregates',
              AggregateVectorParameterTypeInternal.ReverseReactiveEnergy,
            ),
          ),
        },
        {
          label: t('selectOptions.vectorBalancedActiveEnergy'),
          value: AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
          enabled: supported.includes(
            convertMeasurementToNumber(MeasurementChannelKind.Meter)(
              'aggregates',
              AggregateVectorParameterTypeInternal.VectorBalancedActiveEnergy,
            ),
          ),
        },
      ].filter((x) => x.enabled),
    [t, convertMeasurementToNumber, supported],
  );

  const timeRanges: SelectOptionInterface<MeasurementRange>[] = useMemo(
    () => [
      { value: MeasurementRange.DayInHours, label: t('dateAbbreviation.hours') },
      { value: MeasurementRange.MonthInDays, label: t('dateAbbreviation.days') },
      { value: MeasurementRange.WeekInDays, label: t('dateAbbreviation.weeks') },
      { value: MeasurementRange.YearInMonths, label: t('dateAbbreviation.months') },
      { value: MeasurementRange.TenYearsInYears, label: t('dateAbbreviation.years') },
    ],
    [t],
  );

  const relativeActiveMeasurementRange = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours: {
        return MeasurementDataRangeInternal.InHours;
      }
      case MeasurementRange.YearInMonths: {
        return MeasurementDataRangeInternal.InMonths;
      }
      case MeasurementRange.TenYearsInYears: {
        return MeasurementDataRangeInternal.InYears;
      }
      default: {
        return MeasurementDataRangeInternal.InDays;
      }
    }
  }, [activeMeasurementRange]);

  useEffect(() => {
    getAggregatedMeasurements({
      variables: {
        installationId: selectedInstallationId,
        input: {
          channelId: channel.id,
          deviceId: channel.deviceId,
          indices: [1],
          aggregateVectorRequestItems: [
            {
              type: activeAggregatedParameter,
              range: relativeActiveMeasurementRange || MeasurementDataRangeInternal.None,
            },
          ],
        },
      },
    });
  }, [activeAggregatedParameter, relativeActiveMeasurementRange]);

  const filterMeasurementsBySelectedPhases = useMemo(() => {
    return data?.meterAggregatedMeasurements.data?.aggregateVectorMeasurements.filter((phaseMeasurement) =>
      ANALYSIS_SELECTED_PHASES?.map((phase) => phase.value).includes(phaseMeasurement.index),
    );
  }, [data?.meterAggregatedMeasurements]);

  const mappedChartData: ChartDataItem[] = useMemo(() => {
    if (filterMeasurementsBySelectedPhases?.length) {
      const length = filterMeasurementsBySelectedPhases[0].values.length;
      return (
        range(length)?.map((phaseValue, index) => {
          return {
            values: filterMeasurementsBySelectedPhases?.map((phaseMeasurement) => {
              return {
                type: phaseMeasurement.index,
                value: phaseMeasurement.values[index] / 1000,
                color: '#FF4016',
              };
            }),
          };
        }) ?? []
      );
    }

    return [];
  }, [activeMeasurementRange, ANALYSIS_SELECTED_PHASES, data?.meterAggregatedMeasurements]);

  const onClickActiveMeasurementRange = useCallback((value: MeasurementRange) => {
    setActiveMeasurementRange(value);
    setPage(0);
  }, []);

  const handleOnChange = useCallback((value: string) => {
    setActiveAggregatedParameter(value as AggregateVectorParameterTypeInternal);
  }, []);

  return {
    data: data?.meterAggregatedMeasurements?.data,
    isLoading: loading,
    mappedChartData,
    onClickActiveMeasurementRange,
    handleOnChange,
    activeAggregatedParameter,
    activeMeasurementRange,
    options,
    page,
    setPage,
    timeRanges,
  };
};
