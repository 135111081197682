import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { rgbToHex } from '@mui/material';
import {
  BlindPositionChangePayload,
  RequestAckPayload,
  SwitchStateChangePayload,
  BlindCalibrateStatusChangePayload,
  BlindCalibrateStatusTypePayload,
  DeviceConnectionStateChangePayload,
  DeviceFirmwareUpdatedPayload,
  LightToggleStateChangePayload,
  LightBrightnessChangePayload,
  LightColorChangePayload,
  RecoveryDeviceStatusRecievedPayload,
  LightChannelColorStateResponse,
  LightTemperatureChangePayload,
  BlindStateDirectionInternal,
  DeviceConnectionState,
  GatePositionChangePayload,
  GateCalibrateStatusChangePayload,
  GateCalibrateStatusTypePayload,
  GateConfigurationChangePayload,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../../hooks';
import {
  ChannelCoverStateInterface,
  ChannelLightStateInterface,
  ChannelSwitchStateInterface,
  DeviceInterface,
} from '../../../types';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../../types/channel/gate';
import { toastError, toastSuccess } from '../../toast';

export const useUpdateLavvaState = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation('channel-settings');
  const { t: tr } = useTranslation('backend-validation');
  const { channelList, deviceList, setDeviceList, setChannelList } = useDevicesAndChannels();
  const { setChannelState } = useChannelsState();

  const clearAnimationIfExists = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const updateLavvaSwitchState = ({ channelId, state }: SwitchStateChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        lavvaState: state,
      } as ChannelSwitchStateInterface;

      return newState;
    });
  };

  const setCoverAnimation = (
    channelId: string,
    targetPosition: number,
    position: number,
    predictedTimeInMs: number,
  ) => {
    const range = Math.abs(targetPosition - position);

    intervalRef.current = setInterval(() => {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        const prevVal = (newState[channelId] as ChannelCoverStateInterface).lavvaStatePosition || 0;
        const nextValue = targetPosition === prevVal ? prevVal : targetPosition < prevVal ? prevVal - 1 : prevVal + 1;

        if ((nextValue > 0 || nextValue < 100) && nextValue !== prevVal) {
          (newState[channelId] as ChannelCoverStateInterface).lavvaStatePosition = nextValue;
        }

        if (nextValue === targetPosition) {
          clearAnimationIfExists();
        }

        return newState;
      });
    }, predictedTimeInMs / range);
  };

  const updateLavvaBlindPosition = async ({
    channelId,
    position,
    direction,
    predictedTimeInMs,
    targetPosition,
    slatsPredictedTimeInMs,
  }: BlindPositionChangePayload) => {
    clearAnimationIfExists();

    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        lavvaStatePosition: position,
        lavvaStateDirection: direction,
        movingParams: {
          predictedTimeInMs: predictedTimeInMs != null ? predictedTimeInMs : null,
          targetPosition: targetPosition != null ? targetPosition : null,
          slatsPredictedTimeInMs: slatsPredictedTimeInMs != null ? slatsPredictedTimeInMs : null,
        },
        deviceConnectionState: DeviceConnectionState.Connected,
      } as ChannelCoverStateInterface;

      return newState;
    });

    if (predictedTimeInMs && targetPosition != null) {
      if (direction === BlindStateDirectionInternal.Opening && position === 100 && slatsPredictedTimeInMs) {
        timeoutRef.current = setTimeout(() => {
          setCoverAnimation(channelId, targetPosition, position, predictedTimeInMs);
        }, slatsPredictedTimeInMs);
      } else {
        setCoverAnimation(channelId, targetPosition, position, predictedTimeInMs);
      }
    } else {
      clearAnimationIfExists();
    }
  };

  const updateLavvaLightState = ({ channelId, toggleValue }: LightToggleStateChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        toggleValue,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const updateLavvaLightBrightness = ({ channelId, brightness }: LightBrightnessChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        brightness,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const updateLavvaLightTemperature = ({ channelId, temperature }: LightTemperatureChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        temperature,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const updateLavvaLightColor = ({ channelId, color }: LightColorChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        color: {
          ...color,
          hex: rgbToHex(`rgb(${color.r}, ${color.g}, ${color.b})`),
        } as LightChannelColorStateResponse,
      } as ChannelLightStateInterface;

      return newState;
    });
  };

  const onRequestAckReceived = (receivedData: RequestAckPayload) => {
    if (receivedData.status === 'TIMEOUT') {
      clearAnimationIfExists();
    }
  };

  const onDeviceFirmwareUpdated = (receivedData: DeviceFirmwareUpdatedPayload) => {
    const newDeviceList = Array.from(deviceList);
    const deviceIndex = newDeviceList.findIndex((device) => device?.id === receivedData.deviceId);

    if (deviceIndex !== -1) {
      newDeviceList[deviceIndex].isUpdating = false;
      (newDeviceList[deviceIndex] as DeviceInterface).payload.newestFirmwareVersion = '';
      (newDeviceList[deviceIndex] as DeviceInterface).payload.currentFirmwareVersion =
        receivedData.currentFirmwareVersion;
      (newDeviceList[deviceIndex] as DeviceInterface).payload.isUpdateAvailable = false;
      setDeviceList(newDeviceList);
    }
  };

  const setDeviceAsUpdating = (deviceId: string) => {
    setDeviceList((prev) => {
      const newDeviceList = Array.from(prev);
      const deviceIndex = newDeviceList.findIndex((device) => device?.id === deviceId);

      if (deviceIndex !== -1) {
        newDeviceList[deviceIndex].isUpdating = true;
      }

      return newDeviceList;
    });
  };

  const onRecoveryFirmwareUpdated = (receivedData: RecoveryDeviceStatusRecievedPayload) => {
    const newDeviceList = Array.from(deviceList);
    const deviceIndex = newDeviceList.findIndex((device) => device?.id === receivedData.deviceId);

    if (deviceIndex !== -1) {
      (newDeviceList[deviceIndex] as DeviceInterface).recoveryMode = {
        status: receivedData.recoveryDeviceStatus,
        progress: receivedData.progress || undefined,
      };

      setDeviceList(newDeviceList);
    }
  };

  const onDeviceConnectionStateChange = (deviceStateData: DeviceConnectionStateChangePayload) => {
    clearAnimationIfExists();
    const newChannelList = Array.from(channelList);
    const channelsToUpdate = newChannelList.filter((channel) => channel?.deviceId === deviceStateData.deviceId);

    setChannelState((oldState) => {
      const newState = { ...oldState };

      for (let i = 0; i < channelsToUpdate.length; i++) {
        newState[channelsToUpdate[i].id] = {
          ...newState[channelsToUpdate[i].id],
          deviceConnectionState: deviceStateData.deviceConnectionState,
        };
      }

      return newState;
    });
  };

  const usesDepleted = (id: string) => {
    if (id) {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[id] = {
          ...newState[id],
          grantedUses: 0,
          usesCounter: 0,
        };

        return newState;
      });
    }
  };

  const onBlindCalibrateStatusChange = ({
    channelId,
    progress,
    openingMilliseconds,
    closingMilliseconds,
    status,
    error,
  }: BlindCalibrateStatusChangePayload) => {
    if (error) {
      toastError({ content: tr(error) });

      setChannelState((oldState) => {
        const newState = { ...oldState };
        const calibrateState = (newState[channelId] as ChannelCoverStateInterface).calibrateState;
        newState[channelId] = {
          ...(newState[channelId] as ChannelCoverStateInterface),
          calibrateState: {
            openingMilliseconds: calibrateState?.openingMilliseconds || 0,
            closingMilliseconds: calibrateState?.closingMilliseconds || 0,
            error: null,
            progress: null,
            started: calibrateState?.started,
          },
        };

        return newState;
      });
    } else {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...(newState[channelId] as ChannelCoverStateInterface),
          calibrateState: {
            openingMilliseconds: openingMilliseconds || 0,
            closingMilliseconds: closingMilliseconds || 0,
            error: !error ? (newState[channelId] as ChannelCoverStateInterface).calibrateState?.error : error,
            progress,
            started: !!progress,
          },
        };

        return newState;
      });

      const channelAlias = channelList.find((channel) => channel.id === channelId)?.alias;

      if (status === BlindCalibrateStatusTypePayload.Data) {
        toastSuccess({
          content: `${t('toast.calibrationSuccess', { channel: channelAlias })}`,
        });
      }
    }
  };

  // const setGateAnimation = (channelId: string, targetPosition: number, position: number, predictedTimeInMs: number) => {
  //   const range = Math.abs(targetPosition - position);

  //   intervalRef.current = setInterval(() => {
  //     setChannelState((oldState) => {
  //       const newState = { ...oldState };
  //       const prevVal = (newState[channelId] as ChannelGateStateInterface).position || 0;
  //       const nextValue = targetPosition === prevVal ? prevVal : targetPosition < prevVal ? prevVal - 1 : prevVal + 1;

  //       if ((nextValue > 0 || nextValue < 100) && nextValue !== prevVal) {
  //         (newState[channelId] as ChannelGateStateInterface).position = nextValue;
  //       }

  //       if (nextValue === targetPosition) {
  //         clearAnimationIfExists();
  //       }

  //       return newState;
  //     });
  //   }, predictedTimeInMs / range);
  // };

  const updateLavvaGatePosition = async ({
    channelId,
    position,
    direction,
    predictedTimeInMs,
    targetPosition,
  }: GatePositionChangePayload) => {
    // clearAnimationIfExists();

    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        position: position,
        direction: direction,
        movingParams: {
          predictedTimeInMs: predictedTimeInMs != null ? predictedTimeInMs : null,
          targetPosition: targetPosition != null ? targetPosition : null,
        },
        deviceConnectionState: DeviceConnectionState.Connected,
      } as ChannelGateStateInterface;
      return newState;
    });

    // if (predictedTimeInMs && targetPosition != null) {
    //   setGateAnimation(channelId, targetPosition, position, predictedTimeInMs);
    // } else {
    //   clearAnimationIfExists();
    // }
  };

  const updateLavvaGateConfiguration = async ({
    channelId,
    supportedGateFeatures,
  }: Pick<GateConfigurationChangePayload, 'channelId' | 'supportedGateFeatures'>) => {
    setChannelList((prev) => {
      const temp = [...prev];

      const index = temp.findIndex((x) => x.id === channelId);

      if (index !== -1) {
        (temp[index].data as ChannelGateInterface).supportedGateFeatures = supportedGateFeatures;
      }

      return [...temp];
    });
  };

  const onGateCalibrateStatusChange = ({
    channelId,
    progress,
    openingMilliseconds,
    closingMilliseconds,
    status,
    error,
  }: GateCalibrateStatusChangePayload) => {
    if (error) {
      toastError({ content: tr(error) });

      setChannelState((oldState) => {
        const newState = { ...oldState };
        const calibrateState = (newState[channelId] as ChannelGateStateInterface).calibrateState;
        newState[channelId] = {
          ...(newState[channelId] as ChannelGateStateInterface),
          calibrateState: {
            openingMilliseconds: calibrateState?.openingMilliseconds || 0,
            closingMilliseconds: calibrateState?.closingMilliseconds || 0,
            error: null,
            progress: null,
            started: calibrateState?.started,
          },
        };

        return newState;
      });
    } else {
      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...(newState[channelId] as ChannelGateStateInterface),
          calibrateState: {
            openingMilliseconds: openingMilliseconds || 0,
            closingMilliseconds: closingMilliseconds || 0,
            error: !error ? (newState[channelId] as ChannelGateStateInterface).calibrateState?.error : error,
            progress,
            started: !!progress,
          },
        };

        return newState;
      });

      const channelAlias = channelList.find((channel) => channel.id === channelId)?.alias;

      if (status === GateCalibrateStatusTypePayload.Data) {
        toastSuccess({
          content: `${t('toast.calibrationSuccess', { channel: channelAlias })}`,
        });
      }
    }
  };

  return {
    updateLavvaSwitchState,
    updateLavvaBlindPosition,
    updateLavvaGatePosition,
    updateLavvaGateConfiguration,
    updateLavvaLightState,
    updateLavvaLightBrightness,
    updateLavvaLightTemperature,
    updateLavvaLightColor,
    onRequestAckReceived,
    onDeviceConnectionStateChange,
    usesDepleted,
    onBlindCalibrateStatusChange,
    onDeviceFirmwareUpdated,
    onRecoveryFirmwareUpdated,
    setDeviceAsUpdating,
    onGateCalibrateStatusChange,
  };
};
