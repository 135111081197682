import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  GateFeatureTypeInternal,
  GateModeInternal,
} from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { useGate } from '../../../../hooks/use-gate';
import { ChannelInterface } from '../../../../types';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../../../types/channel/gate';
import { IconCirclePadlockClose, IconCirclePadlockOpen, IconCirclePadlockOpenFull } from '../../../icons';
import { InitiateButton } from '../../../initiate-button';
import { RollerControls } from '../../../roller-controls';

interface PropsInterface {
  channels: ChannelInterface[];
  isListItem?: boolean;
  action?: boolean;
}

const stepByStepIcon = {
  1: <IconCirclePadlockClose />,
  2: <IconCirclePadlockOpen />,
  3: <IconCirclePadlockOpenFull />,
};

export const GateControlBox: React.FC<PropsInterface> = ({ channels, isListItem, action }) => {
  const { handleAction, handleStepByStep } = useGate(channels, action);
  const { channelState } = useChannelsState();

  const channelDetails = useMemo(() => {
    return channels.length === 1 && !action ? channels[0] : null;
  }, [action, channels]);

  const handleGateAction = (type: string) => handleAction(type);

  if (channels.some((channel) => channel.data.type !== ChannelTypeInternal.Gate)) return null;

  const isRollUpControls = useMemo(() => {
    if (channels.every((x) => (x.data as ChannelGateInterface).supportedGateFeatures.length)) {
      return channels.every((x) =>
        (x.data as ChannelGateInterface).supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir),
      );
    }

    return channels.every(
      (channel) =>
        channel.data.type === ChannelTypeInternal.Gate &&
        (channel.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp,
    );
  }, [channels]);

  const isStepByStep = useMemo(
    () =>
      channels.every(
        (channel) =>
          channel.data.type === ChannelTypeInternal.Gate &&
          (channel.data as ChannelGateInterface).gateMode === GateModeInternal.StepByStep,
      ),
    [channels],
  );
  const isQuickControl = useMemo(
    () =>
      channels.every(
        (channel) =>
          channel.data.type === ChannelTypeInternal.Gate && (channel.data as ChannelGateInterface).isQuickControl,
      ),
    [channels],
  );

  const gateStep = useMemo(() => {
    if (channelDetails) {
      const position = (channelState[channelDetails.id] as ChannelGateStateInterface).position;
      if (!position) return 3;
      return position === 100 ? 1 : 2;
    }

    return 2;
  }, [channelState[channelDetails?.id || '']]);

  return (
    <>
      {isRollUpControls ? (
        <RollerControls
          handleAction={handleGateAction}
          action={action}
          isListItem={isListItem}
          type={ChannelTypeInternal.Gate}
        />
      ) : isStepByStep && isQuickControl ? (
        <InitiateButton isOn={false} onClickControl={handleStepByStep} isSmall isChannelItem checkStatus>
          {stepByStepIcon[gateStep]}
        </InitiateButton>
      ) : null}
    </>
  );
};
