import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Query, UserProfileQueryVariables } from '../../data-access/gql-types/graphql';
import { USER_ME, USER_PROFILE } from '../../data-access/queries/user';
import { User } from '../../types';
import { isLocalApp } from '../../utils/helpers/local-app';

interface useProfileInterface {
  user: User;
  userInitial: string;
  userId: string | undefined;
  userProfileData: Query | undefined;
  refetchUserMe: () => void;
  refetchUserProfile: () => void;
  loadingUser: boolean;
  tips: string[] | undefined;
}

export const useProfile = (): useProfileInterface => {
  const {
    data,
    refetch: refetchUser,
    loading: loadingMe,
  } = useQuery<Query>(USER_ME, { fetchPolicy: 'cache-first', skip: isLocalApp });

  const {
    data: userProfileData,
    refetch: refetchProfile,
    loading: loadingProfile,
  } = useQuery<Query, UserProfileQueryVariables>(USER_PROFILE, {
    fetchPolicy: 'cache-first',
    skip: isLocalApp,
  });

  const refetchUserMe = () => refetchUser();
  const refetchUserProfile = () => refetchProfile();

  const userInitial = useMemo(() => data?.me?.profile?.firstName.charAt(0) || '', [data]);
  const userId = useMemo(() => data?.me?.profile?.id, [data]);

  const tips = useMemo(() => {
    return (userProfileData?.userProfile?.displayedLearningScenarios as string[]) || undefined;
  }, [userProfileData]);

  return {
    user: {
      profile: {
        firstName: data?.me?.profile?.firstName || '',
        id: data?.me?.profile?.id || '',
        email: data?.me?.profile?.email || '',
      },
      profileImage: {
        imageUrl: data?.me?.profileImage?.imageUrl || '',
      },
    },
    userInitial,
    userId,
    userProfileData,
    tips,
    refetchUserMe,
    refetchUserProfile,
    loadingUser: loadingMe || loadingProfile,
  };
};
