import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ChannelIcon, EmptyStateBox, IconWarning } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { useDevicesAndChannels } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { ChannelInterface } from '../../../types';

interface Params {
  deviceId: string;
  channelId?: string;
}

const DeviceChannels: React.FC = () => {
  const { t } = useTranslation('channel-list');
  const { channelList } = useDevicesAndChannels();
  const [channelListFiltered, setChannelListFiltered] = useState<ChannelInterface[]>([]);
  const history = useHistory();
  const { deviceId } = useParams<Params>();

  useEffect(() => {
    setChannelListFiltered(
      channelList.filter((channel) => channel && channel.deviceId === deviceId) as ChannelInterface[],
    );
  }, [channelList]);

  const deviceTabs = React.useMemo(
    () =>
      channelListFiltered.map((channel) => {
        if (channel) {
          return {
            id: channel.id,
            title: channel.alias,
            type: channel.data.type,
            deviceId: channel.deviceId,
            iconName: channel.iconName,
          };
        }
      }),
    [channelListFiltered],
  );

  const renderChannels = useMemo(() => {
    if (channelListFiltered.length === 0) {
      return <EmptyStateBox header={t('channels')} content={t('channelsEmptyState.content')} icon={<IconWarning />} />;
    }

    return channelListFiltered.map((channel) => {
      if (channel) {
        return (
          <ArrowButton
            key={channel.id}
            title={channel.alias}
            icon={<ChannelIcon channel={channel} />}
            onClick={() => history.push(`${ROUTES.ChannelConfiguration(channel.data.type, channel.id)}?redirect=true`)}
            className="m-b-24 big-radius"
          />
        );
      }
    });
  }, [deviceTabs, channelListFiltered]);

  return <section className="driver-settings__devices grid-list-24 m-t-32">{renderChannels}</section>;
};

export default DeviceChannels;
