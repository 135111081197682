import { useMemo } from 'react';
import { GateModeInternal } from '../../../data-access/gql-types/graphql';
import { ChannelGateInterface, ChannelInterface } from '../../../types';

export const useActionGatesSupportedFeatures = (channels: ChannelInterface[]) => {
  const ventilationSupported = useMemo(() => {
    // if (channels.any((x) => (x.data as ChannelGateInterface).supportedGateFeatures.length > 0)) {
    //   return channels.every((x) =>
    //     (x.data as ChannelGateInterface).supportedGateFeatures.includes(GateFeatureTypeInternal.Tilt),
    //   );
    // }
    return false;
  }, [channels]);

  const setDirectionSupported = useMemo(() => {
    // if (channels.any((x) => (x.data as ChannelGateInterface).supportedGateFeatures.length > 0)) {
    //   return channels.every((x) =>
    //     (x.data as ChannelGateInterface).supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir),
    //   );
    // }

    return channels.every((x) => (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp);
  }, [channels]);

  const setPositionSupported = useMemo(() => {
    // if (channels.any((x) => (x.data as ChannelGateInterface).supportedGateFeatures.length > 0)) {
    //   return channels.every((x) =>
    //     (x.data as ChannelGateInterface).supportedGateFeatures.includes(GateFeatureTypeInternal.SetPos),
    //   );
    // }

    return channels.every((x) => (x.data as ChannelGateInterface).gateMode === GateModeInternal.RollUp);
  }, [channels]);

  return {
    ventilationSupported,
    setDirectionSupported,
    setPositionSupported,
  };
};
