import React from 'react';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useCover } from '../../../../hooks';
import { ChannelInterface } from '../../../../types';
import { RollerControls } from '../../../roller-controls';

interface PropsInterface {
  channels: ChannelInterface[];
  isListItem?: boolean;
  action?: boolean;
}

export const CoverControlBox: React.FC<PropsInterface> = ({ channels, isListItem, action }) => {
  const { handleAction } = useCover(channels, action);

  const handleCoverAction = (type: string) => {
    handleAction(type);
  };

  if (channels.some((channel) => channel.data.type !== ChannelTypeInternal.Blind)) return null;

  return (
    <RollerControls
      handleAction={handleCoverAction}
      action={action}
      isListItem={isListItem}
      type={ChannelTypeInternal.Blind}
    />
  );
};
