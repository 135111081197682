import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChannelTypeInternal,
  MeterTypeInternal,
  StandaloneMeasurementPayload,
} from '../../../../data-access/gql-types/graphql';
import { ChannelInterface, ChannelMeterInterface } from '../../../../types';
import { IconArrowLink, IconEco } from '../../../icons';
import './index.scss';

interface PropsInterface {
  channel: ChannelInterface;
}

export const MeterControl: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');

  const isStandalone = useMemo(() => {
    return channel.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  if (!isStandalone) {
    return null;
  }

  const value = useMemo(() => {
    if (isStandalone && ((channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload)) {
      const payload = (channel.data as ChannelMeterInterface)?.payload as StandaloneMeasurementPayload;
      return payload?.sumOfActivePower;
    }
  }, [channel, isStandalone]);

  if (value === null || !(channel.data as ChannelMeterInterface)?.payload) {
    return null;
  }

  return (
    <div className="meter-control">
      {value !== undefined ? (
        <div className="meter-control__value-wrapper">
          {value < 0 ? <IconEco /> : null}
          <span className="meter-control__value">
            {Math.abs(value / 1000).toFixed(1) !== '0.0' ? (value / 1000).toFixed(1) : '0.0'}
          </span>
          <span className="meter-control__unit">{t('kW')}</span>
          {value ? <IconArrowLink size={22} direction={value < 0 ? 'up' : 'down'} /> : null}
        </div>
      ) : null}
    </div>
  );
};
