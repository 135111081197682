import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  GlobalTimeParameters,
  IInputOutputSwitchParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { SwitchConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchService';
import { LightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { ISlr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr21ConfigService';
import { ISlr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr22ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { LedControllersLightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/SlrConfigParameters';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { LedTimeType } from '../../../device-details/types';
import DisablingTimeForm from '../on-off/disable-time';
import EnablingTimeForm from '../on-off/enable-time';
import FloatingTimeForm from '../on-off/floating-time';
import InputsModeForm from '../on-off/inputs-mode';
import TurnOnBehaviourForm from '../on-off/turn-behaviour';
import DimmingBrighteningTimeForm from './dimming-brightening-time';
import OnOffActivationControllerForm from './on-off-activation';

interface ComponentProps {
  device: IDevice;
  serviceName: string;
  channel?: IDeviceChannel;
}

const SlrConfigForm: React.FC<ComponentProps> = ({ device, serviceName, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [params, setParams] = useState<
    | StateOfTurnOnSwitchDeviceParameters
    | LedControllersLightTransitionTime
    | GlobalTimeParameters
    | boolean
    | IInputOutputSwitchParameters[]
    | undefined
  >(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetSwitchParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: string) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
      ISlr21ConfigService | ISlr22ConfigService
    >(serviceName);

    switch (panel) {
      case 'TurnOnBehaviourParams': {
        if (channel) {
          const data: StateOfTurnOnSwitchDeviceParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetSwitchParamsResponse<StateOfTurnOnSwitchDeviceParameters>(data, () => {
            setParams(data as StateOfTurnOnSwitchDeviceParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'DimmingAndBrighteningParams': {
        if (channel) {
          const data: LedControllersLightTransitionTime | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetDimmingAndBrighteningTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<LedControllersLightTransitionTime>(data, () => {
            setParams(data as LedControllersLightTransitionTime);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'TurnOnOffTimeActivationParams': {
        const data: boolean | ResponseResult<SwitchConfigurationErrorCode> =
          await configService.GetTurnOnOffTimeActivationAsync(device);

        handleGetSwitchParamsResponse<boolean>(data, () => {
          setParams(data as boolean);
          setOpen(true);
          setConfigType(panel);
        });
        break;
      }
      case 'EnableTimeParams': {
        if (channel) {
          const data: GlobalTimeParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetTurnOnTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setParams(data as GlobalTimeParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'DisableTimeParams': {
        if (channel) {
          const data: GlobalTimeParameters | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetTurnOffTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setParams(data as GlobalTimeParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'InputModeParams': {
        if (channel) {
          const data: IInputOutputSwitchParameters[] | ResponseResult<SwitchConfigurationErrorCode> =
            await configService.GetSwitchInputOutputParamAsync(device, channel.Number);

          handleGetSwitchParamsResponse<IInputOutputSwitchParameters[]>(data, () => {
            setParams(data as IInputOutputSwitchParameters[]);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'FloatingTimeParams': {
        if (channel && serviceName === Slr22ConfigService.ServiceName) {
          const data: GlobalTimeParameters | ResponseResult<SwitchConfigurationErrorCode> = await (
            configService as ISlr22ConfigService
          ).GetFloatingTimeAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setParams(data as GlobalTimeParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'TurnOnBehaviourParams' && (
            <TurnOnBehaviourForm device={device} turnOnBehaviour={params as StateOfTurnOnSwitchDeviceParameters} />
          )}
          {configType === 'DimmingAndBrighteningParams' && channel && (
            <DimmingBrighteningTimeForm device={device} time={params as LightTransitionTime} channel={channel} />
          )}
          {configType === 'TurnOnOffTimeActivationParams' && (
            <OnOffActivationControllerForm device={device} activation={params as boolean} />
          )}
          {configType === 'EnableTimeParams' && (
            <EnablingTimeForm device={device} timeParams={params as GlobalTimeParameters} led={LedTimeType.LedTime} />
          )}
          {configType === 'DisableTimeParams' && (
            <DisablingTimeForm device={device} timeParams={params as GlobalTimeParameters} led={LedTimeType.LedTime} />
          )}
          {configType === 'InputModeParams' && (
            <InputsModeForm
              device={device}
              inputs={params as IInputOutputSwitchParameters[]}
              led={LedTimeType.LedTime}
            />
          )}
          {configType === 'FloatingTimeParams' && serviceName === Slr22ConfigService.ServiceName && (
            <FloatingTimeForm
              device={device}
              timeParams={params as GlobalTimeParameters}
              led={LedTimeType.FloatingTime}
            />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.OnOffParams.TurnOnBehaviourParams')}
                onClick={() => handleOpen('TurnOnBehaviourParams')}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.DimmingAndBrighteningParams')}
                onClick={() => handleOpen('DimmingAndBrighteningParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.EnableTimeParams')}
                onClick={() => handleOpen('EnableTimeParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.DisableTimeParams')}
                onClick={() => handleOpen('DisableTimeParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.InputModeParams')}
                onClick={() => handleOpen('InputModeParams')}
              />
              {serviceName === Slr22ConfigService.ServiceName && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.OnOffParams.FloatingTimeParams')}
                  onClick={() => handleOpen('FloatingTimeParams')}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              <ArrowButton
                className="m-t-24"
                title={t('exalus.params.OnOffParams.TurnOnOffTimeActivationParams')}
                onClick={() => handleOpen('TurnOnOffTimeActivationParams')}
              />
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SlrConfigForm;
