import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  CurrentLightThresholdDeviceState,
  MeasuredBrightnessDeviceState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { BrightnessControlHook } from '../types';

export const useBrightnessControl = (channel?: IDeviceChannel, disabled?: boolean): BrightnessControlHook => {
  if (disabled) return { brightness: null, brightnessThreshold: null, time: '' };
  
  const brightnessThreshold = useMemo(() => {
    const brightnessState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.CurrentLightThreshold,
    ) as CurrentLightThresholdDeviceState;

    if (brightnessState) {
      return brightnessState.Data.LightThreshold;
    }

    return null;
  }, [channel?.States]);

  const brightnessData = useMemo(() => {
    const brightnessState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.MeasuredBrightness,
    ) as MeasuredBrightnessDeviceState;

    if (brightnessState) {
      return brightnessState.Data;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (brightnessData?.Time) return format(Date.parse(brightnessData.Time), 'dd.MM, HH:mm');
    return '';
  }, [brightnessData]);

  return {
    brightnessThreshold,
    brightness: brightnessData?.Brightness ?? null,
    time,
  };
};
