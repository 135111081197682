import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconEdit, IconPhoto } from '../../../components';
import { useProfile } from '../../../hooks';
import { toastError } from '../../../utils/toast';

interface PropsInterface {
  profileImageUrl: string | undefined;
  setUseFile: (value: File) => void;
}

const Avatar: React.FC<PropsInterface> = ({ profileImageUrl, setUseFile }) => {
  const { t } = useTranslation('profile');
  const { user } = useProfile();

  const inputPhotoLabel = useMemo(
    () => (
      <span className="m-l-24">
        {t(`${!profileImageUrl ? 'avatar.optionPhoto.labelUpload' : 'avatar.optionPhoto.labelChange'}`)}
      </span>
    ),
    [user],
  );

  const inputPhotoIcon = useMemo(
    () => (!profileImageUrl ? <IconAdd className="icon-add--reverse" /> : <IconEdit />),
    [profileImageUrl],
  );

  const onChangePhoto = useCallback((event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > 15 * 1042 * 1024) {
        toastError({ content: t('avatar.fileTooBig') });
      } else {
        setUseFile(event.target.files[0]);
      }
    }
  }, []);

  return (
    <div className="edit-avatar__option">
      <span className="edit-avatar__option-header">{t('avatar.optionPhoto.header')}</span>
      <label className="edit-avatar__input">
        <div className="edit-avatar__input--left">
          <IconPhoto />
          {inputPhotoLabel}
        </div>
        <div className="edit-avatar__input-file">
          <input type="file" onChange={onChangePhoto} hidden accept="image/*" />
          {inputPhotoIcon}
        </div>
      </label>
    </div>
  );
};

export default Avatar;
