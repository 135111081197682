import React from 'react';
import classNames from 'classnames';
import { Page, Tabs } from '../../../../components';
import { DashboardHeader } from '../../../../components/dashboard-header';
import { useInstallation } from '../../../../hooks';
import { useExalusContext } from '../../context';
import { useExalusStorageContext } from '../../context/storage';
import { useExalusChannelStateGroupChanged } from '../../hooks';
import { ExalusGroups } from './components/groups';
import { UpdateControllerDialog } from './components/update-controller-dialog';
import { useDashboard } from './hooks/use-dashboard';
import './index.scss';

const DashboardContent: React.FC = () => {
  const { selectedInstallation, installationList } = useInstallation();
  const { allGroups } = useExalusContext();
  const { popUpNavLinks, tabs } = useDashboard();
  const { setActiveGroup, activeGroupIndex } = useExalusStorageContext();
  useExalusChannelStateGroupChanged();

  return (
    <Page
      isStickyHeader
      containerClassName="page__container--flex-height"
      carouselContent={installationList.length !== 0 || selectedInstallation !== undefined}
      className={classNames('dashboard ', {
        'dashboard--no-selected-installation': selectedInstallation === undefined,
      })}
      header={
        <>
          <DashboardHeader navLinks={popUpNavLinks} />
          {allGroups.length > 0 && (
            <Tabs
              tabList={tabs}
              activeTabIndex={activeGroupIndex}
              setActiveTabIndex={setActiveGroup}
              tabsType="pills"
              isDivider={false}
              moreButton="secondary"
            />
          )}
        </>
      }
    >
      <ExalusGroups />
      <UpdateControllerDialog />
    </Page>
  );
};

export default DashboardContent;
