import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, IconError } from '../../../components';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { GateModeInternal, InstallationAccessType } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { ChannelGateInterface } from '../../../types/channel/gate';
import { GateHistory } from './components/gate-history';
import { GateRoll } from './components/gate-roll';
import { GateStepByStep } from './components/gate-step-by-step';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelDetailsGate: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [preventSwipeViews, setPreventSwipeViews] = useState<boolean>(false);
  const { selectedInstallation } = useInstallation();

  const gateMode = useMemo(
    () => (channel.data as ChannelGateInterface).gateMode,
    [(channel.data as ChannelGateInterface).gateMode],
  );

  const historyVisible = useMemo(() => {
    const accessType = selectedInstallation?.accessType || InstallationAccessType.Undefined;
    return [InstallationAccessType.Owner, InstallationAccessType.Admin].includes(accessType);
  }, [selectedInstallation]);

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={preventSwipeViews}
      {...(gateMode !== GateModeInternal.Unknown
        ? {
            tabs: [{ label: t('control') }, ...(historyVisible ? [{ label: t('history') }] : [])],
            additionalTabs: [...(historyVisible ? [<GateHistory key={1} channelId={channel.id} />] : [])],
          }
        : {})}
    >
      {gateMode !== GateModeInternal.Unknown ? (
        <>
          {gateMode === GateModeInternal.RollUp ? (
            <GateRoll channel={channel} setPreventSwipeViews={setPreventSwipeViews} />
          ) : (
            <GateStepByStep channel={channel} />
          )}
        </>
      ) : (
        <ControlWrapper>
          <IconError />
          <p style={{ marginLeft: 12, textTransform: 'uppercase' }}>{t('unknowMode')}</p>
        </ControlWrapper>
      )}
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsGate;
