import {
  ChannelTypeInternal,
  PositionFavourites,
  SwitchChannelStateResponse,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum, ChannelInterface, ChannelStateType } from '../../../types';

export const parseSwitchChannel = (userChannel: UserChannel): ChannelInterface => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.LIGHT,
  deviceId: userChannel.deviceId,
  data: {
    type: ChannelTypeInternal.Switch,
    favouritePositionList: (userChannel.favouritePositions as PositionFavourites[])?.map(
      (position) => position.value || 0,
    ) as [number, number, number],
    measuredBy: (userChannel.payload as SwitchChannelStateResponse)?.measuredBy || undefined,
    controlledInput: (userChannel.payload as SwitchChannelStateResponse)?.controlledInput || undefined,
  },
});

export const parseSwitchChannelState = (userChannel: UserChannel): ChannelStateType => ({
  lavvaState: (userChannel.payload as SwitchChannelStateResponse)?.state,
  supplyReturnBehavior: (userChannel.payload as SwitchChannelStateResponse)?.supplyReturnBehavior,
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
