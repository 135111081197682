import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBox, EmptyStateBox, IconWarning } from '../../../../components';
import { UserAction } from '../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../routes';
import { useAddDevice } from '../../../device-add/hooks/use-add-device';

interface PropsInterface {
  list: UserAction[];
  channelListCount: number;
  isAdvanced: boolean;
}

export const UserActionList: React.FC<PropsInterface> = ({ list, channelListCount, isAdvanced = false }) => {
  const { t } = useTranslation('action');
  const { addDevice } = useAddDevice();

  if (!channelListCount) {
    return (
      <EmptyStateBox
        header={t('actionEmptyState.header')}
        btnText={t('actionEmptyState.missingDevice.btnText')}
        content={t('actionEmptyState.missingDevice.content')}
        icon={<IconWarning />}
        onClick={addDevice}
      />
    );
  }

  if (!list?.length) {
    if (isAdvanced) {
      return (
        <EmptyStateBox
          header={t('scenarioActionEmptyState.header')}
          content={t('scenarioActionEmptyState.missingAction.content')}
          btnText={t('scenarioActionEmptyState.missingAction.btnText')}
          icon={<IconWarning />}
          linkTo={ROUTES.ActionCreate()}
        />
      );
    }
    return (
      <EmptyStateBox
        header={t('basicActionEmptyState.header')}
        content={t('basicActionEmptyState.missingAction.content')}
        btnText={t('basicActionEmptyState.missingAction.btnText')}
        icon={<IconWarning />}
        linkTo={ROUTES.ActionCreateBasic()}
      />
    );
  }

  return (
    <div className="grid-list-16 p-b-24">
      {list?.map((userAction) => (
        <ActionBox
          key={userAction.action.id}
          actionData={userAction}
          actionId={userAction.action.id}
          isListItem
          showEvents={isAdvanced}
        />
      ))}
    </div>
  );
};
