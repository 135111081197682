import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { IUpdatesService, UpdateErrorCode } from 'lavva.exalushome/build/js/Services/Updates/IUpdatesService';
import { UpdatesService } from 'lavva.exalushome/build/js/Services/Updates/UpdatesService';
import { DialogConfirmation } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import { diffHours } from '../../../../../../utils/helpers';
import * as exalusStorage from '../../../../../../utils/storage/exalus';

export const UpdateControllerDialog: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const checkControllerUpdateAvailability = async () => {
    const updateService = Api.Get<IUpdatesService>(UpdatesService.ServiceName);
    const result: boolean | ResponseResult<UpdateErrorCode> =
      await updateService.CheckControllerSoftwareUpdateAvailabilityAsync();
    exalusStorage.setItem('remindUpdateController', new Date());

    if (result === true) setShowDialog(true);
  };

  useEffect(() => {
    const remindCreateAccount = exalusStorage.getItem('remindUpdateController');

    if (!remindCreateAccount) {
      checkControllerUpdateAvailability();
    } else {
      if (diffHours(new Date(remindCreateAccount), 24)) {
        checkControllerUpdateAvailability();
      }
    }
  }, []);

  const handleClose = () => setShowDialog(false);

  const handleAction = () => {
    handleClose();
    history.push(`${ROUTES.ExalusControllerSettings()}?view=updates`);
  };

  return (
    <DialogConfirmation
      show={showDialog}
      setShow={handleClose}
      header={t('exalus.updateControllerInfo.header')}
      content={t('exalus.updateControllerInfo.content')}
      primaryBtnText={t('exalus.updateControllerInfo.update')}
      secondaryBtnText={t('exalus.updateControllerInfo.skip')}
      primaryBtnAction={handleAction}
      secondaryBtnAction={handleClose}
      timerCancel
    />
  );
};
