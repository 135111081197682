import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { InstallationAccessType } from '../../../data-access/gql-types/graphql';
import { useInstallation, useNative } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { toastError } from '../../../utils/toast';
import { AddDeviceHook } from '../types';

export const useAddDevice = (): AddDeviceHook => {
  const history = useHistory();
  const { t } = useTranslation('channel-settings');
  const { selectedInstallation } = useInstallation();
  const { setShowBluetoothNotSupportedDialog } = useNative();

  const addDevice = useCallback(() => {
    if (selectedInstallation) {
      if (
        selectedInstallation.accessType === InstallationAccessType.Owner ||
        selectedInstallation.accessType === InstallationAccessType.Admin
      ) {
        if ('bluetooth' in window.navigator) history.push(ROUTES.DeviceAddBluetooth());
        else setShowBluetoothNotSupportedDialog(true);
      } else {
        toastError({ content: t('toast.noPermissions') });
      }
    }
  }, [selectedInstallation]);

  return {
    addDevice,
  };
};
