import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrors } from '../../../hooks/use-errors';
import { PublishStatusRest } from '../../../types';
import { useRestClient } from '../../clients/rest-client';
import { RecoveryBody } from './recovery.types';

export const useGetDeviceStatus = (): UseMutationResult<AxiosResponse<PublishStatusRest>, AxiosError, RecoveryBody> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<PublishStatusRest>, AxiosError, RecoveryBody>(
    ['recovery-getDeviceStatus'],
    async (body) => await restClient.post<PublishStatusRest>('/recovery/getDeviceStatus', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};

export const useUpdateRecoveryFirmware = (): UseMutationResult<
  AxiosResponse<PublishStatusRest>,
  AxiosError,
  RecoveryBody
> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();

  return useMutation<AxiosResponse<PublishStatusRest>, AxiosError, RecoveryBody>(
    ['recovery-updateFirmware'],
    async (body) => await restClient.post<PublishStatusRest>('/recovery/updateFirmware', body),
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};
