import React from 'react';
import {
  MeasurementChannelKind,
  VectorMeasurementResponseItem,
} from '../../../../../../../data-access/gql-types/graphql';
import { useApi } from '../../../../../../../hooks';
import { measurementParameters } from '../../../../../measurement/utils';
import { EnergyConsumptionSummaryElement } from './element';
import './index.scss';

interface PropsInterface {
  measurements: VectorMeasurementResponseItem[];
  itemPrefix?: string;
}

export const EnergyConsumptionSummary: React.FC<PropsInterface> = ({ measurements, itemPrefix }) => {
  const { convertNumberToMeasurement } = useApi();

  return (
    <ul className="energy-consumption-summary">
      {measurements.map((phase, index) => (
        <EnergyConsumptionSummaryElement
          key={index}
          value={phase.value.toFixed(2)}
          index={index}
          itemPrefix={itemPrefix}
          divider={index !== measurements.length - 1}
          unit={
            measurementParameters[convertNumberToMeasurement(MeasurementChannelKind.Meter)('vectors', phase.type)].unit
          }
        />
      ))}
    </ul>
  );
};
