import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Carousel, CarouselItem, EmptyStateBox, IconHeart, IconWarning } from '../../../components';
import { EcoBox } from '../../../components/eco/box';
import { InstallationAccessType, UserGroup } from '../../../data-access/gql-types/graphql';
import { useApi, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { isProd } from '../../../utils/helpers/environment';
import { DashboardItems } from './items';

interface ComponentProps {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  isListView: boolean;
}

export const DashboardCarousel: React.FC<ComponentProps> = ({ activeTabIndex, setActiveTabIndex, isListView }) => {
  const { t: tg } = useTranslation('groups');
  const { dashboardLoading, groups } = useApi();
  const { selectedInstallation, installationsLoading } = useInstallation();

  const canEdit = useCallback(
    (userGroup: UserGroup) => {
      if (selectedInstallation) {
        const { accessType } = selectedInstallation;

        return (
          [InstallationAccessType.Owner, InstallationAccessType.Admin].includes(accessType) ||
          (accessType === InstallationAccessType.User && !userGroup.group.isPublicGroup)
        );
      }
    },
    [selectedInstallation, groups],
  );

  const groupsContent = useMemo(() => {
    return groups?.map((userGroup) => {
      return (
        <CarouselItem key={userGroup.id} className={'m-t-8'}>
          {userGroup.userGroupItems.length === 0 && (
            <>
              {userGroup.group.isDefaultGroup ? (
                <EmptyStateBox
                  header={tg('favourites.header')}
                  btnText={tg('favourites.add')}
                  content={tg('favourites.emptyState')}
                  icon={<IconHeart isEmpty />}
                  linkTo={ROUTES.FavouritesForm(userGroup.id)}
                />
              ) : (
                <EmptyStateBox
                  header={tg('custom.header')}
                  btnText={tg('custom.add')}
                  content={tg(`custom.${canEdit(userGroup) ? 'emptyState' : 'emptyStateNoPermission'}`)}
                  icon={<IconWarning />}
                  {...(canEdit(userGroup) ? { linkTo: ROUTES.GroupEdit(userGroup.id) } : {})}
                />
              )}
            </>
          )}
          {userGroup.userGroupItems.length > 0 && (
            <div
              className={classNames('p-b-24 dashboard__device-list', {
                'grid grid--2 grid--small-gap': !isListView,
                'grid-list-16': isListView,
              })}
            >
              {userGroup.group.isDefaultGroup && !isProd ? <EcoBox level={0} isListItem={isListView} /> : null}
              <DashboardItems group={userGroup} isListView={isListView} />
            </div>
          )}
        </CarouselItem>
      );
    });
  }, [groups, isListView]);

  return (
    <>
      {!dashboardLoading && !installationsLoading && (
        <Carousel setActiveIndex={setActiveTabIndex} activeIndex={activeTabIndex}>
          {groupsContent}
        </Carousel>
      )}
    </>
  );
};
