import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwitchSetOnTime } from '../../../../api/modules/switch/switch.hooks';
import { TimeActivationButton } from '../../../../components';
import { ChannelInterface } from '../../../../types';
import { SetActivationTimeDialog } from '../set-activation-time-dialog';

interface PropsInterface {
  channel: ChannelInterface;
  value: number;
  index: number;
  onFavouriteTimeChange: (value: number, index: number) => void;
  onClick?: () => void;
  onHold?: () => void;
}

export const TimeActivation: React.FC<PropsInterface> = ({ channel, value, index, onFavouriteTimeChange }) => {
  const { t } = useTranslation('channel-details');
  const [showActivationTimeDialog, setShowActivationTimeDialog] = useState<boolean>(false);
  const switchOnTime = useSwitchSetOnTime();

  const onClick = useCallback(() => {
    switchOnTime.mutate({
      channelId: channel.id,
      deviceId: channel.deviceId,
      timeInMilliseconds: value,
    });
  }, [value]);

  const onHold = useCallback(() => {
    setShowActivationTimeDialog(true);
  }, [value]);

  return (
    <>
      <TimeActivationButton value={value} onHold={onHold} onClick={onClick} />
      <SetActivationTimeDialog
        open={showActivationTimeDialog}
        setOpen={setShowActivationTimeDialog}
        title={t('timeActivationDialog')}
        onSave={(value) => onFavouriteTimeChange(value, index)}
        miliseconds={value}
      />
    </>
  );
};
