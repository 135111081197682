import React, { useEffect, useState } from 'react';
import { DayOfWeekArgument } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SubmitButton } from '../../../../../../../../components';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { Days } from '../../../../components/days';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { DaysOfWeekOption, TriggerMethodType } from '../../../../types';
import { useActionParameterOptions } from '../../../../utils/use-action-parameter-options';

export const DaysOfWeekSource: React.FC = () => {
  const { daysOfWeek } = useActionParameterOptions();
  const { builder } = useExalusCreateActionContext();
  const {
    triggerMethod,
    conditionType,
    setConditionType,
    seq,
    atMeetDisabled,
    setTriggerMethod,
    finalizeSequenceSubmit,
  } = useConditionEdit(true);
  const [days, updateDays] = useState<DaysOfWeekOption[]>(daysOfWeek);

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDaysOfWeek;

    if (argument) {
      updateDays(daysOfWeek.map((x) => ({ ...x, checked: !!argument.find((y) => y === x.value) })));
    }
  }, [seq]);

  const handleSubmit = () => {
    const argument = builder?.ArgumentAsDaysOfWeek(
      days.filter((x) => x.checked).map((x) => x.value) as DayOfWeekArgument[],
      triggerMethod === TriggerMethodType.WhenChangeOn,
      conditionType,
    );

    finalizeSequenceSubmit(argument, -3);
  };

  return (
    <>
      <div className="grid-list-24">
        {!atMeetDisabled ? (
          <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
        ) : null}
        <ConditionTypeSelect
          conditionType={conditionType}
          setConditionType={setConditionType}
          allow={[ConditionsTypes.Equal, ConditionsTypes.NotEqueal]}
        />
      </div>
      <Days days={days} updateDays={updateDays} />
      <SubmitButton disabled={!days.filter((x) => x.checked).length} onClick={handleSubmit} />
    </>
  );
};
