import React, { useEffect, useState } from 'react';
import { Hours, Minutes, Seconds } from 'lavva.exalushome/build/js/Helpers';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SubmitButton } from '../../../../../../../../components';
import { leadingZero } from '../../../../../../../../utils/helpers';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { TimeInput } from '../../../../components/time-input';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';
import { getTimeParts } from '../../../../utils/time';

export const TimeSource: React.FC = () => {
  const { builder } = useExalusCreateActionContext();
  const [time, setTime] = useState<string>('00:00:00');
  const {
    triggerMethod,
    atMeetDisabled,
    seq,
    conditionType,
    setConditionType,
    setTriggerMethod,
    finalizeSequenceSubmit,
  } = useConditionEdit(true);

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsTime;

    if (argument) {
      const time = [argument.Hour, argument.Minute, argument.Second].map(leadingZero).join(':');
      setTime(time);
    }
  }, [seq]);

  const handleSubmit = () => {
    const timeParts = getTimeParts(time);
    const argument = builder?.ArgumentAsTime(
      {
        Hour: timeParts.hours as Hours,
        Minute: timeParts.minutes as Minutes,
        Second: timeParts.seconds as Seconds,
      },
      triggerMethod === TriggerMethodType.WhenChangeOn,
      conditionType,
    );

    finalizeSequenceSubmit(argument, -3);
  };

  return (
    <div className="grid-list-24">
      {!atMeetDisabled ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={
          !atMeetDisabled
            ? [
                ConditionsTypes.Equal,
                ConditionsTypes.NotEqueal,
                ConditionsTypes.BiggerThan,
                ConditionsTypes.BiggerThanOrEqual,
                ConditionsTypes.SmallerThan,
                ConditionsTypes.SmallerThanOrEqual,
              ]
            : [ConditionsTypes.Equal]
        }
      />
      <TimeInput time={time} setTime={setTime} />
      <SubmitButton onClick={handleSubmit} />
    </div>
  );
};
