import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useMutation } from '@apollo/client';
import { Page, SubmitButton } from '../../components';
import {
  ChangeGroupItemsOrderMutation,
  ChangeGroupItemsOrderMutationVariables,
  UserGroup,
} from '../../data-access/gql-types/graphql';
import { CHANGE_GROUP_ITEMS_ORDER } from '../../data-access/mutations/groups';
import { useApi, useInstallation, useBackdropContext } from '../../hooks';
import { useRefetchData } from '../../hooks/refresh-data';
import * as storage from '../../utils/storage/lavva';
import { toastError } from '../../utils/toast';
import { GroupSortChannelsHeader, GroupsSortChannelsBody } from './components';
import { GroupSortable, UserGroupItemSortable } from './types';

const GroupSortChannels: React.FC = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallation();
  const { groups: groupsData } = useApi();
  const [activeGroup, setActiveGroup] = useState<GroupSortable>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchDashboard } = useRefetchData();
  const [changeGroupItemsOrder, { loading }] = useMutation<
    ChangeGroupItemsOrderMutation,
    ChangeGroupItemsOrderMutationVariables
  >(CHANGE_GROUP_ITEMS_ORDER);

  useEffect(() => {
    const index = groupsData.findIndex((group: UserGroup) => group.id === storage.getItem('lastActiveGroupId'));
    if (index !== -1) setActiveTabIndex(index);
  }, [groupsData]);

  useEffect(() => {
    const userGroup = groupsData[activeTabIndex];
    if (userGroup) {
      setIsValid(false);
      storage.setItem('lastActiveGroupId', String(userGroup.id));

      setActiveGroup({
        id: userGroup.id,
        items: orderBy(userGroup.userGroupItems, 'order').map((x) => ({
          ...x,
          id: x.itemId,
          resourceType: userGroup.group.groupItems.find((it) => it.id === x.itemId)?.resourceType,
        })),
      });
    }
  }, [groupsData, activeTabIndex]);

  const onSave = useCallback(() => {
    if (activeGroup) {
      turnOnBackdrop();

      changeGroupItemsOrder({
        variables: {
          input: {
            installationId: selectedInstallationId,
            groupId: activeGroup.id,
            items: activeGroup.items?.map((item: UserGroupItemSortable, index: number) => ({
              itemId: item.id,
              order: index,
            })),
          },
        },
        onCompleted: (data) => {
          turnOffBackdrop();

          if (data.changeGroupItemsOrder.id) {
            refetchDashboard();
            history.goBack();
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
            // TODO errors
          }
        },
        onError: () => turnOffBackdrop(),
      });
    }
  }, [activeGroup]);

  return (
    <Page
      isStickyHeader
      header={<GroupSortChannelsHeader setActiveTabIndex={setActiveTabIndex} activeTabIndex={activeTabIndex} />}
    >
      {activeGroup?.items && (
        <GroupsSortChannelsBody
          setActiveGroup={setActiveGroup}
          activeTabIndex={activeTabIndex}
          activeGroup={activeGroup}
          setIsValid={setIsValid}
        />
      )}
      <SubmitButton onClick={onSave} disabled={!isValid} isLoading={loading} />
    </Page>
  );
};

export default GroupSortChannels;
