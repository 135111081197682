import { ChannelTypeInternal, UserChannel } from '../../../data-access/gql-types/graphql';
import {
  parseCoverChannel,
  parseCoverChannelState,
  parseLightChannel,
  parseLightChannelState,
  parseMeterChannel,
  parseMeterStateChannel,
  parseOptimizerChannel,
  parseOptimizerStateChannel,
  parseSwitchChannel,
  parseSwitchChannelState,
} from '../parse';
import { parseGateChannel, parseGateChannelState } from '../parse/gate';

export const parseFavouritePositions = (favouritePositionList: [number, number, number], defaultList: number[]) => {
  if (favouritePositionList) {
    if (favouritePositionList?.length === 3 && favouritePositionList.every((value) => Number(value))) {
      return favouritePositionList;
    } else {
      return [
        favouritePositionList[0] !== null ? favouritePositionList[0] : defaultList[0],
        favouritePositionList[1] !== null ? favouritePositionList[1] : defaultList[1],
        favouritePositionList[2] !== null ? favouritePositionList[2] : defaultList[2],
      ];
    }
  } else return defaultList;
};

export const parseChannel = (userChannel: UserChannel) => {
  switch (userChannel.channelType) {
    case ChannelTypeInternal.Blind:
      return parseCoverChannel(userChannel);
    case ChannelTypeInternal.Switch:
      return parseSwitchChannel(userChannel);
    case ChannelTypeInternal.Meter:
      return parseMeterChannel(userChannel);
    case ChannelTypeInternal.Optimizer:
      return parseOptimizerChannel(userChannel);
    case ChannelTypeInternal.Light:
      return parseLightChannel(userChannel);
    case ChannelTypeInternal.Gate: 
      return parseGateChannel(userChannel);
    default:
      return undefined;
  }
};

export const parseChannelState = (userChannel: UserChannel) => {
  switch (userChannel.channelType) {
    case ChannelTypeInternal.Blind:
      return parseCoverChannelState(userChannel);
    case ChannelTypeInternal.Switch:
      return parseSwitchChannelState(userChannel);
    case ChannelTypeInternal.Light:
      return parseLightChannelState(userChannel);
    case ChannelTypeInternal.Gate:
      return parseGateChannelState(userChannel);
    case ChannelTypeInternal.Meter:
      return parseMeterStateChannel(userChannel);
    case ChannelTypeInternal.Optimizer:
      return parseOptimizerStateChannel(userChannel);
    default:
      return undefined;
  }
};
