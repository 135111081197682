import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconSchedule } from '../../../../../../components';

interface PropsInterface {
  averageValue: number;
  minValue: number;
  maxValue: number;
  fixed: number;
  unit: string;
}

export const CurrentAbsoluteValues: React.FC<PropsInterface> = ({ averageValue, minValue, maxValue, fixed, unit }) => {
  const { t } = useTranslation('channel-details');

  return (
    <div className="summary summary--background-element">
      <div className="summary__caption p-l-0 p-r-0">
        <IconSchedule />
        <span className="summary__caption-text">{t('energyMeasurements.summary')}</span>
      </div>
      <div className="summary__section p-t-0 p-b-0 p-l-0 p-r-0 max-width-desktop">
        <div className="summary__section-row">
          <span>{t('summary.average')}</span>
          <span className="summary__section-value">
            {+averageValue.toFixed(fixed)} {unit}
          </span>
        </div>
        <div className="summary__section-row">
          <span>{t('summary.max')}</span>
          <span className="summary__section-value">
            {+maxValue.toFixed(fixed)} {unit}
          </span>
        </div>
        <div className="summary__section-row">
          <span>{t('summary.min')}</span>
          <span className="summary__section-value">
            {+minValue.toFixed(fixed)} {unit}
          </span>
        </div>
      </div>
    </div>
  );
};
