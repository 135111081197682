import { leadingZero } from './leading-zero';

type ConvertedHoursMinutes = {
  convertedHours: number;
  convertedMinutes: number;
};

type HoursMinutes = {
  hours: number;
  minutes: number;
};

type MinutesSeconds = {
  minutes: number;
  seconds: number;
};

type ConvertedMilisecondsMinutes = {
  minutes: number;
  seconds: number;
};

export const convertMinutesToHours = (minutes: number): ConvertedHoursMinutes => {
  const hours = minutes / 60;
  const convertedHours = Math.floor(hours);
  const convertedMinutes = Math.round((hours - convertedHours) * 60);

  return {
    convertedHours,
    convertedMinutes,
  };
};

export const convertHoursToMinutes = ({ hours, minutes }: HoursMinutes): number => {
  return hours * 60 + minutes;
};

export const convertMinutesToSeconds = ({ minutes, seconds }: MinutesSeconds): number => {
  return minutes * 60 + seconds;
};

export const convertMSToMinutesSeconds = (miniseconds: number): ConvertedMilisecondsMinutes => {
  const date = new Date(miniseconds);
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return { minutes, seconds };
};

export const convertSecondsToHMS = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  return `${leadingZero(h)}:${leadingZero(m)}:${leadingZero(s)}`;
};

export const diffHours = (oldDate: Date, hours?: number) => {
  const newDate = new Date();
  let diff = (newDate.getTime() - oldDate.getTime()) / 1000;
  diff /= 60 * 60;
  const diffHours = Math.abs(Math.round(diff));
  const hoursCount = hours || 24;
  return diffHours >= hoursCount;
};

export const timeToMilliseconds = (timeStr: string) => {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  const milliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;

  return milliseconds;
};

export const millisecondsToTime = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
