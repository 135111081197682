import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmptyStateBox,
  NavHeader,
  NotificationItem,
  Page,
  Header,
  DialogConfirmation,
  Tabs,
  PopUpNav,
} from '../../components';
import { NotificationTypeEnum, NotificationInvitationType } from '../../types';
import { useNotificationList } from './hooks/use-notification-list';

const NotificationList: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('notifications');
  const {
    tabs,
    popUpNavLinks,
    activeTabIndex,
    showClearAllDialog,
    notificationList,
    onClearAll,
    onClearAllCancel,
    handleChangeTab,
    setShowClearAllDialog,
  } = useNotificationList();

  const notifications = useMemo(
    () => notificationList.filter((x) => !x.isHidden).filter((x) => (activeTabIndex === 0 ? x.id : !x.wasDisplayed)),
    [notificationList, activeTabIndex],
  );

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader>
            <PopUpNav links={popUpNavLinks} />
          </NavHeader>
          <Header title={t('notifications')} />
          <Tabs tabList={tabs} activeTabIndex={activeTabIndex} setActiveTabIndex={handleChangeTab} tabsType="pills" />
        </>
      }
    >
      <div className="notifications grid-list-16 p-b-24">
        {!notifications.length ? (
          <EmptyStateBox header={t('noNotifications.header')} content={t('noNotifications.content')} />
        ) : (
          notifications
            .sort((a, b) => b.createdTimestampUTC - a.createdTimestampUTC)
            .map((notification) => {
              if (notification.data.type === NotificationTypeEnum.INVITATION) {
                switch (notification.data.invitationType) {
                  case NotificationInvitationType.InvitationReceived:
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        title={t('invitationReceived.title')}
                      >
                        <p>
                          {t('invitationReceived.message', { installationName: notification.data.installationName })}
                        </p>
                      </NotificationItem>
                    );
                  case NotificationInvitationType.InvitationAcceptedByInvitee:
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        title={t('invitationAcceptedByInvitee.title')}
                      >
                        <p>
                          {t('invitationAcceptedByInvitee.message', {
                            installationName: notification.data.installationName,
                            inviteeName: notification.data.userName,
                          })}
                        </p>
                      </NotificationItem>
                    );
                  case NotificationInvitationType.InvitationRejectedByInvitee:
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        title={t('invitationRejectedByInvitee.title')}
                      >
                        <p>
                          {t('invitationRejectedByInvitee.message', {
                            installationName: notification.data.installationName,
                            inviteeName: notification.data.userName,
                          })}
                        </p>
                      </NotificationItem>
                    );
                  case NotificationInvitationType.InvitationCanceledByCreator:
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        title={t('InvitationCanceledByCreator.title')}
                      >
                        <p>
                          {t('InvitationCanceledByCreator.message', {
                            installationName: notification.data.installationName,
                            creatorName: notification.data.userName,
                          })}
                        </p>
                      </NotificationItem>
                    );
                  default:
                    return null;
                }
              } else {
                return (
                  <NotificationItem key={notification.id} notification={notification} title={notification.data.title}>
                    <p>{notification.data.message}</p>
                  </NotificationItem>
                );
              }
            })
        )}
      </div>
      <DialogConfirmation
        show={showClearAllDialog}
        setShow={setShowClearAllDialog}
        header={t('clearAllContent')}
        primaryBtnText={tc('buttons.clear')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={onClearAll}
        secondaryBtnAction={onClearAllCancel}
      />
    </Page>
  );
};

export default NotificationList;
