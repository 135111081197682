import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ArrowButton from '../../../../components/arrow-button';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../../routes';

const OptimizerConfiguration: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();

  const configureSchedule = () => history.push(ROUTES.OptimizerSchedule(channelId));

  return <ArrowButton title={`${t('schedule.label')} (COMING SOON)`} onClick={configureSchedule} small />;
};

export default OptimizerConfiguration;
