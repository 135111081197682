import React, { useMemo, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { DialogSelect, SelectOptionInterface } from '../dialog';
import { IconChevron } from '../icons';
import './index.scss';

interface PropsInterface<T> {
  options: SelectOptionInterface<T>[];
  id?: string;
  name?: string;
  label: string;
  hiddenLabel?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (value: T) => void;
  icon?: ReactNode;
  openDialog?: boolean;
  setOpenDialog?: (show: boolean) => void;
  className?: string;
  inputComponent?: ReactNode;
  value?: T;
}

export const InputSelect = <T extends string | number | readonly string[] | undefined>({
  options,
  id = uuid.v4(),
  name,
  label,
  hiddenLabel = false,
  disabled = false,
  onChange,
  icon = <IconChevron direction="right" noSpace />,
  openDialog = false,
  errorMessage = '',
  setOpenDialog,
  className,
  inputComponent,
  value,
}: PropsInterface<T>) => {
  const { t } = useTranslation('common');
  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);

  const option = useMemo(() => {
    return options.find((op) => op.value === value);
  }, [value, options]);

  useEffect(() => setShowSelectDialog(openDialog), [openDialog]);

  const onClick = () => setShowSelectDialog(true);

  const dialogOnChange = (value: T) => {
    setShowSelectDialog(false);
    if (onChange) onChange(value);
  };

  const setShow = (value: boolean) => {
    setShowSelectDialog(value);
    setOpenDialog && setOpenDialog(value);
  };

  return (
    <>
      {inputComponent ? (
        <div onClick={onClick}>{inputComponent}</div>
      ) : (
        <div
          className={classNames('input', {
            'input--disabled': disabled,
            [className as string]: className,
          })}
          {...(!disabled ? { onClick } : {})}
        >
          {label && !hiddenLabel && (
            <label className="input__label" htmlFor={id}>
              {label}
            </label>
          )}
          <div className="input__wrapper">
            <input id={id} name={name} type="text" value={option?.value} disabled={disabled} hidden readOnly />
            <span className={classNames('input__input', { clicable: !disabled })}>
              {option?.label ? `${option.label}${option.intValue ? ` (${option.intValue})` : ''}` : t('select')}
            </span>
            <div className="input__icon input__icon--unset">{icon}</div>
          </div>
          {errorMessage && <div className="input__error-message">{errorMessage}</div>}{' '}
        </div>
      )}

      <DialogSelect
        show={showSelectDialog && !disabled}
        setShow={setShow}
        title={label}
        options={options}
        value={value}
        onChange={dialogOnChange}
      />
    </>
  );
};
