import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNative } from '../../../hooks';
import { useErrors } from '../../../hooks/use-errors';
import { toastSuccess } from '../../../utils/toast';
import { useRestClient } from '../../clients/rest-client';
import { useUpdateChannel } from '../channels';
import { DeviceResponse } from '../device/device.types';
import {
  GateSetPositionBody,
  GateSetDirectionBody,
  GateStepByStepBody,
  GateTiltBody,
  GateCalibrateBody,
} from './gate.types';

export const useGateSetPosition = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  GateSetPositionBody
> => {
  const restClient = useRestClient();
  const { setChannelAsDisconnected } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GateSetPositionBody>(
    ['gate-setPosition'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/gate/setPosition', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useGateSetDirection = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  GateSetDirectionBody
> => {
  const restClient = useRestClient();
  const { setChannelAsDisconnected, resetCalibrateState } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GateSetDirectionBody>(
    ['gate-setDirection'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/gate/setDirection', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
          resetCalibrateState(body.channelId, data);
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useGateStepByStep = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  GateStepByStepBody
> => {
  const restClient = useRestClient();
  const { setChannelAsDisconnected } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GateStepByStepBody>(
    ['gate-stepByStep'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/gate/stepByStep', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useGateTilt = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, GateTiltBody> => {
  const restClient = useRestClient();
  const { setChannelAsDisconnected } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GateTiltBody>(
    ['gate-tilt'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/gate/tilt', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useGateCalibrate = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, GateCalibrateBody> => {
  const { t: td } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { setChannelAsDisconnected } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, GateCalibrateBody>(
    ['gate-calibrate'],
    async (body) => await restClient.post<DeviceResponse>('/gate/calibrate', body),
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
          toastSuccess({ content: td('toast.calibrationStartSent') });
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};
