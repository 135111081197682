import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ReedStateDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ReedControlHook } from '../types';

export const useReedControl = (channel?: IDeviceChannel, disabled?: boolean): ReedControlHook => {
  if (disabled) return { reed: null, time: '' };

  const reedData = useMemo(() => {
    const reedState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.ReedState,
    ) as ReedStateDeviceState;

    if (reedState) {
      return reedState.Data;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (reedData?.Time) return format(Date.parse(reedData.Time), 'dd.MM, HH:mm');
    return '';
  }, [reedData]);

  return {
    reed: reedData?.State ?? null,
    time,
  };
};
