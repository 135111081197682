export enum MeasurementChannelKindNumber {
  UNKNOWN = 0,
  METER = 1,
  OPTIMIZER = 2,
}

export interface MeasurementsResetBody {
  channelId: string;
  kind: MeasurementChannelKindNumber;
}

export interface MeterErrorGlobal {
  description: string;
  errorCode: string;
}
