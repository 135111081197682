import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService, GetParamsErrorCode } from 'lavva.exalushome.extalife';
import { IRcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/IRcr21ConfigService';
import {
  MovementDetectorParams,
  PirSensorParams,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigParams';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import DetectorForm from './detector';
import RipForm from './rip';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const Rcr21ConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [params, setParams] = useState<PirSensorParams | MovementDetectorParams | undefined>(undefined);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    handleSubmitResponse,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: string) => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcr21ConfigService>(
        Rcr21ConfigService.ServiceName,
      );

      switch (panel) {
        case 'RipSensorParams': {
          const data: PirSensorParams | ResponseResult<GetParamsErrorCode> =
            await configService.GetPirSensorParamsAsync(device, channel.Number);

          handleGetParamsResponse<PirSensorParams>(data, () => {
            setParams(data as PirSensorParams);
            setOpen(true);
            setConfigType(panel);
          });
          break;
        }
        case 'MovementDetectorParams': {
          const data: MovementDetectorParams | ResponseResult<GetParamsErrorCode> =
            await configService.GetMovementDetectorParamsAsync(device, channel.Number);

          handleGetParamsResponse<MovementDetectorParams>(data, () => {
            setParams(data as MovementDetectorParams);
            setOpen(true);
            setConfigType(panel);
          });
          break;
        }
        default:
          break;
      }

      turnOffBackdrop();
    }
  };

  const resetAlarm = useCallback(async () => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcr21ConfigService>(
        Rcr21ConfigService.ServiceName,
      );

      const result = await configService.ClearTamperAlarmAsync(device, channel.Number);

      handleSubmitResponse(result, 'alarmResetSuccessfully');

      turnOffBackdrop();
    }
  }, [channel]);

  const handleOpen = useCallback((panel: string) => getDefaultParams(panel), []);

  const handleBack = useCallback(() => {
    setOpen(false);
    setConfigType('');
  }, []);

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'RipSensorParams' && channel && (
            <RipForm device={device} channel={channel} params={params as PirSensorParams} />
          )}
          {configType === 'MovementDetectorParams' && channel && (
            <DetectorForm device={device} channel={channel} params={params as MovementDetectorParams} />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rcr21Params.RipSensorParams')}
                onClick={() => handleOpen('RipSensorParams')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rcr21Params.MovementDetectorParams')}
                onClick={() => handleOpen('MovementDetectorParams')}
              />
              <ArrowButton className="m-b-24" title={t('exalus.params.Rcr21Params.ResetAlarm')} onClick={resetAlarm} />
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rcr21ConfigForm;
