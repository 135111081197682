import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType as ChannelTaskType,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindRemoteButtonState,
  BlindStatusCode,
  ConfigurationStateEnum,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { BlindPositionDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import {
  ControlWrapper,
  EmptyStateBox,
  IconChevron,
  IconConnectionError,
  IconWarning,
  Slider,
} from '../../../../../../../components';
import LavvaDetailsWrapper from '../../../../../../../components/details-wrapper';
import { useSomethingWrong } from '../../../../../pages/channel-list/hooks/use-something-wrong';
import { canChannelDo } from '../../../../../utils';
import { BlindDetailsSimpleControlButtons } from '../../../../control-buttons/blind-details/simple';
import { useBlindControl } from '../../../hooks/use-blind-control';
import Favourites from './favourites';

interface ComponentProps {
  channel: IDeviceChannel;
  active: boolean;
}

export const BlindDetailsControls: React.FC<ComponentProps> = ({ channel, active }) => {
  const { t: tc } = useTranslation('common');
  const { t: td } = useTranslation('channel-details');
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [targetValue, setTargetValue] = useState<number>(0);
  const { open, stop, close, handleAction, handleSliderEvent } = useBlindControl(channel);
  const { connectionIcon, calibrationNeed, channelOrientedError } = useSomethingWrong(channel, { detailsView: true });

  const state = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.BlindPosition,
    ) as BlindPositionDeviceState;
  }, [channel.States]);

  const status = useMemo(() => (state?.GetBlindStatus ? state.GetBlindStatus() : null), [state]);

  const isViewSimple = useMemo(() => canChannelDo(channel, [ChannelTaskType.SetBlindPositionSimple]), [channel]);

  const remoteButtonState = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState,
    ) as IChannelState<BlindRemoteButtonState>;
  }, [channel.States]);

  useEffect(() => {
    if (typeof state?.Data?.Position === 'number') {
      if (state?.Data?.Position === sliderValue && targetValue !== sliderValue) return;
      setSliderValue(state?.Data?.Position);
      setTargetValue(state?.Data?.Position);
    }
  }, [channel.States]);

  const position = useMemo(() => {
    return state?.Data?.Position ?? null;
  }, [channel.States]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetValue(Number(event.target.value));
  }, []);

  const handleDown = useCallback(() => {
    handleAction(BlindActionEnum.Close);
  }, []);

  const handleStop = useCallback(() => {
    handleAction(BlindActionEnum.Stop);
    setTargetValue(sliderValue);
  }, [sliderValue]);

  const handleUp = useCallback(() => {
    handleAction(BlindActionEnum.Open);
  }, []);

  return (
    <>
      {(status === BlindStatusCode.UnableToMove || status === BlindStatusCode.MotorOverheatedPleaseWait) && (
        <EmptyStateBox
          header={td(`exalus.blind.status.${BlindStatusCode[status]}.header`)}
          content={td(`exalus.blind.status.${BlindStatusCode[status]}.content`)}
          icon={<IconWarning />}
          className="m-b-24"
        />
      )}
      <LavvaDetailsWrapper>
        <div
          className={classNames('cover-detail-view', {
            'cover-detail-view--full-space': isViewSimple || calibrationNeed,
          })}
        >
          {isViewSimple || calibrationNeed ? (
            <ControlWrapper>
              {connectionIcon && <IconConnectionError big size={14} />}
              {calibrationNeed && (
                <EmptyStateBox
                  header={tc('status.calibrate')}
                  content={td(
                    calibrationNeed !== ConfigurationStateEnum.OngoingConfiguration
                      ? 'calibrationInProgressInfo'
                      : 'calibrationNeededSimple',
                  )}
                  icon={<IconWarning />}
                  className="m-b-24"
                />
              )}
              <div>
                <BlindDetailsSimpleControlButtons
                  open={open}
                  stop={stop}
                  close={close}
                  remoteButtonState={remoteButtonState}
                  active={active}
                />
              </div>
            </ControlWrapper>
          ) : (
            <>
              <ControlWrapper>
                <div className="cover-detail-view__slider">
                  {connectionIcon && <IconConnectionError big size={14} />}
                  <Slider
                    onPointerUp={handleSliderEvent}
                    value={sliderValue}
                    targetValue={targetValue}
                    onChange={onChange}
                    active={active}
                    error={!!channelOrientedError}
                    showValue={false}
                  />
                </div>
              </ControlWrapper>
              <div className="cover-detail-view__controls p-l-24 p-r-24">
                <div onClick={handleDown} className="cover-detail-view__controls-down">
                  <IconChevron withCircle direction="down" />
                </div>
                <button className="cover-detail-view__controls-stop" onClick={handleStop}>
                  {tc('status.stop')}
                </button>
                <div onClick={handleUp} className="cover-detail-view__controls-up">
                  <IconChevron withCircle direction="up" />
                </div>
              </div>
              <Favourites channel={channel} setTargetValue={setTargetValue} position={position} active={active} />
            </>
          )}
        </div>
      </LavvaDetailsWrapper>
    </>
  );
};
