import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconSortHandle, IconAdd, EmptyStateBox, Header, NavHeader, Page, GroupItem } from '../../components';
import { InstallationAccessType, UserGroup } from '../../data-access/gql-types/graphql';
import { useApi, useInstallation } from '../../hooks';
import { ROUTES } from '../../routes';

const GroupList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('groups');
  const [groups, setGroups] = useState<UserGroup[]>();
  const { groups: groupsData } = useApi();
  const { selectedInstallation } = useInstallation();
  const canAdd =
    selectedInstallation?.accessType === InstallationAccessType.Owner ||
    selectedInstallation?.accessType === InstallationAccessType.Admin;

  useEffect(() => setGroups(groupsData), [groupsData]);

  const handleSortButton = () => history.push(ROUTES.GroupSort());

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader>
            {canAdd ? (
              <button onClick={() => history.push(ROUTES.GroupCreate())}>
                <IconAdd />
              </button>
            ) : null}
            <button className="p-l-0 p-r-0" onClick={handleSortButton}>
              <IconSortHandle />
            </button>
          </NavHeader>
          <Header title={t('allGroups')} />
        </>
      }
    >
      {groups?.length ? (
        <div className="grid-list-16">
          {groups.map((group: UserGroup) => (
            <GroupItem key={group.id} id={group.id} groups={groups} />
          ))}
        </div>
      ) : (
        <EmptyStateBox
          header={t('groups')}
          content={t('descriptionEmptyList')}
          btnText={t('create')}
          linkTo={ROUTES.GroupCreate()}
        />
      )}
    </Page>
  );
};

export default GroupList;
