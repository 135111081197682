import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from '@apollo/client';
import { useSetInputType } from '../../../api/modules/generic-input/generic-input.hooks';
import {
  GenericInput,
  GenericInputBistableMode,
  GenericInputMonostableMode,
} from '../../../api/modules/generic-input/generic-input.types';
import {
  ChannelTypeInternal,
  ControlledInputResponse,
  GenericInputTypeInternal,
  OnGenericInputStateChangeSubscription,
  OnGenericInputStateChangeSubscriptionVariables,
} from '../../../data-access/gql-types/graphql';
import { ON_GENERIC_INPUT_STATE_CHANGE } from '../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext, useDevicesAndChannels } from '../../../hooks';
import { useTimeout } from '../../../hooks/timeout/use-timeout';
import { ChannelCoverInterface, ChannelLightInterface, ChannelSwitchInterface } from '../../../types';
import { toastSuccess } from '../../../utils/toast';
import { SetInputHookParams } from '../types';

export const useUpdateInputType = ({ id, channel }: SetInputHookParams) => {
  const { t } = useTranslation('channel-settings');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const { setChannelList } = useDevicesAndChannels();
  const inputTypeGeneric = useSetInputType();
  const { data } = useSubscription<
    OnGenericInputStateChangeSubscription,
    OnGenericInputStateChangeSubscriptionVariables
  >(ON_GENERIC_INPUT_STATE_CHANGE, {
    variables: { channelId: id },
    skip: !id,
  });

  useEffect(() => {
    if (data?.onGenericInputStateChange && channel) {
      turnOffBackdrop();

      if ([ChannelTypeInternal.Switch, ChannelTypeInternal.Light].includes(channel.data.type)) {
        setChannelList((prev) => {
          const temp = [...prev];
          const index = temp.findIndex((x) => x.id === channel.id);

          if (index !== -1) {
            const inputConfig = {
              genericInputType: data.onGenericInputStateChange.genericInputType,
              monostableMode: data.onGenericInputStateChange.monostableMode,
              bistableMode: data.onGenericInputStateChange.bistableMode,
            };

            let controlledInput = {
              ...(temp[index].data as ChannelSwitchInterface | ChannelLightInterface).controlledInput,
            };

            controlledInput = {
              inputId: controlledInput.inputId,
              slot: controlledInput.slot,
              inputConfig,
            };

            (temp[index].data as ChannelSwitchInterface | ChannelLightInterface).controlledInput =
              controlledInput as ControlledInputResponse;
          }

          clearTimeoutError();
          toastSuccess({ content: `${t('toast.editInputTypeSuccess')}` });
          return [...temp];
        });
      } else if (channel.data.type === ChannelTypeInternal.Blind) {
        setChannelList((prev) => {
          const temp = [...prev];
          const index = temp.findIndex((x) => x.id === channel.id);

          if (index !== -1) {
            const inputConfig = {
              genericInputType: data.onGenericInputStateChange.genericInputType,
              monostableMode: data.onGenericInputStateChange.monostableMode,
              bistableMode: data.onGenericInputStateChange.bistableMode,
            };

            const controlledInputs = ((temp[index].data as ChannelCoverInterface).controlledInputs || [])?.map((x) => ({
              ...x,
            }));

            const inputIndex = controlledInputs.findIndex(
              (x) => x.inputId === data.onGenericInputStateChange.channelId,
            );

            if (inputIndex !== -1) {
              controlledInputs[inputIndex].inputConfig = { ...inputConfig };
            }

            (temp[index].data as ChannelCoverInterface).controlledInputs =
              controlledInputs as ControlledInputResponse[];
          }

          clearTimeoutError();
          toastSuccess({ content: `${t('toast.editInputTypeSuccess')}` });
          return [...temp];
        });
      }
    }
  }, [data]);

  const editInputType = (inputType: GenericInputTypeInternal) => {
    const inputTypeForRequest = {
      [GenericInputTypeInternal.Monostable]: GenericInput.Monostable,
      [GenericInputTypeInternal.Bistable]: GenericInput.Bistable,
    };

    if (channel && id) {
      turnOnBackdrop();
      inputTypeGeneric.mutate(
        {
          deviceId: channel.deviceId,
          channelId: id,
          genericInputType: inputTypeForRequest[inputType],
          ...(inputType === GenericInputTypeInternal.Monostable
            ? { monostableMode: GenericInputMonostableMode.Normal }
            : { bistableMode: GenericInputBistableMode.Normal }),
        },
        {
          onSuccess: () => {
            turnOnBackdrop();
            setTimeoutError();
          },
          onError: () => {
            turnOffBackdrop();
          },
        },
      );
    }
  };

  return {
    editInputType,
  };
};
