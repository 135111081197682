import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LavvaDetailsWrapper from '../../../../../components/details-wrapper';
import { PICKER_COLOR_LIST } from '../../../../../const';
import { ChannelTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { useChannelsState, useLight } from '../../../../../hooks';
import { ChannelInterface, ChannelLightStateInterface, RGBType } from '../../../../../types';
import { hsbToRgb, rgbToHsb } from '../../../../../utils/helpers';
import { useRgb } from '../../hooks/use-rgb';
import ColorComponent from './color';
import CurrentlyComponent from './currently';
import RgbPicker from './picker';
// import Scenes from './scenes';
import TemperatureComponent from './temperature';

const scenesVisible = false;

interface PropsInterface {
  channel: ChannelInterface;
}

export const ChannelDetailsLightRgb: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { channelState } = useChannelsState();
  const [sliderColor, setSliderColor] = useState<string>('');
  const [activeColor, setActiveColor] = useState<string>('');
  const [rgbValue, setRgbValue] = useState<string>('');
  const state = channelState[channel.id] as ChannelLightStateInterface;
  const [brightness, setBrightness] = useState<number>(state?.brightness ?? 100);
  const [temperature, setTemperature] = useState<number>(0);
  const [hue, setHue] = useState<number>(0);
  const [saturation, setSaturation] = useState<number>(0);
  const [preventSwipeViews, setPreventSwipeViews] = useState<boolean>(false);
  const { handleControlOn, handleControlBrightness, handleControlTemperature, handleControlRgb, isOn } =
    useLight(channel);
  const { rgbType, hasBrightness } = useRgb(channel);
  const colorState = state?.color;

  useEffect(() => {
    if (channel.data.type !== ChannelTypeInternal.Light) return;

    setBrightness(state?.brightness ?? 100);

    if (rgbType === RGBType.WW) setTemperature(state?.temperature ?? 100);

    const hsb = rgbToHsb([
      colorState?.r.toString() || '',
      colorState?.g.toString() || '',
      colorState?.b.toString() || '',
    ]);
    if (colorState) {
      setRgbValue(`${colorState?.r},${colorState?.g},${colorState?.b}`);
    }
    setSliderColor(hsbToRgb([hsb[0], hsb[1] / 100, 1]).join(','));
    setActiveColor(hsbToRgb([hsb[0], hsb[1] / 100, (state?.brightness || 0) / 100]).join(','));

    if (colorState?.r === 255 && colorState?.g === 255 && colorState?.b === 255) setTemperature(45);
    if (colorState?.r === 255 && colorState?.g === 255 && colorState?.b !== 255 && rgbType !== RGBType.WW) {
      setTemperature(Math.round((38 * colorState?.b) / 255));
    }
    if (colorState?.r !== 255 && colorState?.g === 255 && colorState?.b === 255 && rgbType !== RGBType.WW) {
      setTemperature(Math.round((54 * 255) / colorState?.r));
    }
  }, [JSON.stringify(channel.data), rgbType]);

  useEffect(() => {
    const timeout = setTimeout(() => setPreventSwipeViews(false), 500);
    return () => clearTimeout(timeout);
  }, [activeColor]);

  const handleBrightness = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => {
      if (channel.data.type !== ChannelTypeInternal.Light) return;
      const brightness = Number((event.target as HTMLInputElement).value);

      setBrightness(brightness);
      handleControlBrightness(brightness);

      const hsb = rgbToHsb([
        state?.color?.r.toString() || '',
        state?.color?.g.toString() || '',
        state?.color?.b.toString() || '',
      ]);
      const h = hue ? hue : hsb[0];
      // const s = saturation ? saturation : hsb[1] / 100;
      setActiveColor(hsbToRgb([h, 1, brightness / 100]).join(','));
    },
    [hue, saturation, state],
  );

  const handleTemperature = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => {
      const value = Number((event.target as HTMLInputElement).value);
      setTemperature(value);
      let rgb: string;

      if (value <= 38) rgb = `255,255,${Math.round((value * 255) / 38)}`;
      else if (value > 38 && value < 54) rgb = '255,255,255';
      else rgb = `${Math.round((54 * 255) / value)},255,255`;

      setActiveColor(rgb);

      if (rgbType === RGBType.WW) {
        handleControlTemperature(value);
      } else {
        setRgbValue(rgb);
        handleControlRgb(rgb);
      }
    },
    [brightness, rgbType],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPreventSwipeViews(true);
      setBrightness(Number(event.target.value));
    },
    [setBrightness],
  );

  const onChangeTemperature = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setTemperature(value);
      let rgb: string;

      if (value <= 38) {
        rgb = `255,255,${Math.round((value * 255) / 38)}`;
      } else if (value > 38 && value < 54) {
        rgb = '255,255,255';
      } else {
        rgb = `${Math.round((54 * 255) / value)},255,255`;
      }

      setRgbValue(rgb);
      setActiveColor(rgb);
      setSliderColor(rgb);
    },
    [brightness],
  );

  const handleColorPicked = useCallback(
    (hue, saturation) => {
      const brightnessVal = brightness || 100;
      const rgb = hsbToRgb([hue, saturation, 1]).join(',');
      handleControlRgb(rgb);
      setBrightness(brightnessVal);
      setActiveColor(rgb);
      setSliderColor(hsbToRgb([hue, saturation, 1]).join(','));
      setHue(hue);
      setSaturation(saturation);
    },
    [brightness],
  );

  const tabs = useMemo(() => {
    return [
      ...(rgbType === RGBType.RGB || rgbType === RGBType.RGBW ? [{ label: t('rgb') }] : []),
      ...(rgbType === RGBType.RGB || rgbType === RGBType.RGBW ? [{ label: t('color') }] : []),
      ...(rgbType === RGBType.WHITE || rgbType === RGBType.WW ? [{ label: t('currently') }] : []),
      ...(rgbType !== RGBType.WHITE ? [{ label: t('white') }] : []),
      ...(scenesVisible ? [{ label: t('scenes') }] : []),
    ];
  }, [scenesVisible, channel.data, rgbType, t]);

  const components = useMemo(() => {
    const list = [
      <RgbPicker
        key={0}
        rgbValue={rgbValue}
        handleColorPicked={handleColorPicked}
        sliderColor={sliderColor}
        isOn={isOn}
        activeColor={activeColor}
        setPreventSwipeViews={setPreventSwipeViews}
        {...(hasBrightness ? { handleChange } : {})}
        {...(hasBrightness ? { brightness } : {})}
        {...(hasBrightness ? { handleBrightness } : {})}
      />,
      <ColorComponent
        key={1}
        rgbValue={rgbValue}
        handleChange={handleChange}
        brightness={brightness}
        handleBrightness={handleBrightness}
        handleControlRgb={handleControlRgb}
        colors={PICKER_COLOR_LIST}
        darkBorder
      />,
      <CurrentlyComponent
        key={2}
        isOn={isOn}
        handleControlOn={handleControlOn}
        {...(hasBrightness ? { handleChange } : {})}
        {...(hasBrightness ? { brightness } : {})}
        {...(hasBrightness ? { handleBrightness } : {})}
      />,
      <TemperatureComponent
        key={3}
        temperature={temperature}
        onChangeTemperature={onChangeTemperature}
        handleTemperature={handleTemperature}
        {...(hasBrightness ? { handleChange } : {})}
        {...(hasBrightness ? { brightness } : {})}
        {...(hasBrightness ? { handleBrightness } : {})}
      />,
    ];

    if (rgbType === RGBType.WHITE) return [list[2]];
    if (rgbType === RGBType.WW) return [list[2], list[3]];
    if (rgbType === RGBType.RGB || rgbType === RGBType.RGBW) return [list[0], list[1], list[3]];

    return [];
  }, [rgbValue, sliderColor, isOn, activeColor, brightness, temperature, PICKER_COLOR_LIST, rgbType]);

  return (
    <LavvaDetailsWrapper
      tabs={tabs}
      additionalTabs={rgbType !== RGBType.WHITE ? components.slice(1) : []}
      preventSwipeEvent={preventSwipeViews}
    >
      {components.length ? components[0] : null}
    </LavvaDetailsWrapper>
  );
};
