import { useKeycloak } from '@react-keycloak/web';
import * as storage from '../../../../utils/storage/lavva';

export const useLogoutLavva = () => {
  const { keycloak } = useKeycloak();

  const handleLogoutLavva = () => {
    const theme = storage.getItem('theme');
    localStorage.clear();
    storage.clear();
    sessionStorage.clear();
    localStorage.removeItem('wisniowski');
    storage.setItem('theme', theme);

    const local = localStorage.getItem('local-app');
    if (local) localStorage.setItem('local-app', 'true');

    keycloak.logout();
  };

  return {
    handleLogoutLavva,
  };
};
