import { cloneDeep } from 'lodash';
import { ChartDataItem } from '..';
import { dataPickerFormat } from '../../../../../components';
import { AvailableLanguage, MeasurementRange } from '../../../../../types';
import { convertDateToFormat, convertDateToString } from '../../../../../utils/helpers';
import { AvailableLongFormatEnum, AvailableShortFormatEnum } from '../types';
import { getMonday, getWeekDate } from './helpers';

const currentFormat = (longFormat: boolean, activeMeasurementRange: MeasurementRange) => {
  switch (activeMeasurementRange) {
    case MeasurementRange.DayInHours:
      return longFormat ? AvailableLongFormatEnum.HOURLY : AvailableShortFormatEnum.HOURLY;
    case MeasurementRange.WeekInDays:
      return longFormat ? AvailableLongFormatEnum.WEEKLY : AvailableShortFormatEnum.WEEKLY;
    case MeasurementRange.MonthInDays:
      return longFormat ? AvailableLongFormatEnum.MONTHLY : AvailableShortFormatEnum.MONTHLY;
    case MeasurementRange.YearInMonths:
      return longFormat ? AvailableLongFormatEnum.YEARLY : AvailableShortFormatEnum.YEARLY;
    case MeasurementRange.TenYearsInYears:
      return longFormat ? AvailableLongFormatEnum.TEN_YEARS : AvailableShortFormatEnum.TEN_YEARS;
    default:
      return '';
  }
};

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

const getPreviousMonthAndYearFromX = (lastXMonths: number) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - lastXMonths);

  const previousMonth = currentDate.getMonth() + 1;
  const previousYear = currentDate.getFullYear();

  return {
    previousMonth,
    previousYear,
  };
};

export const getDataByRange = (
  activeMeasurementRange: MeasurementRange,
  mappedChartData: ChartDataItem[],
  page: number,
  language: string,
) => {
  const chartData = cloneDeep(mappedChartData).map((x) => ({
    ...x,
    values: x.values.map((y) => ({ ...y, empty: false })),
  }));
  const currentPage = Math.abs(page);
  let prevAvailable = false;
  let result: ChartDataItem[] = [];
  let range = '';

  switch (activeMeasurementRange) {
    case MeasurementRange.DayInHours: {
      let hour = new Date().getHours();
      if (hour === 0) hour = 24;

      result = new Array(24).fill({
        values: [
          {
            type: 1,
            value: 0,
            color: '#FF4016',
            empty: true,
          },
        ],
      });

      if (currentPage === 0) {
        result =
          hour !== 24
            ? [...chartData.slice(-(hour + 1)), ...result.slice(-(24 - (hour + 1)))]
            : [...chartData.slice(-hour)];
        prevAvailable = true;
      } else {
        chartData.splice(-(hour + 1 + (currentPage - 1) * 24));
        const length = chartData.slice(-24).length;
        result =
          length < 24
            ? [...result.slice(-Math.abs(24 - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = length >= 24 && hour !== 24;
      }

      result = [...result].map((x, i) => {
        const date = new Date();
        date.setHours(i);
        date.setMinutes(0);
        date.setDate(date.getDate() - currentPage);

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d = Array.from(result).pop()?.date;

      if (d) {
        range = `${convertDateToFormat(
          new Date(d - currentPage),
          dataPickerFormat[activeMeasurementRange].format[0],
          language as AvailableLanguage,
        )}`;
      }

      break;
    }
    case MeasurementRange.WeekInDays: {
      const dayOfWeek = new Date().getDay();

      result = new Array(7).fill({
        values: [
          {
            type: 1,
            value: 0,
            color: '#FF4016',
          },
        ],
      });

      if (currentPage === 0) {
        result =
          dayOfWeek !== 7
            ? [...chartData.slice(-dayOfWeek), ...result.slice(-(7 - dayOfWeek))]
            : [...chartData.slice(-dayOfWeek)];
        prevAvailable = true;
      } else {
        chartData.splice(-(dayOfWeek + (currentPage - 1) * 7));
        const length = chartData.slice(-7).length;
        result =
          length < 7
            ? [...result.slice(-Math.abs(7 - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = length >= 7;
      }

      result = [...result].map((x, i) => {
        const monday = getMonday();
        const date = getWeekDate(new Date(new Date().setDate(monday.getDate() - currentPage * 7)), i);

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d1 = Array.from(result).shift()?.date;
      const d2 = Array.from(result).pop()?.date;

      if (d1 && d2)
        range = `${convertDateToFormat(
          new Date(d1),
          dataPickerFormat[activeMeasurementRange].format[0],
          language as AvailableLanguage,
        )} - ${convertDateToFormat(
          new Date(d2),
          dataPickerFormat[activeMeasurementRange].format[0],
          language as AvailableLanguage,
        )}`;

      break;
    }
    case MeasurementRange.MonthInDays: {
      const day = new Date().getDate();
      const daysQuantity = daysInMonth(new Date().getMonth() + 1, new Date().getFullYear());

      result = new Array(daysQuantity).fill({
        values: [
          {
            type: 1,
            value: 0,
            color: '#FF4016',
          },
        ],
      });

      if (currentPage === 0) {
        result =
          day !== daysQuantity
            ? [...chartData.slice(-day), ...result.slice(-(daysQuantity - day))]
            : [...chartData.slice(-day)];
        prevAvailable = true;
      } else {
        let sum = day;

        if (currentPage > 1) {
          for (let i = 1; i < currentPage; i++) {
            const prev = getPreviousMonthAndYearFromX(i);
            const quantity = daysInMonth(prev.previousMonth, prev.previousYear);
            sum += quantity;
          }
        }

        chartData.splice(-sum);
        const prev = getPreviousMonthAndYearFromX(currentPage);
        const quantity = daysInMonth(prev.previousMonth, prev.previousYear);
        const length = chartData.slice(-quantity).length;

        result =
          length < quantity
            ? [...result.slice(-Math.abs(quantity - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = sum + quantity < 62;
      }

      result = [...result].map((x, i) => {
        const date = new Date();
        date.setDate(i + 1);

        if (currentPage !== 0) {
          const prev = getPreviousMonthAndYearFromX(currentPage);
          date.setMonth(prev.previousMonth - 1);
          date.setFullYear(prev.previousYear);
        }

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d = Array.from(result).pop()?.date;

      if (d)
        range = convertDateToFormat(
          new Date(d),
          dataPickerFormat[activeMeasurementRange].format[0],
          language as AvailableLanguage,
        );

      break;
    }
    case MeasurementRange.YearInMonths: {
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();

      result = new Array(12).fill({
        values: [
          {
            type: 1,
            value: 0,
            color: '#FF4016',
          },
        ],
      });

      if (currentPage === 0) {
        result =
          month !== 12 ? [...chartData.slice(-month), ...result.slice(-(12 - month))] : [...chartData.slice(-month)];
        prevAvailable = month !== 12;
      } else {
        chartData.splice(-(month + (currentPage - 1) * 12));
        const length = chartData.slice(-12).length;
        result =
          length < 12
            ? [...result.slice(-Math.abs(12 - length)), ...chartData.slice(-length)]
            : [...chartData.slice(-length)];
        prevAvailable = length >= 12 && month !== 12;
      }

      result = [...result].map((x, i) => {
        const date = new Date();
        date.setMonth(i);
        date.setFullYear(year - currentPage);

        return {
          ...x,
          time: convertDateToString(date.getTime(), currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date.getTime(), currentFormat(false, activeMeasurementRange)),
          date: date.getTime(),
        };
      });

      const d = Array.from(result).pop()?.date;

      if (d) range = `${new Date(d).getFullYear()}`;

      break;
    }
    case MeasurementRange.TenYearsInYears: {
      result = [...mappedChartData].map((x, i) => {
        const date = new Date().setFullYear(new Date().getFullYear() - (9 - i));

        return {
          ...x,
          time: convertDateToString(date, currentFormat(true, activeMeasurementRange)),
          tooltipTime: convertDateToString(date, currentFormat(false, activeMeasurementRange)),
          date,
        };
      });

      const d1 = Array.from(result).shift()?.date;
      const d2 = Array.from(result).pop()?.date;

      if (d1 && d2)
        range = `${convertDateToFormat(
          new Date(d1),
          dataPickerFormat[activeMeasurementRange].format[0],
          language as AvailableLanguage,
        )} - ${convertDateToFormat(
          new Date(d2),
          dataPickerFormat[activeMeasurementRange].format[0],
          language as AvailableLanguage,
        )}`;
      break;
    }
  }

  return {
    result,
    prevAvailable: Boolean(mappedChartData.length && prevAvailable),
    nextAvailable: Boolean(mappedChartData.length && page < 0),
    range,
  };
};

export const getActiveBarParameters = () => {
  const activeTooltipCursor = document.querySelector('.recharts-rectangle.recharts-tooltip-cursor');

  if (activeTooltipCursor) {
    const x = Array.from(activeTooltipCursor.attributes).find((x) => x.name === 'x');
    const height = Array.from(activeTooltipCursor.attributes).find((x) => x.name === 'height');
    const width = Array.from(activeTooltipCursor.attributes).find((x) => x.name === 'width');

    return {
      x: x ? parseFloat(x.value) : 0,
      height: height ? parseFloat(height.value) : 0,
      width: width ? parseFloat(width.value) : 0,
    };
  }

  return { height: 0, width: 0, x: 0 };
};
