import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Header, NavHeader, Page, Tabs } from '../../components';
import { useDevicesAndChannels } from '../../hooks';
import { useRouter } from '../../hooks/router/use-router';
import { ROUTES } from '../../routes';
import { pathnameWithParameters } from '../../utils/location';
import ConfigurationDetails from '../configuration/configuration-details';
import DeviceChannels from './device-channels';
import DeviceInfo from './device-info';
import './index.scss';

const DeviceSettings: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation('channel-list');
  const { isBackAvailable, handleReplaceRedirect } = useRouter();
  const { deviceId } = useParams<{ deviceId: string; channelId: string }>();
  const { device } = useDevicesAndChannels({ deviceId });
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const tabs = [{ label: t('configuration') }, { label: t('channels') }];

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const handleTabChange = (tabIndex: number) => {
    history.replace(pathnameWithParameters(ROUTES.DeviceSettings(deviceId), search, { key: 'tab', value: tabIndex }));
    setActiveTabIndex(tabIndex);
  };

  if (!device) return null;

  return (
    <Page
      isStickyHeader
      className="driver-settings__page"
      header={
        <>
          <NavHeader {...(!isBackAvailable ? { onClick: () => handleReplaceRedirect(ROUTES.Root()) } : {})} />
          <Header>
            <div className="driver-settings__header">
              <DeviceInfo device={device} />
            </div>
          </Header>
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={handleTabChange}
            tabsType="pills"
            isDivider
          />
        </>
      }
    >
      <div className="p-b-32">
        {activeTabIndex === 0 ? (
          <ConfigurationDetails />
        ) : (
          <>
            <Header subheader title={t('channels')} />
            <DeviceChannels />
          </>
        )}
      </div>
    </Page>
  );
};

export default DeviceSettings;
