import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../../../../../../components';
import { TimeZoneKindInternal } from '../../../../../../data-access/gql-types/graphql';
import { HoursRangeFront, TariffBody } from '../../types';
import './index.scss';

interface ComponentProps {
  index: number;
  timeZoneKind: TimeZoneKindInternal;
  handleWatchForCustom: (values: TariffBody) => void;
  range: HoursRangeFront;
}

export const TariffHours: React.FC<ComponentProps> = ({ range, index, timeZoneKind, handleWatchForCustom }) => {
  const { t } = useTranslation('channel-details');
  const { getValues, setValue } = useFormContext();
  const [fromPicker, setFromPicker] = useState<boolean>(false);
  const [toPicker, setToPicker] = useState<boolean>(false);

  return (
    <div className="hours-ranges">
      <>
        <Input
          defaultValue={range.from}
          value={range.from}
          required
          readOnly
          onClickInput={() => setFromPicker(true)}
          endIcon={<IconEdit />}
          label={`${t('from')}${
            [TimeZoneKindInternal.Winter, TimeZoneKindInternal.Summer].includes(timeZoneKind)
              ? ` (${t(
                  `optimizer.configuration.tariff.${
                    timeZoneKind === TimeZoneKindInternal.Summer ? 'summer' : 'winter'
                  }Time`,
                )})`
              : ''
          }`}
        />
        <DialogTimePicker
          header={t('from')}
          open={fromPicker}
          setOpen={setFromPicker}
          onSave={(value) => {
            setValue(`hoursRanges[${index}.from]`, value);
            handleWatchForCustom(getValues() as TariffBody);
          }}
          time={range.from}
          maxMinutes={0}
        />
      </>
      <>
        <Input
          defaultValue={range.to}
          value={range.to}
          required
          readOnly
          onClickInput={() => setToPicker(true)}
          endIcon={<IconEdit />}
          label={t('to')}
        />
        <DialogTimePicker
          header={t('to')}
          open={toPicker}
          setOpen={setToPicker}
          onSave={(value) => {
            setValue(`hoursRanges[${index}.to]`, value);
            handleWatchForCustom(getValues() as TariffBody);
          }}
          time={range.to}
          maxMinutes={0}
        />
      </>
    </div>
  );
};
