import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { IconReferenceLine } from '../../../../../../components';
import { useCurrentLanguage } from '../../../../../../hooks';
import { MeasurementRange } from '../../../../../../types';
import { SelectedPhase } from '../../../../measurement/types';
import { ChartDataItem } from '../../index';
import { getActiveBarParameters } from '../../utils';
import { EmptyState } from './empty-state';
import './index.scss';
import { LoadingState } from './loading-state';
import { CustomOverTooltip, Tooltip as CustomTooltip } from './tooltip';

interface PropsInterface {
  data: ChartDataItem[];
  mappedChartData: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
  selectedPhases: SelectedPhase[] | undefined;
  isLoading: boolean;
}

const strokeLineColor = '#9A9BA2';

export const AnalysisBarChart: React.FC<PropsInterface> = ({
  data,
  mappedChartData,
  activeMeasurementRange,
  selectedPhases,
  isLoading,
}) => {
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const [payload, setPayload] = useState<ChartDataItem>();
  const [barPayload, setBarPayload] = useState<{ x: number; height: number; width: number }>({
    x: 0,
    height: 0,
    width: 0,
  });
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { currentLanguage } = useCurrentLanguage();

  const maxChartDataValue = useMemo(() => {
    const allValues = data.reduce<number[]>(
      (all, chartDataItem) => [...all, ...chartDataItem.values.map((valueObject) => valueObject.value)],
      [],
    );
    return Math.max(...allValues, 0);
  }, [data]);

  useEffect(() => {
    setBarPayload(getActiveBarParameters());
  }, [showTooltip, payload?.tooltipTime]);

  useEffect(() => {
    if (selectedPhases === undefined || data.length === 0) return;
    const keys = selectedPhases.map((phase) => phase.value);

    if (selectedPhases.length === 0) return;

    const chartData = data.map((el) => {
      const values = el.values.filter((value) => keys.includes(value.type));
      const dateKeyValue = values.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.value }), {});

      return {
        tooltipTime: el.time,
        time: el.tooltipTime,
        ...dateKeyValue,
        values,
      };
    });

    setChartData(chartData);
  }, [data, selectedPhases, maxChartDataValue, activeMeasurementRange]);

  const referenceLineLeftPosition = useMemo(() => barPayload.x + barPayload.width * 0.5 - 8.5, [barPayload]);

  const label = useMemo(() => payload?.tooltipTime || '', [activeMeasurementRange, currentLanguage, payload]);

  const xAxisInterval = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return 3;
      case MeasurementRange.MonthInDays:
        return 3;
      case MeasurementRange.WeekInDays:
      case MeasurementRange.YearInMonths:
        return 'preserveStartEnd';
      case MeasurementRange.TenYearsInYears:
        return 'preserveStartEnd';
      default:
        return 1;
    }
  }, [activeMeasurementRange]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (mappedChartData.length === 0) {
    return <EmptyState missingData />;
  }

  if (!chartData.length) {
    return <EmptyState />;
  }

  return (
    <>
      <CustomTooltip active={showTooltip} payload={payload} label={label} />
      <div className="chart">
        <div
          className={classNames('chart__reference-line', { 'chart__reference-line--active': showTooltip })}
          style={{ left: referenceLineLeftPosition }}
        >
          <IconReferenceLine height={barPayload.height} />
        </div>
        <ResponsiveContainer width="100%" height={176}>
          <BarChart data={chartData} margin={{ top: 8, right: 0, left: -20, bottom: 0 }}>
            <CartesianGrid vertical={false} stroke={strokeLineColor} strokeOpacity={0.2} />
            <Tooltip content={<CustomOverTooltip setPayload={setPayload} setShowTooltip={setShowTooltip} />} />
            <XAxis
              tickMargin={8}
              dataKey="time"
              axisLine={false}
              interval={xAxisInterval}
              tickLine={{ stroke: 'transparent' }}
            />
            <YAxis
              axisLine={{ strokeWidth: '0' }}
              tickCount={6}
              tickMargin={6}
              tickLine={{ stroke: strokeLineColor, strokeWidth: '0.5px', strokeOpacity: '0.2' }}
            />
            {chartData.length && chartData[0].values.length
              ? chartData[0].values.map((item, index) => (
                  <Bar
                    radius={index === chartData[0].values.length - 1 ? [2, 2, 0, 0] : undefined}
                    key={item.type}
                    dataKey={item.type}
                    stackId="energy"
                    fill={item.color}
                  />
                ))
              : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
