import React, { ReactNode } from 'react';
import { SnackbarUtilsConfigurator } from './components/snackbar';
import { BackdropContextProvider } from './hooks';
import ApiProvider from './hooks/api/provider';
import ChannelsStateProvider from './hooks/channels-state/provider';
import CustomSnackbarProvider from './hooks/custom-snackbar/provider';
import DevicesAndChannelsProvider from './hooks/devices-and-channels/provider';
import { ChannelSubscriptions } from './hooks/devices-and-channels/subscriptions';
import InstallationProvider from './hooks/installation/provider';
import NativeFunctionsProvider from './hooks/native/native-functions';
import NativeProvider from './hooks/native/provider';
import { LavvaCreateActionContextProvider } from './modules/action-create/context';
import { ExalusContextProvider } from './modules/exalus/context';
import { ExalusDevicesContextProvider } from './modules/exalus/context/devices';
import { ExalusServicesContextProvider } from './modules/exalus/context/services';
import { ExalusStorageContextProvider } from './modules/exalus/context/storage';
import { ExalusCreateActionContextProvider } from './modules/exalus/pages/action-create/context';
import { CreateInstallationProvider } from './modules/installation-create/context';
import { ShareInstallationProvider } from './modules/installation-share/context';
import './styles/main.scss';
import ApolloProviderWrapper from './utils/graphql/apollo.provider';
import GraphqlProvider from './utils/graphql/graphql.context';
import { isLocalApp } from './utils/helpers/local-app';

interface ComponentProps {
  children: ReactNode;
}

const ProvidersLocal: React.FC<ComponentProps> = ({ children }) => (
  <CustomSnackbarProvider>
    <SnackbarUtilsConfigurator />
    <ApolloProviderWrapper>
      <BackdropContextProvider>
        <ExalusServicesContextProvider>
          <ExalusStorageContextProvider>
            <ExalusDevicesContextProvider>
              <ExalusContextProvider>
                <InstallationProvider>
                  <NativeFunctionsProvider>
                    <NativeProvider>
                      <ApiProvider>
                        <CreateInstallationProvider>
                          <ExalusCreateActionContextProvider>
                            <LavvaCreateActionContextProvider>{children}</LavvaCreateActionContextProvider>
                          </ExalusCreateActionContextProvider>
                        </CreateInstallationProvider>
                      </ApiProvider>
                    </NativeProvider>
                  </NativeFunctionsProvider>
                </InstallationProvider>
              </ExalusContextProvider>
            </ExalusDevicesContextProvider>
          </ExalusStorageContextProvider>
        </ExalusServicesContextProvider>
      </BackdropContextProvider>
    </ApolloProviderWrapper>
  </CustomSnackbarProvider>
);

const Providers: React.FC<ComponentProps> = ({ children }) => (
  <CustomSnackbarProvider>
    <SnackbarUtilsConfigurator />
    <GraphqlProvider>
      <ApolloProviderWrapper>
        <BackdropContextProvider>
          <DevicesAndChannelsProvider>
            <ChannelsStateProvider>
              <ExalusServicesContextProvider>
                <ExalusStorageContextProvider>
                  <ExalusDevicesContextProvider>
                    <ExalusContextProvider>
                      <InstallationProvider>
                        <NativeFunctionsProvider>
                          <NativeProvider>
                            <ApiProvider>
                              <CreateInstallationProvider>
                                <ShareInstallationProvider>
                                  <ChannelSubscriptions />
                                  <ExalusCreateActionContextProvider>
                                    <LavvaCreateActionContextProvider>{children}</LavvaCreateActionContextProvider>
                                  </ExalusCreateActionContextProvider>
                                </ShareInstallationProvider>
                              </CreateInstallationProvider>
                            </ApiProvider>
                          </NativeProvider>
                        </NativeFunctionsProvider>
                      </InstallationProvider>
                    </ExalusContextProvider>
                  </ExalusDevicesContextProvider>
                </ExalusStorageContextProvider>
              </ExalusServicesContextProvider>
            </ChannelsStateProvider>
          </DevicesAndChannelsProvider>
        </BackdropContextProvider>
      </ApolloProviderWrapper>
    </GraphqlProvider>
  </CustomSnackbarProvider>
);

const GlobalProviders: React.FC<ComponentProps> = ({ children }) => {
  return !isLocalApp ? <Providers>{children}</Providers> : <ProvidersLocal>{children}</ProvidersLocal>;
};

export default GlobalProviders;
