import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNative } from '../../../hooks';
import { useErrors } from '../../../hooks/use-errors';
import { toastSuccess } from '../../../utils/toast';
import { useRestClient } from '../../clients/rest-client';
import { useUpdateChannel } from '../channels';
import { DeviceResponse } from '../device/device.types';
import { BlindCalibrateBody, BlindSetDirectionBody, BlindSetPositionBody, BlindSwapOutBody } from './blind.types';

export const useBlindSetPosition = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  BlindSetPositionBody
> => {
  const restClient = useRestClient();
  const { setChannelAsDisconnected } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, BlindSetPositionBody>(
    ['blind-setPosition'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/blind/setPosition', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useBlindSetDirection = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  BlindSetDirectionBody
> => {
  const restClient = useRestClient();
  const { setChannelAsDisconnected, resetCalibrateState } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, BlindSetDirectionBody>(
    ['blind-setDirection'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/blind/setDirection', body);
    },
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
          resetCalibrateState(body.channelId, data);
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useBlindCalibrate = (): UseMutationResult<
  AxiosResponse<DeviceResponse>,
  AxiosError,
  BlindCalibrateBody
> => {
  const { t: td } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { setChannelAsDisconnected } = useUpdateChannel();
  const { handleRestErrors, handleLavvaResolve } = useErrors();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, BlindCalibrateBody>(
    ['blind-calibrate'],
    async (body) => await restClient.post<DeviceResponse>('/blind/calibrate', body),
    {
      onSuccess: ({ data }, body) => {
        handleLavvaResolve(data.publishStatus, () => {
          setChannelAsDisconnected(body.channelId, data);
          toastSuccess({ content: td('toast.calibrationStartSent') });
        });
      },
      onError: (error: AxiosError) => {
        handleRestErrors(error);
      },
    },
  );
};

export const useBlindSwapOut = (): UseMutationResult<AxiosResponse<DeviceResponse>, AxiosError, BlindSwapOutBody> => {
  const { t } = useTranslation('channel-settings');
  const restClient = useRestClient();
  const { handleRestErrors } = useErrors();
  const { fireVibration } = useNative();

  return useMutation<AxiosResponse<DeviceResponse>, AxiosError, BlindSwapOutBody>(
    ['device-swapIn'],
    async (body) => {
      fireVibration();
      return await restClient.post<DeviceResponse>('/blind/swapOut', body);
    },
    {
      onError: (error: AxiosError) => {
        handleRestErrors(error, t('toast.noPermissions'));
      },
    },
  );
};
