import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  CorrectionSteps,
  CorrectionType,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { BlindConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigService';
import { ISsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/ISsrConfigService';
import { SsrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/SsrConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import AutoCalibration from '../common/auto-calibration/button';
import CorrectionStepsForm from './correction-steps';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const SsrConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [params, setParams] = useState<CorrectionSteps | undefined>(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetBlindParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: string, correctionType: CorrectionType) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISsrConfigService>(
      SsrConfigService.ServiceName,
    );

    switch (panel) {
      case 'CorrectionSteps': {
        if (channel) {
          const data: CorrectionSteps | ResponseResult<BlindConfigurationErrorCode> =
            await configService.GetCorrectionStepAsync(device, channel.Number, correctionType);

          handleGetBlindParamsResponse<CorrectionSteps>(data, () => {
            setParams(data as CorrectionSteps);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = useCallback(
    (panel: string, correctionType: CorrectionType) => getDefaultParams(panel, correctionType),
    [],
  );

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'CorrectionSteps' && channel && (
            <CorrectionStepsForm device={device} channel={channel} params={params as CorrectionSteps} />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <AutoCalibration channel={channel} />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SsrParams.LooseringParams')}
                onClick={() => handleOpen('CorrectionSteps', 'UPPER')}
              />
              <ArrowButton
                className="m-b-24 m-b-24"
                title={t('exalus.params.SsrParams.PressureParams')}
                onClick={() => handleOpen('CorrectionSteps', 'LOWER')}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SsrConfigForm;
