import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@apollo/client/link/error';
import { PublishStatus } from '../../data-access/gql-types/graphql';
import { PublishStatusRest } from '../../types';
import { toastError } from '../../utils/toast';
import { useBackdropContext } from '../use-backdrop';
import { HandleErrorsHook } from './types';

export const useErrors = (): HandleErrorsHook => {
  const { t } = useTranslation('common');
  const { t: tv } = useTranslation('backend-validation');
  const { turnOffBackdrop } = useBackdropContext();

  const handleGraphQLError = ({ graphQLErrors, networkError, operation }: ErrorResponse) => {
    if (operation.operationName === 'AddMultipleInstallationsAfterMigration') return;

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const code: string = error.extensions?.code as string;

        switch (code) {
          case 'AUTH_NOT_AUTHENTICATED': {
            showError(tv('AUTH_NOT_AUTHENTICATED'));
            break;
          }
          case 'installation_not_attached': {
            console.log('INSTALLATION NOT ATTACHED');
            break;
          }
          default: {
            const errorCode: string = error.extensions?.code as string;

            if (errorCode) {
              if (errorCode.includes('MAXIMUM_LENGTH')) {
                showError(`${tv('MAXIMUM_LENGTH').replace('#', errorCode.split('_')[2])}`);
              } else if (errorCode.includes('NOT_GREATER_THAN')) {
                showError(`${tv('NOT_GREATER_THAN').replace('#', errorCode.split('_')[3])}`);
              } else {
                showError(tv(errorCode) || error.message);
              }
            } else {
              showError(error.message);
            }

            break;
          }
        }
      });
    }

    if (networkError) {
      // showError('Network error');
    }
  };

  const handleRestErrors = (error: AxiosError, errorMessage?: string) => {
    turnOffBackdrop();
    if (error.code === 'ERR_CANCELED') return;

    const status = error.response?.status;

    if (status) {
      switch (status) {
        case 401: {
          window.location.reload();
          break;
        }
        case 429: {
          showError(t('errors.tooManyRequests'));
          break;
        }
        case 500: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        case 501: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        case 502: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        case 503: {
          showError(t('errors.somethingWentWrong'));
          break;
        }
        default: {
          if (errorMessage) toastError({ content: errorMessage });
          else if (typeof error.response?.data === 'string') {
            showError(error.response?.data || 'Error');
          } else {
            const errorData = error.response?.data as any;
            if (errorData) {
              console.log(errorData || 'Error');

              if ('ErrorCode' in errorData) {
                showError(tv(errorData.ErrorCode));
              }
            }
          }
          break;
        }
      }
    } else showError(t('errors.somethingWentWrong'));
  };

  const showError = (message: string) => {
    toastError({ content: message });
  };

  const handleLavvaResolve = (status: PublishStatusRest | PublishStatus | undefined, onSuccess?: () => void) => {
    turnOffBackdrop();

    switch (status) {
      case PublishStatusRest.Ok: {
        if (onSuccess) onSuccess();
        break;
      }
      case PublishStatusRest.DeviceOrChannelNotFound: {
        toastError({ content: t(`publishStatus.${PublishStatusRest[PublishStatusRest.DeviceOrChannelNotFound]}`) });
        break;
      }
      case PublishStatusRest.DeviceDisconnected: {
        toastError({ content: t(`publishStatus.${PublishStatusRest[PublishStatusRest.DeviceDisconnected]}`) });
        break;
      }
      case PublishStatusRest.ContractNotSupported: {
        toastError({ content: t(`publishStatus.${PublishStatusRest[PublishStatusRest.ContractNotSupported]}`) });
        break;
      }
      default: {
        break;
      }
    }
  };

  return { handleGraphQLError, handleRestErrors, handleLavvaResolve };
};
