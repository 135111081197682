import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useGraphqlContext } from '../../utils/graphql/graphql.context';
import { toastError } from '../../utils/toast';
import { useBackdropContext } from '../use-backdrop';

export const useSetProfileImage = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { LAVVA_MANAGER } = useGraphqlContext();

  const setProfileImage = (file: File, onSuccess: () => void) => {
    turnOnBackdrop();
    const formData = new FormData();
    formData.append('file', file);
    fetch(`${LAVVA_MANAGER}Upload/Profile`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
      body: formData,
    })
      .then((data) => {
        if (data.status === 200) onSuccess();
        else if (data.status === 400 || data.status === 404 || data.status === 500) {
          toastError({ content: tc('errors.somethingWentWrong') });
        } else if (data.status === 403) {
          toastError({ content: t('avatar.toastSizeError') });
        }
      })
      .catch(() => {
        toastError({ content: tc('errors.somethingWentWrong') });
      })
      .finally(() => {
        turnOffBackdrop();
      });
  };

  return {
    setProfileImage,
  };
};
