import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { SunPosition } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import {
  IconActions,
  IconSunDown,
  IconSunUp,
  Input,
  InputSelect,
  SubmitButton,
} from '../../../../../../../../components';
import { AdvanceDelayDialog } from '../../../../../../../trigger-form/condition-types/time-condition/components';
import { useExalusSunTimes } from '../../../../../../hooks/scenarios.hooks';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';
import { useActionParameterOptions } from '../../../../utils/use-action-parameter-options';
import SunChart from './chart';

export const AstronomicalSource: React.FC = () => {
  const { t } = useTranslation('action');
  const [sunPosition, setSunPosition] = useState<SunPosition>(SunPosition.SunRise);
  const [showDelayDialog, setShowDelayDialog] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const { sunPositions } = useActionParameterOptions();
  const { builder } = useExalusCreateActionContext();
  const { data } = useExalusSunTimes();
  const {
    seq,
    triggerMethod,
    atMeetDisabled,
    conditionType,
    setConditionType,
    setTriggerMethod,
    finalizeSequenceSubmit,
  } = useConditionEdit();

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsAstronomicalClockWithOffset;

    if (argument) {
      setSunPosition(argument.Position);
      setOffset(argument.Offset / 60);
    }
  }, [seq]);

  const onChangeSunPosition = (sunPosition: SunPosition) => setSunPosition(sunPosition);

  const handleSubmit = () => {
    const argument = builder?.ArgumentAsAstronomicalClockWithOffset(
      { Position: sunPosition, Offset: offset * 60 },
      triggerMethod === TriggerMethodType.WhenChangeOn,
      conditionType,
    );

    finalizeSequenceSubmit(argument, -3);
  };

  const nextFireTime = useMemo(() => {
    if (data) {
      return sunPosition === SunPosition.SunRise
        ? data.sunRiseTime.getTime() + offset * 60000
        : data.sunSetTime.getTime() + offset * 60000;
    }
  }, [data, offset, sunPosition]);

  return (
    <>
      <div className="grid-list-24">
        {!atMeetDisabled ? (
          <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
        ) : null}
        {triggerMethod === TriggerMethodType.IfIsIt && (
          <ConditionTypeSelect
            conditionType={conditionType}
            setConditionType={setConditionType}
            astronomical
            allow={[
              ConditionsTypes.Equal,
              ConditionsTypes.NotEqueal,
              ConditionsTypes.BiggerThan,
              ConditionsTypes.BiggerThanOrEqual,
              ConditionsTypes.SmallerThan,
              ConditionsTypes.SmallerThanOrEqual,
            ]}
          />
        )}
        <InputSelect
          options={sunPositions}
          value={sunPosition}
          onChange={onChangeSunPosition}
          label={t('action.create.conditions.sources.schedule.sunPosition')}
        />
        <Input
          defaultValue={offset.toString()}
          value={offset.toString()}
          required
          readOnly
          label={t('action.create.conditions.sources.schedule.minOffset')}
          onClickInput={() => setShowDelayDialog(true)}
        />
      </div>
      {data && (
        <div className="row-container">
          <div className="row-container--icon">
            <IconActions />
          </div>
          <p className="secondary-text">
            {t('action.create.conditions.sources.schedule.fireAt', {
              time: nextFireTime ? format(nextFireTime, 'dd.MM, HH:mm') : '-',
            })}
          </p>
        </div>
      )}
      {data && (
        <div className="row-container flex-end">
          <div className="row-container  margin-right">
            <div className="row-container--icon">
              <IconSunUp />
            </div>
            <p className="secondary-text small">{format(data?.sunRiseTime, 'dd.MM, HH:mm')}</p>
          </div>
          <div className="row-container">
            <div className="row-container--icon">
              <IconSunDown />
            </div>
            <p className="secondary-text small">{format(data?.sunSetTime, 'dd.MM, HH:mm')}</p>
          </div>
        </div>
      )}
      <SunChart time={nextFireTime} />
      <SubmitButton onClick={handleSubmit} />
      <AdvanceDelayDialog
        open={showDelayDialog}
        setOpen={setShowDelayDialog}
        title={t('action.create.conditions.sources.schedule.minOffset')}
        onSave={(value) => setOffset(value)}
        time={offset}
        min={-60}
        max={60}
        loop={false}
      />
    </>
  );
};
