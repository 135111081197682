import React from 'react';
import classNames from 'classnames';
import './index.scss';

interface ComponentProps {
  titlePrimary: string;
  titleSecondary: string;
  handleClickPrimary: () => void;
  handleClickSecondary?: () => void;
  disabledPrimary?: boolean;
  disabledSecondary?: boolean;
  reverse?: boolean;
  separator?: boolean;
}

const FixedTwoButtons: React.FC<ComponentProps> = ({
  titlePrimary,
  titleSecondary,
  disabledPrimary,
  disabledSecondary,
  reverse,
  separator = true,
  handleClickPrimary,
  handleClickSecondary,
}) => (
  <div className={classNames('fixed-two-buttons', { reverse: reverse, separator: separator })} id="fixed-two-buttons">
    <button
      className={classNames('button', { 'one-button': !handleClickSecondary })}
      disabled={disabledPrimary}
      onClick={handleClickPrimary}
    >
      {titlePrimary}
    </button>
    {handleClickSecondary && (
      <button
        disabled={disabledSecondary}
        className="button button--secondary no-disabled-background"
        onClick={handleClickSecondary}
      >
        {titleSecondary}
      </button>
    )}
  </div>
);

export default FixedTwoButtons;
