import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService, GetParamsErrorCode } from 'lavva.exalushome.extalife';
import {
  FirmwareService,
  FirmwareVersion,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/FirmwareService';
import { IFirmwareService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/IFirmwareService';
import { IRcm21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/IRcm21ConfigService';
import {
  MultisensorChannelOffsetParams,
  MultisensorDetectionThresholdParams,
  MultisensorIntervalParams,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigParams';
import {
  Rcm21ConfigService,
  VersionedOptions,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/Rcm21ConfigService';
import { Rcm21VersionedService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MultiSensor/VersionedOptions/^1.6.0.34/Rcm21VersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { SensorType } from '../../../../enums';
import { getSensorType } from '../../../../utils';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { getRcm21VersionService, parseFirmware, sensorTypeToRcm21 } from '../../../device-details/utils';
import HysteresisForm from './hysteresis';
import IntervalForm from './interval';
import OffsetForm from './offset';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const Rcm21ConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [versionService, setVersionService] = useState<string>('');
  const [currentFirmware, setCurrentFirmware] = useState<string>('');
  const [params, setParams] = useState<
    MultisensorIntervalParams | MultisensorDetectionThresholdParams | MultisensorChannelOffsetParams | undefined
  >(undefined);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  useEffect(() => {
    const getFirmwareVersion = async () => {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const firmwareService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IFirmwareService>(
        FirmwareService.ServiceName,
      );
      const currentFirmware: FirmwareVersion | ResponseResult<GetParamsErrorCode> =
        await firmwareService.GetFirmwareVersionAsync(device);

      handleGetParamsResponse<FirmwareVersion>(currentFirmware, () => {
        setCurrentFirmware(parseFirmware(currentFirmware as FirmwareVersion));
      });

      turnOffBackdrop();
    };

    getFirmwareVersion();
  }, []);

  const sensorType = useMemo(() => {
    if (channel) return getSensorType(channel);
  }, [channel]);

  const getDefaultParams = async (panel: string) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcm21ConfigService>(
      Rcm21ConfigService.ServiceName,
    );

    const versionedOptions: VersionedOptions = await configService.VersionedConfigurationOptions();

    const serviceVersion = await getRcm21VersionService(currentFirmware, versionedOptions);
    setVersionService(serviceVersion);

    switch (panel) {
      case 'MultisensorIntervalParameters': {
        if (channel) {
          const interval: MultisensorIntervalParams | ResponseResult<GetParamsErrorCode> =
            await configService.GetFramesIntervalAsync(device, channel.Number);

          handleGetParamsResponse<MultisensorIntervalParams>(interval, () => {
            setParams(interval as MultisensorIntervalParams);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'HysteresisParameters': {
        if (channel && sensorType) {
          const detectionThreshold: MultisensorDetectionThresholdParams | ResponseResult<GetParamsErrorCode> =
            await configService.GetDetectionThresholdDataAsync(device, sensorTypeToRcm21[sensorType]);

          handleGetParamsResponse<MultisensorDetectionThresholdParams>(detectionThreshold, () => {
            setParams(detectionThreshold as MultisensorDetectionThresholdParams);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'OffsetParameters': {
        if (channel && sensorType) {
          const offset: MultisensorChannelOffsetParams | ResponseResult<GetParamsErrorCode> = await (
            versionedOptions[serviceVersion] as Rcm21VersionedService
          ).GetChannelOffsetAsync(device, sensorTypeToRcm21[sensorType]);

          handleGetParamsResponse<MultisensorChannelOffsetParams>(offset, () => {
            setParams(offset as MultisensorChannelOffsetParams);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = useCallback(() => {
    setOpen(false);
    setConfigType('');
  }, []);

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'MultisensorIntervalParameters' && channel && (
            <IntervalForm device={device} interval={params as MultisensorIntervalParams} channel={channel} />
          )}
          {configType === 'OffsetParameters' && (
            <OffsetForm
              device={device}
              versionService={versionService}
              offset={params as MultisensorChannelOffsetParams}
            />
          )}
          {configType === 'HysteresisParameters' && (
            <HysteresisForm
              device={device}
              versionService={versionService}
              hysteresis={params as MultisensorDetectionThresholdParams}
            />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.Rcm21Params.HysteresisParameters')}
                onClick={() => handleOpen('HysteresisParameters')}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rcm21Params.MultisensorIntervalParameters')}
                onClick={() => handleOpen('MultisensorIntervalParameters')}
              />
              {currentFirmware >= '^1.6.0.34' && sensorType === SensorType.Temperature && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.Rcm21Params.OffsetParameters')}
                  onClick={() => handleOpen('OffsetParameters')}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rcm21ConfigForm;
