import React from 'react';
import { ControlWrapper, OnOffControl } from '../../../components';
import { LightToggleValueInternal } from '../../../data-access/gql-types/graphql';
import { useActionControl } from '../../../hooks';
import { ChannelInterface } from '../../../types';

interface PropsInterface {
  channels: ChannelInterface[];
}
const ActionSwitch: React.FC<PropsInterface> = ({ channels }) => {
  const { handleControlsOn } = useActionControl(channels);

  return (
    <ControlWrapper>
      <OnOffControl
        actionOn={() => handleControlsOn(LightToggleValueInternal.On)}
        actionOff={() => handleControlsOn(LightToggleValueInternal.Off)}
      />
    </ControlWrapper>
  );
};

export default ActionSwitch;
