import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  GatePositionState,
  GatePositionTypeEnum,
  GatewayPositionState,
  GatewayPositionTypeEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { InputSelect, SubmitButton } from '../../../../../../../../components';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';
import { useActionParameterOptions } from '../../../../utils/use-action-parameter-options';

export const GateParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { channels } = useExalusCreateActionContext();
  const { gateOptions, gatewayOptions } = useActionParameterOptions();
  const [gateOption, setGateOption] = useState<GatePositionTypeEnum>(GatePositionTypeEnum.Close);
  const [gatewayOption, setGatewayOption] = useState<GatewayPositionTypeEnum>(GatewayPositionTypeEnum.Close);
  const {
    triggerMethod,
    atMeetDisabled,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit();

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDeviceState;

    if (argument && channels.length && onlyParametersEdit) {
      const gate = channels[0]?.States.find((state) => state.TypeAsEnum === DeviceResponseType.GatePosition);

      if (gate) {
        const state = argument.GetCheckDeviceState<GatePositionState>();
        setGateOption(state.PositionType);
      } else {
        const state = argument.GetCheckDeviceState<GatewayPositionState>();
        setGatewayOption(state.PositionType);
      }
    }
  }, [seq, onlyParametersEdit]);

  const onChangeGate = (option: GatePositionTypeEnum) => setGateOption(option);

  const onChangeGateway = (option: GatewayPositionTypeEnum) => setGatewayOption(option);

  const gatePosition = useMemo(() => {
    if (channels.length) {
      return channels[0]?.States.find((state) => state.TypeAsEnum === DeviceResponseType.GatePosition);
    }
  }, [channels]);

  const handleSubmit = () => {
    if (channels.length) {
      const deviceArgument = createDeviceArgumentWithComparison();
      deviceArgument.Type = gatePosition ? DeviceResponseType.GatePosition : DeviceResponseType.GatewayPosition;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;

      if (gatePosition) {
        const sensorGateState = new GatePositionState();
        sensorGateState.Channel = channels[0].Number;
        sensorGateState.PositionType = gateOption;
        deviceArgument.SetCheckDeviceState<GatePositionState>(sensorGateState);
      } else {
        const sensorGatewayState = new GatewayPositionState();
        sensorGatewayState.Channel = channels[0].Number;
        sensorGatewayState.PositionType = gatewayOption;
        deviceArgument.SetCheckDeviceState<GatewayPositionState>(sensorGatewayState);
      }

      saveConditionParams(deviceArgument);
    }
  };

  return (
    <>
      {!atMeetDisabled ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && !atMeetDisabled && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[ConditionsTypes.Equal, ConditionsTypes.NotEqueal]}
      />
      {gatePosition ? (
        <InputSelect
          options={gateOptions}
          value={gateOption}
          onChange={onChangeGate}
          label={t('action.create.conditions.sources.device.gate')}
        />
      ) : (
        <InputSelect
          options={gatewayOptions}
          value={gatewayOption}
          onChange={onChangeGateway}
          label={t('action.create.conditions.sources.device.gate')}
        />
      )}
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
