import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { MeasuredTemperatureDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { TemperatureControlHook } from '../types';

export const useTemperatureControl = (channel?: IDeviceChannel, disabled?: boolean): TemperatureControlHook => {
  if (disabled) return { temperature: null, time: '' };

  const temperatureData = useMemo(() => {
    const temperatureState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.MeasuredTemperature,
    ) as MeasuredTemperatureDeviceState;

    if (temperatureState) {
      return temperatureState.Data;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (temperatureData?.Time) return format(Date.parse(temperatureData.Time), 'dd.MM, HH:mm');
    return '';
  }, [temperatureData]);

  return {
    temperature: temperatureData?.Temperature ?? null,
    time,
  };
};
