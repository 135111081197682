import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconAutomatic } from '../../../../components';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useCover, useChannelsState } from '../../../../hooks';
import { useGate } from '../../../../hooks/use-gate';
import { ChannelCoverStateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../../types';
import { useConfiguration } from '../../../configuration/hooks/use-configuration';

interface PropsInterface {
  channel: ChannelInterface;
}

export const Calibration: React.FC<PropsInterface> = <
  T extends ChannelCoverStateInterface | ChannelGateStateInterface,
>({
  channel,
}) => {
  const { t } = useTranslation('channel-settings');
  const { t: tcd } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { autoCalibrate, calibrateLoading } = useConfiguration({ channel });
  const { channelState } = useChannelsState();
  const { handleAction: handleBlindAction } = useCover([channel]);
  const { handleAction: handleGateAction } = useGate([channel]);

  const handleAction = () => {
    if (channel.data.type === ChannelTypeInternal.Blind) {
      handleBlindAction('stop');
    } else if (channel.data.type === ChannelTypeInternal.Gate) {
      handleGateAction('stop');
    }
  };

  const calibrationInProgress = useMemo(() => {
    return (channelState[channel.id] as T).calibrateState?.progress;
  }, [(channelState[channel.id] as T).calibrateState?.progress]);

  if (calibrationInProgress) {
    return (
      <>
        <button onClick={handleAction} className="button button--secondary button--active-on-click">
          {tc('status.stop')}
        </button>
        <p>{`${tcd('calibrationProgress')} ${(channelState[channel.id] as T).calibrateState?.progress}%`}</p>
      </>
    );
  }

  return (
    <div className="max-width-desktop">
      <button onClick={autoCalibrate} className="button button--secondary button--active-on-click">
        <IconAutomatic />
        {!calibrateLoading ? t('automatic') : tc('buttons.loading')}
      </button>
    </div>
  );
};
