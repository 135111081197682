import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DependantChannelResponse } from '../../data-access/gql-types/graphql';
import { useChannelsState } from '../../hooks';
import DependantChannel from '../../modules/channel-details/gate/components/dependant-channel';
import { ChannelGateStateInterface, ChannelInterface } from '../../types';
import { IconCirclePadlockClose, IconCirclePadlockOpen, IconVent } from '../icons';
import './index.scss';

interface CommonProps {
  open: () => void;
  close: () => void;
  stop: () => void;
  ventilation: () => void;
  ventilationVisible: boolean;
  directionVisible: boolean;
}

interface ChannelDetailsProps extends CommonProps {
  kind: 'channel';
  channel: ChannelInterface;
  dependantChannels: DependantChannelResponse[];
}

interface ActionDetailsProps extends CommonProps {
  kind: 'action';
}

type ComponentProps = ChannelDetailsProps | ActionDetailsProps;

export const GateControls: React.FC<ComponentProps> = (props) => {
  const { open, close, stop, ventilation, ventilationVisible, directionVisible, kind } = props;
  const { t } = useTranslation('channel-details');
  const { channelState } = useChannelsState();
  const direction = kind === 'channel' ? (channelState[props.channel.id] as ChannelGateStateInterface).direction : '';

  return (
    <div className="gate-controls m-b-24">
      {direction && (
        <div className="direction m-b-16">
          <p>{t(`direction.${direction}`)}</p>
        </div>
      )}
      <div className="gate-controls__background">
        <div className="gate-controls__content">
          {directionVisible && (
            <>
              <div className="on-off-wrapper m-b-16">
                <button onClick={open} className={classNames('on-off-wrapper__btn on-off-wrapper__btn--on')}>
                  <IconCirclePadlockOpen big />
                </button>
                <div className="on-off-wrapper__divider" />
                <button onClick={close} className="on-off-wrapper__btn on-off-wrapper__btn--off">
                  <IconCirclePadlockClose big />
                </button>
              </div>
              <button onClick={stop} className="btn-custom shadow">
                STOP
              </button>
            </>
          )}

          {kind === 'channel' && props.dependantChannels.length ? (
            <div className={classNames('dependant-channels', { 'm-t-16': directionVisible })}>
              {props.dependantChannels.map((ch) => (
                <DependantChannel key={ch.channelId} channel={ch} deviceId={props.channel.deviceId} />
              ))}
            </div>
          ) : null}
          {ventilationVisible && (
            <button onClick={ventilation} className="btn-custom m-t-16">
              <div className="icon">
                <IconVent />
              </div>
              <span>{t('ventilation')}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
