import React from 'react';
import { CoverControlBox, SwitchControl, MeterControl, OptimizerControl, LightControl, GateControlBox } from '..';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../types';

interface PropsInterface {
  channel: ChannelInterface;
  isListItem?: boolean;
}

export const ChannelBoxControls: React.FC<PropsInterface> = ({ channel, isListItem }) => {
  switch (channel.data.type) {
    case ChannelTypeInternal.Meter:
      return <MeterControl channel={channel} />;
    case ChannelTypeInternal.Optimizer:
      return <OptimizerControl channel={channel} />;
    case ChannelTypeInternal.Blind:
      return <CoverControlBox channels={[channel]} isListItem={isListItem} />;
    case ChannelTypeInternal.Gate:
      return <GateControlBox channels={[channel]} isListItem={isListItem} />;
    case ChannelTypeInternal.Switch:
      return <SwitchControl channel={channel} />;
    case ChannelTypeInternal.Light:
      return <LightControl channel={channel} />;
    default:
      return null;
  }
};
