import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  MovementSensorDeviceState,
  TamperProtectionDeviceState,
  TamperProtectionStatus,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { MovementControlHook } from '../types';

export const useMovementControl = (channel?: IDeviceChannel, disabled?: boolean): MovementControlHook => {
  if (disabled) return { movement: null, isTamperProtection: false, time: '' };

  const movementData = useMemo(() => {
    const movementState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.MovementSensorState,
    ) as MovementSensorDeviceState;

    if (movementState) {
      return movementState.Data;
    }

    return null;
  }, [channel?.States]);

  const tamperProtection = useMemo(() => {
    const tamperState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.TamperProtectionState,
    ) as TamperProtectionDeviceState;

    if (tamperState) {
      return tamperState.Data.State;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (movementData?.Time) return format(Date.parse(movementData.Time), 'dd.MM, HH:mm');
    return '';
  }, [movementData]);

  return {
    movement: movementData?.Movement ?? null,
    isTamperProtection: tamperProtection === TamperProtectionStatus.Breach || false,
    time,
  };
};
