export const isProd = ['lavva.cloud', 'exalushome.tr7.pl', 'wisniowski.lavva.cloud', 'smart.wisniowski.pl'].includes(
  window.location.host,
);
export const isStag = [
  'stage.lavva.cloud',
  'stage.tr7.pl',
  'wisniowski.stage.lavva.cloud',
  'stage.smart.wisniowski.pl',
].includes(window.location.host);
export const isDev = ['dev2.lavva.cloud'].includes(window.location.host);
export const isLocalhost = window.location.hostname === 'localhost';
