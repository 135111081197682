import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ControlWrapper, InitiateButton } from '../../../../../components';
import OTPInput from '../../../../../components/otp-input';
import { GateStateDirectionInternal } from '../../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../../hooks';
import { useChannelDetailsContext } from '../../../../../hooks/channel-details';
import { useGate } from '../../../../../hooks/use-gate';
import { ChannelInterface } from '../../../../../types';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../../../../types/channel/gate';
import { GateStepIcon } from '../gate-step-icon';
import './index.scss';

interface PropsInterface {
  channel: ChannelInterface;
}

const isBlockedByPin = true;

export const GateStepByStep: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [quickControl, setQuickControl] = useState<boolean>(false);
  const [unblocked, setUnblocked] = useState<boolean>(false);
  const [pinVisible, setPinVisible] = useState<boolean>(false);
  const { handleStepByStep, handleQuickControl } = useGate([channel]);
  const { channel: channelDetails } = useChannelDetailsContext();
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateInterface;

  useEffect(() => {
    if (channelDetails?.data) {
      setQuickControl((channelDetails?.data as ChannelGateInterface).isQuickControl);
    }
  }, [(channelDetails?.data as ChannelGateInterface)?.isQuickControl]);

  const gateStep = useMemo(() => {
    const position = (channelState[channel.id] as ChannelGateStateInterface).position;
    if (!position) return 3;
    return position === 100 ? 1 : 2;
  }, [channelState[channel.id]]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuickControl(e.target.checked);
    handleQuickControl(e.target.checked);
  };

  const handleAction = () => {
    if (!isBlockedByPin) {
      handleStepByStep();
    } else {
      if (!unblocked) setPinVisible(true);
      else handleStepByStep();
    }
  };

  const handlePinAction = (pin: string) => {
    if (pin.length === 4) {
      handleStepByStep();
      setPinVisible(false);
      setUnblocked(true);
    }
  };

  return (
    <>
      {!pinVisible ? (
        <>
          <ControlWrapper>
            <InitiateButton
              isOn={false}
              onClickControl={handleAction}
              active={[GateStateDirectionInternal.Opening, GateStateDirectionInternal.Closing].includes(
                state.direction,
              )}
            >
              <GateStepIcon step={gateStep} />
            </InitiateButton>
          </ControlWrapper>
          <div className="p-l-24 p-r-24 m-t-24">
            <Checkbox checked={quickControl} onChange={handleChange} reverse>
              <p style={{ fontSize: 16 }}>{t('quickControl')}</p>
            </Checkbox>
          </div>
        </>
      ) : (
        <div className="otp-container">
          <OTPInput onAction={handlePinAction} />
        </div>
      )}
    </>
  );
};
