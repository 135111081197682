import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CreateGroupMutation, CreateGroupMutationVariables } from '../../../data-access/gql-types/graphql';
import { CREATE_GROUP } from '../../../data-access/mutations/groups';
import { useInstallation, useBackdropContext, useDevicesAndChannels } from '../../../hooks';
import { useGroupErrors } from '../../../hooks/backend-errors/use-group-errors';
import { useRefetchData } from '../../../hooks/refresh-data';
import * as storage from '../../../utils/storage/lavva';
import { toastSuccess } from '../../../utils/toast';
import { useFormChannelsContext } from '../context';

export const useCreateGroup = () => {
  const history = useHistory();
  const { t } = useTranslation('groups');
  const { selectedInstallationId } = useInstallation();
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { channelList } = useDevicesAndChannels();
  const { handleErrors } = useGroupErrors();
  const { refetchActions, refetchDashboard } = useRefetchData();
  const { selectedChannels, selectedActions, prepareGroupItems } = useFormChannelsContext();
  const [createGroups, { loading: createGroupLoading }] = useMutation<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >(CREATE_GROUP);
  const form = useForm({
    defaultValues: {
      name: '',
      iconName: '',
    },
  });

  const defaultChannels = useMemo(
    () =>
      [...selectedChannels, ...selectedActions].map(
        (item) => channelList.find((channel) => channel && channel.id === item)?.id || '',
      ),
    [],
  );

  const handleChange = useCallback(() => setIsPublic(!isPublic), [isPublic]);

  const handleChangeIcon = (value: string) => form.setValue('iconName', value);

  const onSubmit = form.handleSubmit((values) => {
    turnOnBackdrop();
    const items = prepareGroupItems();

    createGroups({
      variables: {
        input: {
          name: values.name,
          installationId: selectedInstallationId,
          groupItems: items,
          isPublic,
          iconName: values.iconName,
        },
      },
      onCompleted: (data) => {
        turnOffBackdrop();
        if (data.createGroup.idResponse?.id) {
          toastSuccess({ content: t('groupCreated') });
          refetchDashboard();
          refetchActions();
          storage.setItem('lastActiveGroupId', String(data.createGroup.idResponse.id));
          history.goBack();
        } else {
          handleErrors(data.createGroup.errors || []);
        }
      },
      onError: () => turnOffBackdrop(),
    });
  });

  return {
    form,
    isPublic,
    createGroupLoading,
    defaultChannels,
    onSubmit,
    handleChange,
    handleChangeIcon,
  };
};
