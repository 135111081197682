import { useMemo } from 'react';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { HumiditySensorDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { HumidityControlHook } from '../types';

export const useHumidityControl = (channel?: IDeviceChannel, disabled?: boolean): HumidityControlHook => {
  if (disabled) return { humidity: null, time: '' };
  
  const humidityData = useMemo(() => {
    const humidityState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.HumiditySensorState,
    ) as HumiditySensorDeviceState;

    if (humidityState) {
      return humidityState.Data;
    }

    return null;
  }, [channel?.States]);

  const time = useMemo(() => {
    if (humidityData?.Time) return format(Date.parse(humidityData.Time), 'dd.MM, HH:mm');
    return '';
  }, [humidityData]);

  return {
    humidity: humidityData?.Humidity ?? null,
    time,
  };
};
