import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import {
  BlindStateDirectionInternal,
  ChannelTypeInternal,
  GateStateDirectionInternal,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState, useLight, useSwitch } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { ChannelCoverStateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../types';
import { Body, HeadContent } from '../elements';
import './index.scss';

interface ChannelBoxProps {
  channel: ChannelInterface;
  isListItem?: boolean;
  isCheckbox?: boolean;
  isDraggable?: boolean;
  checked?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  onChannelToggle?: (id: string, value: boolean) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  noRedirect?: boolean;
}

export const ChannelBox: React.FC<ChannelBoxProps> = ({
  channel,
  isDraggable = false,
  isCheckbox = false,
  isListItem = false,
  checked = false,
  onChannelToggle,
  setNodeRef,
  style,
  attributes,
  listeners,
  noRedirect,
}) => {
  const history = useHistory();
  const { isOn: isOnSwitch } = useSwitch({ channelId: channel.id, deviceId: channel.deviceId });
  const { isOn: isOnRgb } = useLight(channel);
  const { channelState } = useChannelsState();

  const taskActive = useMemo(() => {
    if (
      channel.data.type === ChannelTypeInternal.Gate &&
      [GateStateDirectionInternal.Opening, GateStateDirectionInternal.Closing].includes(
        (channelState[channel.id] as ChannelGateStateInterface).direction,
      )
    ) {
      return true;
    }
    if (
      channel.data.type === ChannelTypeInternal.Blind &&
      (channelState[channel.id] as ChannelCoverStateInterface).lavvaStateDirection &&
      [BlindStateDirectionInternal.Opening, BlindStateDirectionInternal.Closing].includes(
        (channelState[channel.id] as ChannelCoverStateInterface).lavvaStateDirection as BlindStateDirectionInternal,
      )
    ) {
      return true;
    }

    return false;
  }, [channelState[channel.id]]);

  const redirectToDetails = () => history.push(ROUTES.ChannelDetails(channel.id));

  return (
    <div
      className={classNames('device-box max-width-desktop', {
        'device-box--checkbox': isCheckbox,
        'device-box--list-item': isListItem,
        'device-box--turn-on': isOnSwitch || isOnRgb,
        'task-active': taskActive,
      })}
      style={style}
      ref={setNodeRef}
    >
      {isDraggable || noRedirect ? (
        <div className={classNames('device-box__head', { invisible: !channel.isVisible })}>
          <HeadContent channel={channel} />
        </div>
      ) : (
        <div className={classNames('device-box__head', { invisible: !channel.isVisible })} onClick={redirectToDetails}>
          <HeadContent channel={channel} />
        </div>
      )}
      <Body
        channel={channel}
        isDraggable={isDraggable}
        onChannelToggle={onChannelToggle}
        isCheckbox={isCheckbox}
        checked={checked}
        attributes={attributes}
        listeners={listeners}
        isListItem={isListItem}
      />
    </div>
  );
};
