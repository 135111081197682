import React from 'react';

interface PropsInterface {
  height: number;
}

export const IconReferenceLine: React.FC<PropsInterface> = () => (
  <svg
    className="icon-reference-line"
    width="18"
    height="156"
    viewBox="0 0 18 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.00001 144L9 0" stroke="url(#paint0_linear_8202_309742)" strokeWidth="2" />
    <rect x="1.5" y="139.5" width="15" height="15" rx="7.5" fill="#55555C" stroke="white" strokeWidth="3" />
    <defs>
      <linearGradient
        id="paint0_linear_8202_309742"
        x1="9.5"
        y1="-2.15521e-08"
        x2="9.50001"
        y2="144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#626262" stopOpacity="0" />
        <stop offset="0.536458" stopColor="#676767" />
        <stop offset="1" stopColor="#585858" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
  // <svg
  //   className="icon-reference-line"
  //   width="18"
  //   height={height}
  //   viewBox={`0 0 18 ${height}`}
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path d={`M9.00001 ${height}L9 0`} stroke="url(#paint0_linear_7727_297111)" strokeWidth="2" />
  //   <rect x="1.5" y={height - 17} width="15" height="15" rx="7.5" fill="#55555C" stroke="white" strokeWidth="3" />
  //   <defs>
  //     <linearGradient
  //       id="paint0_linear_7727_297111"
  //       x1="9.5"
  //       y1="-2.15521e-08"
  //       x2="9.50001"
  //       y2={height}
  //       gradientUnits="userSpaceOnUse"
  //     >
  //       <stop stopColor="#626262" stopOpacity="0" />
  //       <stop offset="0.536458" stopColor="#676767" />
  //       <stop offset="1" stopColor="#585858" stopOpacity="0" />
  //     </linearGradient>
  //   </defs>
  // </svg>
);
