import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import {
  OnGateConfigurationChangeSubscription,
  OnGateConfigurationChangeSubscriptionVariables,
  OnGatePositionChangeSubscription,
  OnGatePositionChangeSubscriptionVariables,
  OnOptimizerConfigurationTaintedPayloadSubscription,
  OnOptimizerConfigurationTaintedPayloadSubscriptionVariables,
} from '../../../data-access/gql-types/graphql';
import {
  ON_BLIND_CALIBRATE_STATUS_CHANGE,
  ON_GATE_CALIBRATE_STATUS_CHANGE,
  ON_BLIND_POSITION_CHANGE,
  ON_GATE_POSITION_CHANGE,
  ON_LIGHT_BRIGHTNESS_CHANGE,
  ON_LIGHT_COLOR_CHANGE,
  ON_LIGHT_TEMPERATURE_CHANGE,
  ON_LIGHT_TOGGLE_STATE_CHANGE,
  ON_REQUEST_ACK_RECEIVED,
  ON_SWITCH_STATE_CHANGE,
  ON_GATE_CONFIGURATION_CHANGE,
  ON_OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD,
} from '../../../data-access/subscriptions/lavva-devices';
import { useInstallation } from '../../../hooks';
import { ChannelSubscriptionParams } from './types';
import { useUpdateLavvaState } from './update-lavva-state';

export const useSwitchSubscription = ({ channelId }: ChannelSubscriptionParams) => {
  const { updateLavvaSwitchState, onRequestAckReceived } = useUpdateLavvaState();
  const { skipLavvaFetch } = useInstallation();
  const { data: switchChange } = useSubscription(ON_SWITCH_STATE_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (switchChange) updateLavvaSwitchState(switchChange.onSwitchStateChange);
  }, [switchChange]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);
};

export const useBlindSubscription = ({ channelId }: ChannelSubscriptionParams): void => {
  const { updateLavvaBlindPosition, onRequestAckReceived, onBlindCalibrateStatusChange } = useUpdateLavvaState();
  const { skipLavvaFetch } = useInstallation();
  const { data: blindPosition } = useSubscription(ON_BLIND_POSITION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });
  const { data: blindCalibration } = useSubscription(ON_BLIND_CALIBRATE_STATUS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (blindPosition) updateLavvaBlindPosition(blindPosition.onBlindPositionChange);
  }, [blindPosition]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);

  useEffect(() => {
    if (blindCalibration) onBlindCalibrateStatusChange(blindCalibration.onBlindCalibrateStatusChange);
  }, [blindCalibration]);
};

export const useLightSubscription = ({ channelId }: ChannelSubscriptionParams): void => {
  const {
    updateLavvaLightState,
    updateLavvaLightBrightness,
    updateLavvaLightTemperature,
    updateLavvaLightColor,
    onRequestAckReceived,
  } = useUpdateLavvaState();
  const { skipLavvaFetch } = useInstallation();
  const { data: lightToggleChange } = useSubscription(ON_LIGHT_TOGGLE_STATE_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: lightBrightnessChange } = useSubscription(ON_LIGHT_BRIGHTNESS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: lightTemperatureChange } = useSubscription(ON_LIGHT_TEMPERATURE_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: lightColorChange } = useSubscription(ON_LIGHT_COLOR_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (lightToggleChange) {
      updateLavvaLightState(lightToggleChange.onLightToggleStateChange);
    }
  }, [lightToggleChange]);

  useEffect(() => {
    if (lightBrightnessChange) {
      updateLavvaLightBrightness(lightBrightnessChange.onLightBrightnessChange);
    }
  }, [lightBrightnessChange]);

  useEffect(() => {
    if (lightTemperatureChange) {
      updateLavvaLightTemperature(lightTemperatureChange.onLightTemperatureChange);
    }
  }, [lightTemperatureChange]);

  useEffect(() => {
    if (lightColorChange) {
      updateLavvaLightColor(lightColorChange.onLightColorChange);
    }
  }, [lightColorChange]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);
};

export const useGateSubscription = ({ channelId }: ChannelSubscriptionParams): void => {
  const { onRequestAckReceived, updateLavvaGatePosition, onGateCalibrateStatusChange, updateLavvaGateConfiguration } =
    useUpdateLavvaState();
  const { skipLavvaFetch } = useInstallation();
  const { data: gatePosition } = useSubscription<
    OnGatePositionChangeSubscription,
    OnGatePositionChangeSubscriptionVariables
  >(ON_GATE_POSITION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateConfiguration } = useSubscription<
    OnGateConfigurationChangeSubscription,
    OnGateConfigurationChangeSubscriptionVariables
  >(ON_GATE_CONFIGURATION_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });
  const { data: ackReceived } = useSubscription(ON_REQUEST_ACK_RECEIVED, {
    variables: { channelOrDeviceId: channelId },
    skip: skipLavvaFetch,
  });
  const { data: gateCalibration } = useSubscription(ON_GATE_CALIBRATE_STATUS_CHANGE, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (gatePosition) updateLavvaGatePosition(gatePosition.onGatePositionChange);
  }, [gatePosition]);

  useEffect(() => {
    if (gateConfiguration) updateLavvaGateConfiguration(gateConfiguration.onGateConfigurationChange);
  }, [gateConfiguration]);

  useEffect(() => {
    if (ackReceived) onRequestAckReceived(ackReceived.onRequestAckReceived);
  }, [ackReceived]);

  useEffect(() => {
    if (gateCalibration) onGateCalibrateStatusChange(gateCalibration.onGateCalibrateStatusChange);
  }, [gateCalibration]);
};

export const useOptimizerSubscription = ({ channelId }: ChannelSubscriptionParams): void => {
  const { skipLavvaFetch } = useInstallation();
  const { data: optimizerConfigurationTainted } = useSubscription<
    OnOptimizerConfigurationTaintedPayloadSubscription,
    OnOptimizerConfigurationTaintedPayloadSubscriptionVariables
  >(ON_OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (optimizerConfigurationTainted) {
      console.log(
        'OPTIMIZER_CONFIGURATION_TAINTED_PAYLOAD',
        optimizerConfigurationTainted.onOptimizerConfigurationTaintedPayload,
      );
    }
  }, [optimizerConfigurationTainted]);
};
