import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetActionBindings } from '../../../../api/modules/device/device.hooks';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../hooks';
import { useErrors } from '../../../../hooks/use-errors';
import { timeToMilliseconds } from '../../../../utils/helpers';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { ActionBindingType, channelTypeForRequest, DeviceBindingsForm, InputBindingTrigger } from '../types';

export const useSubmitBindings = () => {
  const { t } = useTranslation('device-settings');
  const { deviceId } = useParams<{ deviceId: string }>();
  const { mutate } = useSetActionBindings();
  const { handleLavvaResolve } = useErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleSubmitBindings = (values: DeviceBindingsForm, channelType: ChannelTypeInternal) => {
    turnOnBackdrop();

    const bindings = values.bindings.map((x) => ({
      ...x,
      channelType,
    }));

    const request = {
      deviceId,
      bindings: bindings.map((x) => ({
        channelId: x.channelId,
        inputId: x.inputId,
        slot: x.slot || 0,
        channelType: channelTypeForRequest[x.channelType],
        bindings: x.list.map((y) => ({
          $: y.actionType,
          inputTrigger: y.inputTrigger as InputBindingTrigger,
          isDefault: y.actionType === ActionBindingType.Default,
          ...(y.setOnTimeValue !== undefined ? { setOnTimeValue: timeToMilliseconds(y.setOnTimeValue) } : {}),
          ...(y.position !== undefined ? { position: y.position } : {}),
          ...(y.dir !== undefined ? { dir: y.dir } : {}),
          ...(y.temperature !== undefined ? { temperature: y.temperature } : {}),
          ...(y.brightness !== undefined ? { brightness: y.brightness } : {}),
          ...(y.r !== undefined ? { r: y.r } : {}),
          ...(y.g !== undefined ? { g: y.g } : {}),
          ...(y.b !== undefined ? { b: y.b } : {}),
        })),
      })),
    };

    console.log('BINDINGS REQUEST', request);

    mutate(request, {
      onSuccess: ({ data }) => {
        handleLavvaResolve(data.publishStatus, () => {
          turnOffBackdrop();
          toastSuccess({ content: t('bindings.submitResult.success') });
        });
      },
      onError: () => {
        turnOffBackdrop();
        toastError({ content: t('bindings.submitResult.error') });
      },
    });
  };

  return {
    handleSubmitBindings,
  };
};
