import React, { useEffect, useMemo } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { useDevicesAndChannels, useInstallation } from '..';
import { ChannelTypeInternal, IntegrationType, MarkActivityMutation } from '../../data-access/gql-types/graphql';
import { MARK_ACTVITY } from '../../data-access/mutations/user';
import {
  ON_DEVICE_FIRMWARE_UPDATED,
  ON_RECOVERY_DEVICE_STATUS_RECEIVED_PAYLOAD,
} from '../../data-access/subscriptions/lavva-devices';
import { ChannelInterface, DeviceInterface } from '../../types';
import { ChannelSubProps, DeviceSubProps } from '../../types/subscriptions';
import {
  useBlindSubscription,
  useLightSubscription,
  useSwitchSubscription,
  useGateSubscription,
  useOptimizerSubscription,
} from '../../utils/channels/subscriptions/lavva-devices';
import { useUpdateLavvaState } from '../../utils/channels/subscriptions/update-lavva-state';

const ChannelSub: React.FC<ChannelSubProps> = ({ channel }) => {
  switch (channel.data.type) {
    case ChannelTypeInternal.Switch:
      useSwitchSubscription({ channelId: channel.id });
      break;
    case ChannelTypeInternal.Blind:
      useBlindSubscription({ channelId: channel.id });
      break;
    case ChannelTypeInternal.Light:
      useLightSubscription({ channelId: channel.id });
      break;
    case ChannelTypeInternal.Gate:
      useGateSubscription({ channelId: channel.id });
      break;
    case ChannelTypeInternal.Optimizer:
      useOptimizerSubscription({ channelId: channel.id });
      break;
  }

  return null;
};

const DeviceSub: React.FC<DeviceSubProps> = ({ device }) => {
  const { onDeviceFirmwareUpdated, onRecoveryFirmwareUpdated } = useUpdateLavvaState();
  const { skipLavvaFetch } = useInstallation();

  const { data: firmwareUpdated } = useSubscription(ON_DEVICE_FIRMWARE_UPDATED, {
    variables: { deviceId: device?.id },
    skip: skipLavvaFetch,
  });

  const { data: recoveryDeviceStatusReceivedPayload } = useSubscription(ON_RECOVERY_DEVICE_STATUS_RECEIVED_PAYLOAD, {
    variables: { deviceId: device?.id },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (firmwareUpdated) onDeviceFirmwareUpdated(firmwareUpdated.onDeviceFirmwareUpdated);
  }, [firmwareUpdated]);

  useEffect(() => {
    if (recoveryDeviceStatusReceivedPayload) {
      onRecoveryFirmwareUpdated(recoveryDeviceStatusReceivedPayload.onRecoveryDeviceStatusRecievedPayload);
    }
  }, [recoveryDeviceStatusReceivedPayload]);

  return null;
};

export const ChannelSubscriptions: React.FC = () => {
  const { channelList, deviceList } = useDevicesAndChannels();
  const { integrationType } = useInstallation();
  const [markActivity] = useMutation<MarkActivityMutation>(MARK_ACTVITY);

  useEffect(() => {
    markActivity();
  }, []);

  const channelSubscriptions = useMemo(() => {
    if (channelList.length && integrationType === IntegrationType.Lavva) {
      return channelList.map((channel: ChannelInterface) => <ChannelSub key={channel.id} channel={channel} />);
    }
  }, [channelList, integrationType]);

  const deviceSubscriptions = useMemo(() => {
    if (deviceList.length && integrationType === IntegrationType.Lavva) {
      return deviceList.map((device: DeviceInterface) => <DeviceSub key={device.id} device={device} />);
    }
  }, [deviceList, integrationType]);

  return (
    <>
      {channelSubscriptions}
      {deviceSubscriptions}
    </>
  );
};
