import { Dispatch, SetStateAction } from 'react';
import { NativeBluetoothDeviceInfo } from 'lavva.webbluetooth';
import { SelectOptionInterface } from '../../../components';
import { FindWebBluetoothDevicesView } from '../bluetooth/utils/FindWebBluetoothDevicesView';
import { BluetoothData, DiagnosticPayload, UpdateData } from '../bluetooth/types';

export interface AddDeviceHook {
  addDevice: () => void;
}

export interface BluetoothProviderInterface {
  bluetoothData: BluetoothData;
  deviceConnected: boolean;
  identifing: boolean;
  wifiList: SelectOptionInterface<string>[];
  typeOptions: SelectOptionInterface<string>[];
  wifiLoading: boolean;
  isAlreadyAdded: boolean;
  checkList: string[];
  registerErrorMessage: string;
  redoRegisteringDisabled: boolean;
  updateBluetoothData: (obj: Record<string, string | boolean | DiagnosticPayload | null>) => void;
  checkIfConnected: () => void;
  searchBluetooth: (custom: boolean) => void;
  getWifiNetworks: () => void;
  getDeviceSettings: () => void;
  identifyDevice: () => void;
  resetBluetoothData: () => void;
  disconnect: () => void;
  releaseLock: () => void;
  setRedoRegisteringDisabled: Dispatch<SetStateAction<boolean>>;
  registerDevice: (ssid: string, password: string) => void;
  getKnowsWifiNetworks: () => void;
  updateByWifi: () => void;
  setWifi: (ssid: string, password: string) => void;
  removeWifi: (ssid: string) => void;
  updatePayload: UpdateData;
  percentage: number;
  isCustomBluetooth: boolean;
  foundDevices: NativeBluetoothDeviceInfo[];
  dialogScanning: boolean;
  scanning: boolean;
  setDialogScanning: Dispatch<SetStateAction<boolean>>;
  handleDeviceConnect: (device: NativeBluetoothDeviceInfo) => void;
  WebBluetooth: FindWebBluetoothDevicesView;
  updateWifiData: (ssid: string, password: string) => void;
  returnToStartPage: () => void;
}

export enum DeviceType {
  Desktop = 'DESKTOP',
  Ios = 'IOS',
  Android = 'ANDROID',
}
