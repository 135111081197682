import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { SelectOptionInterface } from '../../../../components';
import {
  ChannelTypeInternal,
  ControlledInputResponse,
  DeviceGetBindingsQuery,
  DeviceGetBindingsQueryVariables,
  UserChannel,
} from '../../../../data-access/gql-types/graphql';
import { DEVICE_GET_BINDINGS } from '../../../../data-access/queries/devices';
import { useInstallation } from '../../../../hooks';
import {
  ActionBindingType,
  BindingsItem,
  ControllerInputPayload,
  ControllerInputsPayload,
  DeviceBindingsForm,
  InputBindingTrigger,
  actionOptions,
} from '../types';
import { mapFetchBindingDataWithControlledInput, mapFetchBindingDataWithControlledInputs } from '../utils';

export const useDeviceBindings = () => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const { deviceId } = useParams<{ deviceId: string }>();
  const { selectedInstallationId } = useInstallation();
  const [channelOptions, setChannelOptions] = useState<SelectOptionInterface<string>[]>([]);
  const [inputOptions, setInputOptions] = useState<SelectOptionInterface<string>[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number[]>([]);
  const [channels, setChannels] = useState<UserChannel[]>([]);
  const [channelType, setChannelType] = useState<ChannelTypeInternal>(ChannelTypeInternal.Unknown);
  const form = useForm<DeviceBindingsForm>({
    defaultValues: {
      bindings: [],
    },
  });
  const { fields, update } = useFieldArray({
    control: form.control,
    name: 'bindings',
  });
  const { data, loading } = useQuery<DeviceGetBindingsQuery, DeviceGetBindingsQueryVariables>(DEVICE_GET_BINDINGS, {
    variables: { installationId: selectedInstallationId, deviceId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data?.channelBindings) {
      const filteredChannels = (data?.channelBindings || []).filter((x) =>
        [
          ChannelTypeInternal.Switch,
          ChannelTypeInternal.Light,
          ChannelTypeInternal.Blind,
          ChannelTypeInternal.Gate,
        ].includes(x.channelType),
      );

      const genericInputs = (data?.channelBindings || []).filter(
        (x) => x.channelType === ChannelTypeInternal.GenericInput,
      );

      console.log('CHANNELS', { filteredChannels, genericInputs });
      setChannels(filteredChannels as UserChannel[]);

      let inputs: ControlledInputResponse[] = [];
      const bindings: BindingsItem[] = [];

      if (
        filteredChannels.find((channel) =>
          [ChannelTypeInternal.Blind, ChannelTypeInternal.Gate].includes(channel.channelType),
        )
      ) {
        filteredChannels.map((channel) => {
          if (channelType === ChannelTypeInternal.Unknown) setChannelType(channel.channelType);

          if (channel.payload && 'controlledInputs' in channel.payload) {
            (channel.payload as ControllerInputsPayload).controlledInputs.map((input) => {
              inputs.push(input);

              bindings.push(mapFetchBindingDataWithControlledInputs(channel as UserChannel, input));
            });
          }
        });
      } else {
        inputs = uniqBy(
          filteredChannels.map((channel) => {
            if (channelType === ChannelTypeInternal.Unknown) setChannelType(channel.channelType);
            if (channel.payload && 'controlledInput' in channel.payload) {
              bindings.push(mapFetchBindingDataWithControlledInput(channel as UserChannel));

              return (channel.payload as ControllerInputPayload).controlledInput;
            }
          }),
          (x) => x?.inputId,
        ) as ControlledInputResponse[];
      }

      setChannelOptions(filteredChannels.filter((x) => x).map((x) => ({ label: x.alias, value: x.id })));
      setInputOptions(
        inputs
          .filter((x) => x)
          .map((x) => {
            const inputAlias = genericInputs.find((gen) => gen.id === x.inputId)?.alias;

            return { label: inputAlias || x.inputId, value: x.inputId, weight: x.slot };
          }),
      );

      if (!bindings.length) {
        setActiveTabIndex(new Array(inputs.length).fill(-1));
      } else {
        setActiveTabIndex(bindings.map((x) => (x.list.length ? 0 : -1)));
      }

      form.setValue(
        'bindings',
        !bindings.length
          ? inputs.map(() => ({
              channelId: '',
              inputId: '',
              slot: null,
              list: [],
            }))
          : bindings,
      );
    }
  }, [data?.channelBindings]);

  const inputTriggers = [
    { label: t('bindings.triggerMethods.singleClick'), value: InputBindingTrigger.SingleClick },
    { label: t('bindings.triggerMethods.doubleClick'), value: InputBindingTrigger.DoubleClick },
  ];

  const actionSelectOptions = useMemo(
    () =>
      actionOptions[channelType].map((x) => ({
        label: x !== ActionBindingType.Default ? tc(`types.${x.toUpperCase()}`) : tc(x),
        value: x,
      })),
    [channelType],
  );

  const handleChangeInput = (inputId: string, index: number) => {
    const { bindings } = form.getValues();
    const slot = inputOptions.find((x) => x.value === inputId)?.weight;

    bindings[index].inputId = inputId;
    if (slot !== undefined) bindings[index].slot = slot;
    update(index, { ...bindings[index] });
  };

  const handleChangeActionType = (actionType: ActionBindingType, index: number) => {
    const { bindings } = form.getValues();
    bindings[index].list[activeTabIndex[index]].actionType = actionType;

    switch (actionType) {
      case ActionBindingType.Switch: {
        bindings[index].list[activeTabIndex[index]].setOnTimeValue = '00:00:00';
        break;
      }
      case ActionBindingType.Blind: {
        bindings[index].list[activeTabIndex[index]].position = 0;
        break;
      }
      case ActionBindingType.Default: {
        delete bindings[index].list[activeTabIndex[index]].setOnTimeValue;
        delete bindings[index].list[activeTabIndex[index]].position;
        delete bindings[index].list[activeTabIndex[index]].r;
        delete bindings[index].list[activeTabIndex[index]].g;
        delete bindings[index].list[activeTabIndex[index]].b;
        delete bindings[index].list[activeTabIndex[index]].brightness;
        delete bindings[index].list[activeTabIndex[index]].temperature;
        break;
      }
    }

    update(index, { ...bindings[index] });
  };

  const handleChangeIndex = (tabIndex: number, position: number) => {
    setActiveTabIndex((prev) => {
      prev[position] = tabIndex;
      return [...prev];
    });
  };

  const addBindingItem = (index: number) => {
    const { bindings } = form.getValues();
    bindings[index].list.push({ actionType: null, inputTrigger: null });
    update(index, { ...bindings[index] });

    setActiveTabIndex((prev) => {
      prev[index] = prev[index] + 1;
      return [...prev];
    });
  };

  const handleDeleteBinding = (index: number) => {
    const { bindings } = form.getValues();
    bindings[index].list.splice(activeTabIndex[index], 1);
    update(index, { ...bindings[index] });

    setActiveTabIndex((prev) => {
      prev[index] = bindings[index].list.length - 1;
      return [...prev];
    });
  };

  return {
    form,
    fields,
    loading,
    channelOptions,
    inputOptions,
    inputTriggers,
    channelType,
    actionSelectOptions,
    activeTabIndex,
    channels,
    handleChangeInput,
    handleDeleteBinding,
    addBindingItem,
    handleChangeIndex,
    handleChangeActionType,
  };
};
