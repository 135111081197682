import React, { ChangeEvent, useState, useEffect, useRef, ReactNode, KeyboardEvent } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import { IconChevron, IconClose } from '../icons';
import './index.scss';

interface InputProps {
  defaultValue?: string;
  value?: string;
  inputType?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  label?: string;
  min?: number;
  max?: number;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  hasError?: boolean;
  errorMessage?: string;
  children?: ReactNode;
  autoFocus?: boolean;
  autoCapitalize?: string;
  readOnly?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClear?: () => void;
  onClickStartIcon?: () => void;
  onClickInput?: () => void;
  isPin?: boolean;
  step?: string;
  maxLength?: number;
  pattern?: string;
  info?: boolean;
  noBottomMargin?: boolean;
  reverse?: boolean;
}

export const Input: React.FC<InputProps> = ({
  id = uuid.v4(),
  name = '',
  inputType = 'text',
  defaultValue = '',
  disabled = false,
  label = '',
  required = false,
  min,
  max,
  onChange,
  placeholder,
  hasError = false,
  errorMessage = '',
  children,
  autoFocus = false,
  autoCapitalize,
  readOnly = false,
  startIcon,
  endIcon,
  onClear,
  onClickStartIcon,
  onClickInput,
  step,
  isPin = false,
  maxLength,
  pattern,
  info,
  noBottomMargin,
  onKeyDown,
  reverse,
}) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue || '');
  const [validate, setValidate] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const onFocus = () => {
    if (!ref.current) return;
    const pos = ref.current.value.length;

    if (inputType === 'text') ref.current.setSelectionRange(pos, pos);
  };

  const internalOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValidate(true);
    setInputValue(event.target.value);
    if (onChange) onChange(event);
  };

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  const handleClear = () => {
    setInputValue('');
    if (onClear) onClear();
  };

  const handleClickStartIcon = () => {
    setInputValue('');
    if (onClickStartIcon) onClickStartIcon();
  };

  const onInvalid = () => setValidate(true);

  return (
    <div
      className={classNames('input', {
        'input--disabled': disabled,
        'input--no-bottom-margin': noBottomMargin,
        'input--reverse': reverse,
        'input--clickable': onClickInput,
      })}
      {...(onClickInput ? { onClick: onClickInput } : {})}
    >
      {label && (
        <label htmlFor={id} className={classNames('input__label')}>
          {label}
        </label>
      )}
      <div
        className={classNames('input__wrapper', {
          with_start_icon: startIcon,
          right_button: onClear || inputType === 'number',
        })}
      >
        {startIcon && (
          <button className="start_icon" {...(onClickStartIcon ? { onClick: handleClickStartIcon } : {})}>
            {startIcon}
          </button>
        )}
        {endIcon && <div className="end_icon">{endIcon}</div>}
        <input
          ref={ref}
          className={classNames('input__input', {
            'input__input--validate': validate,
            'has-error': hasError,
            info: info,
          })}
          id={id}
          name={name}
          type={inputType}
          value={inputValue}
          disabled={disabled}
          onFocus={onFocus}
          autoComplete="new-password"
          required={required}
          onChange={internalOnChange}
          placeholder={placeholder !== undefined ? placeholder : label}
          onInvalid={onInvalid}
          autoCapitalize={autoCapitalize}
          readOnly={readOnly}
          {...(onKeyDown ? { onKeyDown } : {})}
          {...(step ? { step } : {})}
          {...(isPin ? { maxLength: 4 } : {})}
          {...(inputType === 'number' && min !== undefined ? { min } : {})}
          {...(inputType === 'number' && max !== undefined ? { max } : {})}
          {...(maxLength ? { maxLength } : {})}
          {...(pattern ? { pattern } : {})}
        />
        {onClear && inputValue && (
          <button className="clear_icon" onClick={handleClear}>
            <IconClose />
          </button>
        )}
        {inputType === 'number' && !onClear && (
          <div className="custom_counter">
            <button type="button" onClick={() => ref.current?.stepUp()}>
              <IconChevron direction="up" size={20} />
            </button>
            <button type="button" onClick={() => ref.current?.stepDown()}>
              <IconChevron direction="down" size={20} />
            </button>
          </div>
        )}
        {children}
      </div>
      {errorMessage && <div className="input__error-message">{errorMessage}</div>}
    </div>
  );
};
