import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DialogConfirmation } from '../../../../../../components';
import { useInstallation } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';

interface PropsInterface {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const InstallationLocationDialog: React.FC<PropsInterface> = ({ open, setOpen }) => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallation();

  const onClose = useCallback(() => setOpen(false), []);

  const handleConfirm = useCallback(() => history.push(ROUTES.InstallationSettings(selectedInstallationId)), []);

  return (
    <DialogConfirmation
      show={open}
      setShow={onClose}
      header={t('trigger.installationLocation.header')}
      content={t('trigger.installationLocation.content')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnText={t('trigger.installationLocation.button')}
      secondaryBtnAction={onClose}
      primaryBtnAction={handleConfirm}
      direction="column"
    />
  );
};
