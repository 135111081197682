import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService, GetParamsErrorCode } from 'lavva.exalushome.extalife';
import {
  ISbrConfigService,
  SbrAutoCloseTime,
  SbrServiceInfo,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/ISbrConfigService';
import { SbrConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/SbrConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FieldChangeResultType, ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { AccessLevel } from 'lavva.exalushome/build/js/Services/Users/IUser';
import { Input, SubmitButton } from '../../../../../../components';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { toastError } from '../../../../../../utils/toast';
import { AdvanceDelayDialog } from '../../../../../trigger-form/condition-types/time-condition/components';
import { useLoggedUser } from '../../../../hooks/common/logged-user';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { SbrBidiStatistics } from './statistics';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const SbrConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const [showTimeDialog, setShowTimeDialog] = useState<boolean>(false);
  // const { handleFieldChangeErrors } = useDeviceConfiguration();
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('AutoCloseTime');
  const [statistics, setStatistics] = useState<SbrServiceInfo | null>(null);
  const [seconds, setSeconds] = useState<number>(60);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { user } = useLoggedUser();
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const statisticsAvailable = useMemo(() => user?.AccessLevel === AccessLevel.Support, [user?.AccessLevel]);

  const getDefaultParams = async (panel: string) => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISbrConfigService>(
      SbrConfigService.ServiceName,
    );

    if (configService) {
      turnOnBackdrop();

      switch (panel) {
        case 'AutoCloseTime': {
          if (channel) {
            const data: SbrAutoCloseTime | ResponseResult<GetParamsErrorCode> =
              await configService.GetAutoCloseTimeAsync(channel);

            handleGetParamsResponse<SbrAutoCloseTime>(data, () => {
              setSeconds((data as SbrAutoCloseTime).TimeInMiliseconds / 1000);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        case 'AlarmLightDelay': {
          if (channel) {
            const data: number | ResponseResult<GetParamsErrorCode> = await configService.GetAlarmLightDelayAsync(
              channel,
            );

            handleGetParamsResponse<number>(data, () => {
              setSeconds((data as number) / 1000);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        case 'AlarmLightDuration': {
          if (channel) {
            const data: number | ResponseResult<GetParamsErrorCode> = await configService.GetAlarmLightDurationAsync(
              channel,
            );

            handleGetParamsResponse<number>(data, () => {
              setSeconds((data as number) / 1000);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        case 'ServiceInfo': {
          if (channel) {
            const data: SbrServiceInfo | ResponseResult<GetParamsErrorCode> = await configService.GetServiceInfoAsync(
              channel,
            );

            handleGetParamsResponse<SbrServiceInfo>(data, () => {
              setStatistics(data as SbrServiceInfo);
              setOpen(true);
              setConfigType(panel);
            });
          }
          break;
        }
        default:
          break;
      }
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  const handleSubmit = async () => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISbrConfigService>(
        SbrConfigService.ServiceName,
      );

      switch (configType) {
        case 'AutoCloseTime': {
          const data: ResponseResult<FieldChangeResultType> = await configService.SetAutoCloseTimeAsync(
            channel,
            seconds * 1000,
            false,
          );

          console.log(data);
          handleBack();
          break;
        }
        case 'AlarmLightDelay': {
          const data: ResponseResult<FieldChangeResultType> = await configService.SetAlarmLightDelayAsync(
            channel,
            seconds * 1000,
          );

          console.log(data);
          handleBack();
          break;
        }
        case 'AlarmLightDuration': {
          const data: ResponseResult<FieldChangeResultType> = await configService.SetAlarmLightDurationAsync(
            channel,
            seconds * 1000,
          );

          console.log(data);
          handleBack();
          break;
        }
        default:
          break;
      }

      turnOffBackdrop();
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage
          handleBack={handleBack}
          {...(configType === 'ServiceInfo' ? { header: t('exalus.params.SbrParams.ServiceInfo') } : {})}
        >
          {configType !== 'ServiceInfo' ? (
            <>
              <Input
                defaultValue={seconds.toString()}
                value={seconds.toString()}
                required
                readOnly
                label={`${t(`exalus.params.SbrParams.${configType}`)} [s]`}
                onClickInput={() => setShowTimeDialog(true)}
              />
              <AdvanceDelayDialog
                open={showTimeDialog}
                setOpen={setShowTimeDialog}
                title={t(`exalus.params.SbrParams.${configType}`)}
                onSave={(value) => setSeconds(value)}
                time={seconds}
                min={0}
                max={60}
                type="seconds"
              />
              <SubmitButton onClick={handleSubmit} />
            </>
          ) : (
            <SbrBidiStatistics statistics={statistics} />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.SbrParams.AutoCloseTime')}
                onClick={() => handleOpen('AutoCloseTime')}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SbrParams.AlarmLightDelay')}
                onClick={() => handleOpen('AlarmLightDelay')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SbrParams.AlarmLightDuration')}
                onClick={() => handleOpen('AlarmLightDuration')}
              />
              {statisticsAvailable && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.SbrParams.ServiceInfo')}
                  onClick={() => handleOpen('ServiceInfo')}
                />
              )}
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SbrConfigForm;
