import { ReactNode } from 'react';
import { ArgumentType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import {
  IconActions,
  IconCalendar,
  IconCyclical,
  IconDevices,
  IconLocation,
  IconSchedule,
  IconTime,
  IconWeather,
} from '../../../../../components/icons';
import { ConditionType, ConditionTypeOption } from '../types';

export const actionConditionTypes: ConditionTypeOption[] = [
  {
    title: 'action.create.conditions.list.devices.header',
    content: 'action.create.conditions.list.devices.content',
    icon: <IconDevices />,
    type: ConditionType.Devices,
    disabled: false,
  },
  {
    title: 'action.create.conditions.list.schedule.header',
    content: 'action.create.conditions.list.schedule.content',
    icon: <IconSchedule />,
    type: ConditionType.Schedule,
    disabled: false,
  },
  {
    title: 'action.create.conditions.list.scenarios.header',
    content: 'action.create.conditions.list.scenarios.content',
    icon: <IconActions />,
    type: ConditionType.Scenarios,
    disabled: false,
  },
  {
    title: 'action.create.conditions.list.location.header',
    content: 'action.create.conditions.list.location.content',
    icon: <IconLocation />,
    type: ConditionType.Location,
    disabled: true,
  },
];

export const conditionIcon: Record<string, ReactNode> = {
  [ArgumentType.ArgumentAsDeviceState]: <IconDevices />,
  [ArgumentType.ArgumentAsTime]: <IconTime />,
  [ArgumentType.ArgumentAsDaysOfWeek]: <IconCalendar />,
  [ArgumentType.ArgumentAsTimeSpan]: <IconCyclical />,
  [ArgumentType.ArgumentAsAstronomicalClockWithOffset]: <IconWeather />,
  [ArgumentType.ArgumentAsConditionInfo]: <IconActions />,
};

export const conditionName: Record<string, string> = {
  [ArgumentType.ArgumentAsTime]: 'action.create.conditions.sources.list.time',
  [ArgumentType.ArgumentAsDaysOfWeek]: 'action.create.conditions.sources.list.daysWeek',
  [ArgumentType.ArgumentAsTimeSpan]: 'action.create.conditions.sources.list.timer',
  [ArgumentType.ArgumentAsAstronomicalClockWithOffset]: 'action.create.conditions.sources.list.astronomical',
  [ArgumentType.ArgumentAsConditionInfo]: 'action.create.conditions.sources.list.scenarioExecution',
};
