import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ArgumentType, ISequence } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { IconDelete, IconEdit } from '../../../../../../components/popup-nav/icons';
import ActionConditionItemDetails from '../../../../../../components/trigger/condition-item-details';
import { ROUTES } from '../../../../../../routes';
import { useGetConditionParams } from '../../../action-details/hooks/get-condition-params';
import { ConditionDetails } from '../../../action-details/types';
import { useExalusCreateActionContext } from '../../context';
import { ConditionSourceType } from '../../types';
import { conditionIcon, conditionName } from '../../utils/action-conditions';
import { getSourceTypeByArgument } from '../../utils/action-sources';

interface ComponentProps {
  seq: ISequence;
  channels: IDeviceChannel[];
  canBeRemoved: boolean;
}

const ConditionItem: React.FC<ComponentProps> = ({ seq, channels, canBeRemoved }) => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { getConditionParams } = useGetConditionParams();
  const { builder, conditionList, setSourceType, setChannels, removeCondition } = useExalusCreateActionContext();

  const deviceArgument = useMemo(() => {
    if (seq.LeftArgumentType === ArgumentType.ArgumentAsDeviceState) {
      return seq.LeftArgument.Argument?.ArgumentAsDeviceState.GetCheckDeviceState();
    }
  }, [seq]);

  const fullChannel = useMemo(
    () =>
      deviceArgument
        ? channels.find(
            (c) =>
              c.GetDevice().Guid === seq.LeftArgument.Argument?.ArgumentAsDeviceState.DeviceGuid &&
              c.Number === deviceArgument?.Channel,
          )
        : null,
    [seq, channels, deviceArgument],
  );

  const handleDelete = () => {
    builder?.RemoveSequenceByGuid(seq.Guid);
    removeCondition(seq.Guid);
  };

  const handleLeft = () => history.push(`${ROUTES.ExalusActionConditionsTypes()}?id=${seq.Guid}`);

  const editConditionScheduleParameters = () => {
    history.push(`${ROUTES.ExalusScheduleSource()}?id=${seq.Guid}&parameters=${true}`);
  };

  const handleRight = useCallback(() => {
    switch (seq.LeftArgumentType) {
      case ArgumentType.ArgumentAsTime: {
        setSourceType(ConditionSourceType.Time);
        editConditionScheduleParameters();
        break;
      }
      case ArgumentType.ArgumentAsDaysOfWeek: {
        setSourceType(ConditionSourceType.DaysOfWeek);
        editConditionScheduleParameters();
        break;
      }
      case ArgumentType.ArgumentAsTimeSpan: {
        setSourceType(ConditionSourceType.Timer);
        editConditionScheduleParameters();
        break;
      }
      case ArgumentType.ArgumentAsAstronomicalClockWithOffset: {
        setSourceType(ConditionSourceType.Astronomical);
        editConditionScheduleParameters();
        break;
      }
      case ArgumentType.ArgumentAsConditionInfo: {
        setSourceType(ConditionSourceType.ScenarioExecution);
        history.push(`${ROUTES.ExalusScenarioSource()}?id=${seq.Guid}&parameters=${true}`);
        break;
      }
      default: {
        const argumentState = seq.LeftArgument.Argument?.ArgumentAsDeviceState;

        if (fullChannel && argumentState) {
          setChannels([fullChannel]);
          const argumentState = seq.LeftArgument.Argument?.ArgumentAsDeviceState;
          const sourceType = getSourceTypeByArgument(argumentState);
          if (sourceType) setSourceType(sourceType);

          history.push(`${ROUTES.ExalusDeviceParameters()}?id=${seq.Guid}&parameters=${true}`);
        }

        break;
      }
    }
  }, [builder, fullChannel, conditionList]);

  const conditionParams: ConditionDetails | null = useMemo(() => getConditionParams(seq, channels), [seq]);

  return (
    <ActionConditionItemDetails
      key={seq.Guid}
      type="list-item"
      icon={conditionParams?.icon || conditionIcon[seq.LeftArgumentType]}
      title={conditionParams?.title ? conditionParams.title : t(conditionName[seq.LeftArgumentType])}
      repeatOption={conditionParams?.description || ''}
      {...(conditionParams?.secondLine
        ? { secondLine: <span className="action-condition__text">{conditionParams.secondLine}</span> }
        : {})}
      options={[
        {
          onClick: handleRight,
          label: t('action.create.conditions.editConditionParameters'),
          icon: <IconEdit />,
        },
        {
          onClick: handleLeft,
          label: t('action.create.conditions.replaceCondition'),
          icon: <IconEdit />,
        },
        ...(canBeRemoved
          ? [
              {
                onClick: handleDelete,
                label: t('remove'),
                icon: <IconDelete />,
              },
            ]
          : []),
      ]}
    />
  );
};

export default ConditionItem;
