import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { IWszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/IWszfBidiConfigService';
import { WindAndBrightnessThresholds } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { ExtaLifeService, GetParamsErrorCode } from 'lavva.exalushome.extalife/build/js/ExtaLife';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { SensorType } from '../../../../enums';
import { getSensorType } from '../../../../utils';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import BrightnessThresholdForm from './brightness-threshold';
import WindThresholdForm from './wind-threshold';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const WszfBidiConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [params, setParams] = useState<WindAndBrightnessThresholds | undefined>(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleGetParamsResponse, DiagnosticButton, DiagnosticResultPopup, firmwareService, UserManualButton } =
    useConfigurationService(device);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: string) => {
    if (channel) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IWszfBidiConfigService>(
        WszfBidiConfigService.ServiceName,
      );

      const data: WindAndBrightnessThresholds | ResponseResult<GetParamsErrorCode> =
        await configService.GetBrightnessAndWindThresholdAsync(device, channel.Number);
      turnOffBackdrop();

      handleGetParamsResponse<WindAndBrightnessThresholds>(data, () => {
        setParams(data as WindAndBrightnessThresholds);
        setOpen(true);
        setConfigType(panel);
      });
    }
  };

  const channelType = useMemo(() => {
    if (channel) {
      return getSensorType(channel);
    }

    return null;
  }, [channel]);

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'WindThreshold' && channel && (
            <WindThresholdForm device={device} windThreshold={params?.WindThreshold} channel={channel} />
          )}
          {configType === 'BrightnessThreshold' && channel && (
            <BrightnessThresholdForm
              device={device}
              brightnessThreshold={params?.BrightnessThreshold}
              channel={channel}
            />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              {channelType === SensorType.Wind && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.WszfBidiParams.WindThreshold')}
                  onClick={() => handleOpen('WindThreshold')}
                />
              )}
              {channelType === SensorType.MeasuredBrightness && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.WszfBidiParams.BrightnessThreshold')}
                  onClick={() => handleOpen('BrightnessThreshold')}
                />
              )}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default WszfBidiConfigForm;
