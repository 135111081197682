import { Dictionary } from 'lodash';
import { VectorMeasurementResponseItem } from '../../../../../data-access/gql-types/graphql';

export const getWeekDate = (startDate: Date, index: number) => {
  const startOfWeek = new Date(startDate);

  const currentDay = new Date(startOfWeek);
  currentDay.setDate(startOfWeek.getDate() + index);

  return currentDay;
};

export const getMonday = (date = new Date()) => {
  const dayOfWeek = date.getDay();
  const diffToMonday = (dayOfWeek + 6) % 7;
  const lastMonday = new Date(date);
  lastMonday.setDate(date.getDate() - diffToMonday);
  return lastMonday;
};

const orderParameterList = [10, 11, 12, 13, 14];

export const sortedParameters = (list: VectorMeasurementResponseItem[]) => {
  return [...list].sort((a, b) => {
    const aIncluded = orderParameterList.includes(a.type);
    const bIncluded = orderParameterList.includes(b.type);

    if (aIncluded && !bIncluded) return -1;
    if (!aIncluded && bIncluded) return 1;
    return 0;
  });
};

export const sortedSummary = (phaseMeasurementsGroupedByType: Dictionary<VectorMeasurementResponseItem[]>) => {
  return Object.entries({ ...phaseMeasurementsGroupedByType }).sort((a, b) => {
    const aIsPriority = orderParameterList.includes(parseInt(a[0]));
    const bIsPriority = orderParameterList.includes(parseInt(b[0]));

    if (aIsPriority && !bIsPriority) return -1;
    if (!aIsPriority && bIsPriority) return 1;
    return 0;
  });
};
