import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../const';
import { OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../types';
import { OptimizerConfigurationHeaterPowerLimit } from '../components/heater-power-limit';
import { OptimizerConfigurationPhaseVoltageThreshold } from '../components/phase-voltage-threshold';
import { OptimizerConfigurationRelays } from '../components/relays';
import { TariffForm } from '../components/tariff';
import { OptimizerConfigurationTemperature } from '../components/temperature';
import { useOptimizerComfort } from '../hooks/use-optimizer-comfort';
import { useOptimizerSetData } from '../hooks/use-optimizer-set-data';
import { ComfortFormBody, initialRelay } from '../types';

interface ComponentProps {
  mode: OptimizerModeInternal;
  channel: ChannelInterface;
  temperature?: number;
}

export const OptimizerConfigurationWorkModeComfort: React.FC<ComponentProps> = ({ mode, channel, temperature }) => {
  const { t: tc } = useTranslation('common');
  const { submitConfiguration } = useOptimizerComfort(channel);
  const form = useForm<ComfortFormBody>({
    defaultValues: {
      tempMin: 0,
      tempMax: 0,
      heaterPowerLimit: 0,
      voltageThreshold1: 0,
      voltageThreshold2: 0,
      voltageThreshold3: 0,
      relayConfig1: { ...initialRelay, index: 1 },
      relayConfig2: { ...initialRelay, index: 2 },
      relayConfig3: { ...initialRelay, index: 3 },
      operator: '',
      tariffName: '',
      wholeWeekendCheaper: false,
      wholeHolidaysCheaper: false,
      ignoreSummerTimeChanges: false,
      hoursRanges: [],
    },
  });
  const { setOptimizerData } = useOptimizerSetData(form.setValue, 'comfort');

  useEffect(() => {
    if (channel) setOptimizerData(channel);
  }, [channel]);

  const onSubmit = form.handleSubmit((values) => {
    submitConfiguration(mode, values);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div
          className={classNames('optimizer-configuration-form__item', {
            disabled: temperature === WRONG_MEASUREMENT_VALUE,
          })}
        >
          <OptimizerConfigurationTemperature minTemperatureExists />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <TariffForm />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationHeaterPowerLimit />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationPhaseVoltageThreshold />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationRelays />
        </div>

        <button className="button m-t-48" type="submit">
          {tc('buttons.save')}
        </button>
      </form>
    </FormProvider>
  );
};
