import { useTranslation } from 'react-i18next';
import { InputSelect } from '../../../../../components';
import { ControlledInputResponse, GenericInputTypeInternal } from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../types';
import { useUpdateInputType } from '../../../hooks/use-update-input-type';

interface ComponentProps {
  input: ControlledInputResponse | undefined;
  channel: ChannelInterface;
  label?: string;
}

const InputType: React.FC<ComponentProps> = ({ input, channel, label }) => {
  const { t } = useTranslation('configuration');
  const { editInputType } = useUpdateInputType({ id: input?.inputId || '', channel });

  const handleUpdateInputType = (value: GenericInputTypeInternal) => {
    if (input?.inputConfig.genericInputType !== value) {
      editInputType(value);
    }
  };

  return (
    <InputSelect
      label={label || ''}
      value={input?.inputConfig.genericInputType}
      onChange={handleUpdateInputType}
      options={[
        { label: t('monoStable'), value: GenericInputTypeInternal.Monostable },
        { label: t('biStable'), value: GenericInputTypeInternal.Bistable },
      ]}
    />
  );
};

export default InputType;
