import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeviceRestart } from '../../../api/modules/device/device.hooks';
import { DialogConfirmation } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { useErrors } from '../../../hooks/use-errors';
import { toastSuccess } from '../../../utils/toast';

interface PropsInterface {
  deviceId: string;
}

const DeviceRestart: React.FC<PropsInterface> = ({ deviceId }) => {
  const { t } = useTranslation('device-settings');
  const { t: tc } = useTranslation('common');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { handleLavvaResolve } = useErrors();
  const { mutate } = useDeviceRestart();

  const handleReset = () => {
    mutate(
      { deviceId },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          handleLavvaResolve(data.publishStatus, () => {
            toastSuccess({ content: t('restartDevice.success') });
          });
        },
      },
    );
  };

  return (
    <>
      <ArrowButton title={t('restartDevice.button')} onClick={() => setShowDialog(true)} small className="m-b-24" />
      <DialogConfirmation
        show={showDialog}
        setShow={setShowDialog}
        header={t('restartDevice.popupHeader')}
        content={t('restartDevice.popupContent')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleReset}
        secondaryBtnAction={() => setShowDialog(false)}
        timer
      />
    </>
  );
};

export default DeviceRestart;
