import React, { useCallback, useEffect, useState } from 'react';
import { ControlWrapper, IconInitiate, InitiateButton, SubmitButton } from '../../../../../components';
import {
  ChannelActionParameterInput,
  TriggerActionRequestInput,
  TriggerActionType,
} from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../types';
import { useTriggerFormContext } from '../../../context';
import { useOnOffControl } from '../helpers/use-control';

interface PropsInterface {
  setAction: (value: TriggerActionRequestInput) => void;
  channel: ChannelInterface;
  defaultParameters: TriggerActionRequestInput | undefined;
}

const LightSetup: React.FC<PropsInterface> = ({ setAction, channel, defaultParameters }) => {
  const [ChannelParameters, setChannelParameters] = useState<{ [key: string]: ChannelActionParameterInput }>({});
  const [statusOn, setStatusOn] = useState<boolean>(false);
  const { setOff, setOn } = useOnOffControl(ChannelParameters);
  const { updateAction } = useTriggerFormContext();

  useEffect(() => {
    if (defaultParameters) {
      switch (defaultParameters.actionType) {
        case TriggerActionType.SetLightState: {
          setStatusOn(defaultParameters?.booleanValue ?? false);
          setChannelParameters(defaultParameters?.booleanValue ? setOn() : setOff());
          break;
        }
      }
    }
  }, [setStatusOn]);

  const handleControlOn = useCallback(
    (value: boolean) => {
      setChannelParameters(value ? setOn() : setOff());
      setStatusOn(value);
    },
    [setOn, setStatusOn],
  );

  const onSubmit = useCallback(() => {
    const data = {
      id: channel.id,
      actionType: TriggerActionType.SetLightState,
      booleanValue: statusOn,
    };

    updateAction(channel.id, data);
    setAction(data);
  }, [ChannelParameters]);

  return (
    <>
      <ControlWrapper>
        <InitiateButton isOn={statusOn} onClickControl={handleControlOn}>
          <IconInitiate />
        </InitiateButton>
      </ControlWrapper>
      <SubmitButton onClick={onSubmit} />
    </>
  );
};

export default LightSetup;
