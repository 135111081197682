import { RelayConfig, TimeZoneKindInternal, VoltageThreshold } from '../../../data-access/gql-types/graphql';

export interface OptimizerSetModeBody {
  deviceId: string;
  channelId: string;
  mode: OptimizerMode;
}

export interface OptimizerSetTestModeLoadBody {
  deviceId: string;
  channelId: string;
  scr: number[];
}

export enum OptimizerMode {
  Unknown = 0,
  TestMode = 1,
  Eco = 2,
  Comfort = 3,
  FullSelfConsumption = 4,
  SelfConsumptionWithPowerLimit = 5,
  SelfConsumptionWithRelays = 6,
}

export interface OptimizerErrorGlobal {
  description: string;
  errorCode: string;
}

export interface OptimizerError {
  code: string;
  message: string;
}

export interface WorkingHours {
  from: string;
  to: string;
}

export interface OptimizerSetConfigBody {
  deviceId: string;
  channelId: string;
  eco?: OptimizerEcoConfigBody;
  comfort?: OptimizerComfortConfigBody;
  fullSelfConsumption?: OptimizerFullConsumptionConfigBody;
  selfConsumptionWithPowerLimit?: OptimizerLimitConsumptionConfigBody;
  selfConsumptionWithRelays?: OptimizerRelaysConsumptionConfigBody;
}

export interface OptimizerEcoConfigBody {
  tempMax: number;
  voltageThreshold: Pick<VoltageThreshold, 'index' | 'value'>[];
  relaysConfig: Omit<RelayConfig, '__typename'>[];
}

interface BaseConsumptionConfigBody {
  tempMax: number;
  tempMin: number;
  heaterPowerLimit: number;
  voltageThreshold: Pick<VoltageThreshold, 'index' | 'value'>[];
  relaysConfig: Omit<RelayConfig, '__typename'>[];
}

interface TariffBody {
  hoursRanges: HoursRangeConfig[];
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
  kind: number
}

export interface HoursRangeConfig {
  from: number;
  to: number;
  timeZoneKind: number;
}

interface TariffConfig {
  tariff: TariffBody;
}

interface TariffOptionalConfig {
  tariff?: TariffBody;
}

export type OptimizerComfortConfigBody = BaseConsumptionConfigBody & TariffConfig;

export interface OptimizerFullConsumptionConfigBody extends BaseConsumptionConfigBody, TariffOptionalConfig {
  tempSelfConsumption: number;
  tempMinEnabled: boolean;
  workingHours: WorkingHours;
}

export interface OptimizerLimitConsumptionConfigBody extends BaseConsumptionConfigBody, TariffOptionalConfig {
  tempSelfConsumption: number;
  tempMinEnabled: boolean;
  powerLimit: number;
  workingHours: WorkingHours;
}

export interface OptimizerRelaysConsumptionConfigBody {
  relaysConfig: Omit<RelayConfig, '__typename'>[];
  workingHours: WorkingHours;
}

export interface TariffHoursRange {
  from: number;
  to: number;
  timeZoneKind: TimeZoneKindInternal;
}

export interface TariffData {
  name: string;
  hoursRanges: TariffHoursRange[];
  wholeWeekendCheaper: boolean;
  wholeHolidaysCheaper: boolean;
  ignoreSummerTimeChanges: boolean;
}

export interface TariffItem {
  operator: string;
  tariffs: TariffData[];
}
