import React, { useMemo } from 'react';
import ActionCreateTasks from '../../../../action-create/components/task-list';
import TaskItem from '../components/task-item';
import { useExalusCreateActionContext } from '../context';
import { useActionTasks } from '../hooks/use-action-tasks';
import { Task } from '../types';

const ExalusActionCreateTasks: React.FC = () => {
  const { taskList } = useExalusCreateActionContext();
  const { addNewTask, handleSubmit } = useActionTasks();

  const tasks = useMemo(() => taskList.map((task: Task) => <TaskItem key={task.id} task={task} />), [taskList]);

  return (
    <ActionCreateTasks
      handleSubmit={handleSubmit}
      addNewTask={addNewTask}
      tasks={tasks}
      taskListLength={taskList.length}
    />
  );
};

export default ExalusActionCreateTasks;
