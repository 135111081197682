import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { FloodSensorState, FloodSensorStates } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { InputSelect, SubmitButton } from '../../../../../../../../components';
import { ConditionTypeSelect } from '../../../../components/condition-type-select';
import { InputString } from '../../../../components/input-string';
import { TriggerMethodSelect } from '../../../../components/trigger-method-select';
import { useExalusCreateActionContext } from '../../../../context';
import { useConditionEdit } from '../../../../hooks/use-condition-edit';
import { TriggerMethodType } from '../../../../types';
import { useActionParameterOptions } from '../../../../utils/use-action-parameter-options';

export const FloodParameters: React.FC = () => {
  const { t } = useTranslation('action');
  const { channels } = useExalusCreateActionContext();
  const { floodOptions } = useActionParameterOptions();
  const [floodOption, setFloodOption] = useState<FloodSensorStates>(FloodSensorStates.FloodDetected);
  const {
    triggerMethod,
    atMeetDisabled,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    setConditionType,
    setTimeSec,
    setTriggerMethod,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  } = useConditionEdit();

  useEffect(() => {
    const argument = seq?.LeftArgument.Argument?.ArgumentAsDeviceState;

    if (argument && onlyParametersEdit) {
      const state = argument.GetCheckDeviceState<FloodSensorState>();
      setFloodOption(state.State);
    }
  }, [seq, onlyParametersEdit]);

  const onChange = (option: FloodSensorStates) => {
    setFloodOption(option);
  };

  const handleSubmit = () => {
    if (channels.length) {
      const deviceArgument = createDeviceArgumentWithComparison();
      deviceArgument.Type = DeviceResponseType.FloodSensorState;
      deviceArgument.DeviceGuid = channels[0].GetDevice().Guid;
      const sensorState = new FloodSensorState();
      sensorState.Channel = channels[0].Number;
      sensorState.State = floodOption;
      deviceArgument.SetCheckDeviceState<FloodSensorState>(sensorState);

      saveConditionParams(deviceArgument);
    }
  };

  return (
    <>
      {!atMeetDisabled ? (
        <TriggerMethodSelect triggerMethod={triggerMethod} setTriggerMethod={setTriggerMethod} />
      ) : null}
      {triggerMethod === TriggerMethodType.WhenChangeOn && !atMeetDisabled && (
        <InputString
          value={timeSec}
          setValue={setTimeSec}
          label={t('action.create.conditions.sources.device.requiredTime')}
          inputType="number"
          min={0}
        />
      )}
      <ConditionTypeSelect
        conditionType={conditionType}
        setConditionType={setConditionType}
        allow={[ConditionsTypes.Equal, ConditionsTypes.NotEqueal]}
      />
      <InputSelect
        options={floodOptions}
        value={floodOption}
        onChange={onChange}
        label={t('action.create.conditions.sources.device.flood')}
      />
      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
