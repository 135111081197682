import { useTranslation } from 'react-i18next';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import {
  CoverDirectionBatchChannel,
  CoverPositionBatchChannel,
  TypeOfActionType,
} from '../../api/modules/action/action.types';
import { useBlindSetDirection, useBlindSetPosition } from '../../api/modules/blind/blind.hooks';
import { ChannelTypeInternal } from '../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../types';
import { toastInfo } from '../../utils/toast';

interface UseCoverActionInterface {
  handleAction: (value: number | string) => void;
}

export enum Direction {
  unknown = 0,
  stop = 1,
  open = 2,
  close = 3,
}

export const useCover = (channels: ChannelInterface[], action?: boolean): UseCoverActionInterface => {
  const { t: ta } = useTranslation('action');
  const setBlindDirection = useBlindSetDirection();
  const setBlindPosition = useBlindSetPosition();
  const actionBatch = useActionBatch();

  const handleAction = (value: string | number) => {
    if (!channels.length) {
      toastInfo({ content: ta('trigger.validationErrors.deviceNotFound') });
      return;
    }

    if (!action) {
      channels.forEach((channel) => {
        if (channel.data.type === ChannelTypeInternal.Blind) {
          if (typeof value === 'string') {
            setBlindDirection.mutate({
              channelId: channel.id,
              deviceId: channel.deviceId,
              direction: Direction[value],
            });
          } else {
            setBlindPosition.mutate({ channelId: channel.id, deviceId: channel.deviceId, position: value });
          }
        }
      });
    } else {
      if (typeof value === 'string') {
        actionBatch.mutate({
          controls: channels.map((channel) => ({
            channelId: channel.id,
            deviceId: channel.deviceId,
            direction: Direction[value],
            $type: TypeOfActionType.BatchBlindSetDirectionRequest,
          })) as CoverDirectionBatchChannel[],
        });
      } else {
        actionBatch.mutate({
          controls: channels.map((channel) => ({
            channelId: channel.id,
            deviceId: channel.deviceId,
            position: value,
            $type: TypeOfActionType.BatchBlindSetPositionRequest,
          })) as CoverPositionBatchChannel[],
        });
      }
    }
  };

  return {
    handleAction,
  };
};
