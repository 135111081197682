import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { ChannelTypeInternal, OptimizerConfigValidation, UserChannel } from '../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../types';
import { ChannelOptimizerInterface } from '../../types/channel/optimizer';
import { parseChannel } from '../../utils/channels/helpers';
import { useDevicesAndChannels } from '../devices-and-channels';

interface ChannelDetailsProviderInterface {
  channel: ChannelInterface | undefined;
  setChannelDetails: (userChannel: UserChannel) => void;
  optimizerConfigValidation: OptimizerConfigValidation | undefined;
  setOptimizerConfigValidation: Dispatch<SetStateAction<OptimizerConfigValidation | undefined>>;
}

const initialState: ChannelDetailsProviderInterface = {
  channel: undefined,
  setChannelDetails: () => null,
  optimizerConfigValidation: undefined,
  setOptimizerConfigValidation: () => null,
};

export const ChannelDetailsContext = createContext<ChannelDetailsProviderInterface>(initialState);

export const useChannelDetailsContext = (): ChannelDetailsProviderInterface => useContext(ChannelDetailsContext);

export const ChannelDetailsContextProvider: React.FC = ({ children }) => {
  const { setChannelList } = useDevicesAndChannels();
  const [channel, setChannel] = useState<ChannelInterface | undefined>(undefined);
  const [optimizerConfigValidation, setOptimizerConfigValidation] = useState<OptimizerConfigValidation | undefined>(
    undefined,
  );

  const setChannelDetails = (userChannel: UserChannel) => {
    const ch = parseChannel(userChannel);
    if (ch) {
      setChannel(ch);

      setChannelList((prev) => {
        const temp = [...prev];
        const index = temp.findIndex((x) => x.id === ch.id);

        if (index !== -1) temp[index] = ch;
        return [...temp];
      });

      if (ch.data.type === ChannelTypeInternal.Optimizer) {
        setOptimizerConfigValidation((ch.data as ChannelOptimizerInterface).payload?.configValidation);
      }
    }
  };

  const values: ChannelDetailsProviderInterface = {
    channel,
    optimizerConfigValidation,
    setChannelDetails,
    setOptimizerConfigValidation,
  };

  return <ChannelDetailsContext.Provider value={values}>{children}</ChannelDetailsContext.Provider>;
};
