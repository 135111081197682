import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedPhase } from '../../../modules/channel-details/measurement/types';
import { ChartDataItem } from '../../../modules/channel-details/meter/analysis';
import { MeasurementRange } from '../../../types';
import { IconSchedule } from '../../icons';
import './index.scss';

interface PropsInterface {
  summaryData: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
  selectedPhases: SelectedPhase[] | undefined;
  hidePhases?: boolean;
}

interface SummaryInterface {
  label: number;
  max: number;
  average?: number;
  sum: number;
  count: number;
}

export const Summary: React.FC<PropsInterface> = ({
  summaryData,
  activeMeasurementRange,
  selectedPhases,
  hidePhases,
}) => {
  const { t } = useTranslation('channel-details');

  const getFullLabel = (type: number) => {
    return !type ? t('sumOfPhases') : `${t('phase')} ${type}`;
  };

  const data = useMemo(() => {
    return summaryData.filter((x) => x.values.length && x.values.find((y) => y.value));
  }, [summaryData]);

  const summarySectionName = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return t('summary.daily');
      case MeasurementRange.WeekInDays:
        return t('summary.weekly');
      case MeasurementRange.MonthInDays:
        return t('summary.monthly');
      case MeasurementRange.YearInMonths:
        return t('summary.yearly');
      case MeasurementRange.TenYearsInYears:
        return t('summary.tenYears');
      default:
        return '';
    }
  }, [activeMeasurementRange]);

  const sections = useMemo(() => {
    if (selectedPhases === undefined) return;

    const selectedPhasesKeys = selectedPhases.map((phase) => phase.value);

    const values = data
      .map((data) =>
        selectedPhasesKeys.some((el) => el === 0)
          ? data.values
          : data.values.filter((el) => selectedPhasesKeys.includes(el.type)),
      )
      .reduce((all, singleValue) => all.concat(singleValue), []);

    return values
      .reduce((prev: SummaryInterface[], { type, value }) => {
        const existIndexElement = prev.findIndex((el) => el.label === type);

        if (existIndexElement === -1) {
          return [
            ...prev,
            {
              label: type,
              sum: value,
              max: Math.max(value, 0),
              count: 1,
            },
          ];
        }

        const element = prev[existIndexElement];
        prev[existIndexElement] = {
          label: type,
          sum: element.sum + value,
          max: Math.max(element.max, value),
          count: element.count + 1,
        };

        return prev;
      }, [])
      .map(({ count, ...rest }) => ({
        ...rest,
        average: rest.sum / count,
        label: getFullLabel(rest.label),
      }));
  }, [data, selectedPhases]);

  if (!sections || sections?.length === 0) return null;

  return (
    <div className="summary max-width-desktop">
      <div className="summary__caption">
        <IconSchedule />
        <span className="summary__caption-text"> {summarySectionName}</span>
      </div>
      {sections.map((element) => (
        <div key={element.label} className="summary__section summary__section--border">
          {!hidePhases && <span className="summary__section-label">{t(`${element.label}`)}</span>}
          <div className="summary__section-row">
            <span>{t('summary.sum')}</span>
            <span className="summary__section-value">
              {' '}
              {element.sum.toFixed(2)} {t('kw')}
            </span>
          </div>
          <div className="summary__section-row">
            <span>{t('summary.average')}</span>
            <span className="summary__section-value">
              {(element.sum / data.length).toFixed(2)} {t('kw')}
            </span>
          </div>
          <div className="summary__section-row">
            <span>{t('summary.max')}</span>
            <span className="summary__section-value">
              {element.max.toFixed(2)} {t('kw')}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
