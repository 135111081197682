import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../../../types';
import { OptimizerConfigurationRelays } from '../components/relays';
import { WorkingHours } from '../components/working-hours';
import { useOptimizerRelaysConsumption } from '../hooks/use-optimizer-relays-consumption';
import { useOptimizerSetData } from '../hooks/use-optimizer-set-data';
import { RelaysConsumptionFormBody, initialRelay } from '../types';

interface ComponentProps {
  mode: OptimizerModeInternal;
  channel: ChannelInterface;
}

export const OptimizerConfigurationWorkModeAutoConsumptionRelays: React.FC<ComponentProps> = ({ mode, channel }) => {
  const { t: tc } = useTranslation('common');
  const { submitConfiguration } = useOptimizerRelaysConsumption(channel);
  const form = useForm<RelaysConsumptionFormBody>({
    defaultValues: {
      relayConfig1: { ...initialRelay, index: 1 },
      relayConfig2: { ...initialRelay, index: 2 },
      relayConfig3: { ...initialRelay, index: 3 },
      relayConfig4: { ...initialRelay, index: 4 },
      from: '00:00',
      to: '00:00',
    },
  });
  const { setOptimizerData } = useOptimizerSetData(form.setValue, 'selfConsumptionWithRelays');

  useEffect(() => {
    if (channel) setOptimizerData(channel);
  }, [channel]);

  const onSubmit = form.handleSubmit((values) => {
    submitConfiguration(mode, values);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div className="optimizer-configuration-form__item">
          <WorkingHours />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationRelays thresholdPercent={false} sumExists />
        </div>

        <button className="button m-t-48" type="submit">
          {tc('buttons.save')}
        </button>
      </form>
    </FormProvider>
  );
};
