import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface useCurrentLanguageInterface {
  currentLanguage: string;
}

export const useCurrentLanguage = (): useCurrentLanguageInterface => {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(() => i18n.language, [i18n]);

  return { currentLanguage };
};
