import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  ComfortPositionParameters,
  InputOutputBlindParameters,
  StateOfTurnOnBlindDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { BlindConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigService';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { Srp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp22ConfigService';
import { Srp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/Srp30ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { ROUTES } from '../../../../../../routes';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import AutoCalibration from '../common/auto-calibration/button';
import ComfortForm from './comfort';
import InputsMode from './inputs-mode';
import TurnOnBehaviourForm from './turn-behaviour';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const SrpConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const history = useHistory();
  const { t } = useTranslation('device-info');
  const [open, setOpen] = useState<boolean>(false);
  const [configType, setConfigType] = useState<string>('');
  const [params, setParams] = useState<
    ComfortPositionParameters | InputOutputBlindParameters | StateOfTurnOnBlindDeviceParameters | undefined
  >(undefined);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    configService: serviceName,
    handleGetBlindParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: string) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISrp30ConfigService>(
      Srp30ConfigService.ServiceName,
    );

    switch (panel) {
      case 'ComfortPosition': {
        if (channel) {
          const data: ComfortPositionParameters | ResponseResult<BlindConfigurationErrorCode> =
            await configService.GetComfortPositionsAsync(device, channel.Number);

          handleGetBlindParamsResponse<ComfortPositionParameters>(data, () => {
            setParams(data as ComfortPositionParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'InputsMode': {
        if (channel) {
          const data: InputOutputBlindParameters | ResponseResult<BlindConfigurationErrorCode> =
            await configService.GetBlindInputOutputParamAsync(device, channel.Number);

          handleGetBlindParamsResponse<InputOutputBlindParameters>(data, () => {
            setParams(data as InputOutputBlindParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      case 'TurnOnBehaviour': {
        if (channel) {
          const data: StateOfTurnOnBlindDeviceParameters | ResponseResult<BlindConfigurationErrorCode> =
            await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetBlindParamsResponse<StateOfTurnOnBlindDeviceParameters>(data, () => {
            setParams(data as StateOfTurnOnBlindDeviceParameters);
            setOpen(true);
            setConfigType(panel);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const goToManualCalibration = () => {
    if (channel) {
      history.push(ROUTES.Srp22Calibration(channel.ChannelId));
    }
  };

  const handleOpen = (panel: string) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfigType('');
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>
          {configType === 'ComfortPosition' && channel && (
            <ComfortForm device={device} channel={channel} comfort={params as ComfortPositionParameters} />
          )}
          {configType === 'InputsMode' && <InputsMode device={device} inputs={params as InputOutputBlindParameters} />}
          {configType === 'TurnOnBehaviour' && (
            <TurnOnBehaviourForm device={device} turnOnBehaviour={params as StateOfTurnOnBlindDeviceParameters} />
          )}
        </ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              {serviceName === Srp30ConfigService.ServiceName && <AutoCalibration channel={channel} />}
              {serviceName === Srp22ConfigService.ServiceName && (
                <ArrowButton title={t('exalus.calibration')} onClick={goToManualCalibration} className="m-b-24" />
              )}
              <ArrowButton
                {...(serviceName === Srp30ConfigService.ServiceName || serviceName === Srp22ConfigService.ServiceName
                  ? { className: 'm-b-24' }
                  : {})}
                title={t('exalus.params.SrpParams.ComfortPosition')}
                onClick={() => handleOpen('ComfortPosition')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SrpParams.InputsMode')}
                onClick={() => handleOpen('InputsMode')}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.SrpParams.TurnOnBehaviour')}
                onClick={() => handleOpen('TurnOnBehaviour')}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default SrpConfigForm;
