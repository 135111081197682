import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChannelBox, CustomBackdrop } from '../../../../components';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';
import { ChannelLightInterface } from '../../../../types';
import { useConfiguration } from '../../hooks/use-configuration';
import { InfoPageType } from '../../types';
import Info from '../components/info';
import InputType from '../components/input-type';
import ReturnBehaviorSelect from '../components/return-behavior-select';
import MeasurementsConfiguration from './measurements';

interface ComponentProps {
  openInfoPage: (type: InfoPageType) => void;
}

const LightConfiguration: React.FC<ComponentProps> = ({ openInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel } = useDevicesAndChannels({ channelId });
  const { swapInDevice, editLoading } = useConfiguration({ channel });

  const input = useMemo(() => {
    return (channel?.data as ChannelLightInterface)?.controlledInput;
  }, [(channel?.data as ChannelLightInterface)?.controlledInput]);

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => openInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <hr />

      {input && (
        <>
          <Info title={t('inService')} onClick={() => openInfoPage(InfoPageType.INSERVICE)} />
          <div className="row_container max-width-desktop">
            <button className="button button--secondary" onClick={swapInDevice}>
              {t('reverseInputs')}
            </button>
          </div>
          <hr />
          <Info title={t('inputType')} onClick={() => openInfoPage(InfoPageType.INPUT_TYPE)} />
          <InputType input={input} channel={channel} />
          <hr />
        </>
      )}
      <ReturnBehaviorSelect channel={channel} />
      {(channel.data as ChannelLightInterface).measuredBy?.meterChannelId && (
        <>
          <hr />
          <MeasurementsConfiguration
            meterChannelId={(channel.data as ChannelLightInterface).measuredBy?.meterChannelId}
          />
        </>
      )}
      {editLoading && <CustomBackdrop loading />}
    </>
  );
};

export default LightConfiguration;
