import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { leadingZero } from '../../utils/helpers';
import { Dialog } from '../dialog/base';
import './index.scss';

interface PropsInterface {
  header?: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onSave: (data: string) => void;
  time: string;
  showHours?: boolean;
  showSeconds?: boolean;
  disableHours?: boolean;
  disableMinutes?: boolean;
  maxMinutes?: number;
  maxHours?: number;
  minSeconds?: number;
}

export const DialogTimePicker: React.FC<PropsInterface> = ({
  header,
  open,
  setOpen,
  onSave,
  time,
  showSeconds,
  disableHours,
  disableMinutes,
  maxMinutes = 59,
  maxHours = 23,
  minSeconds = 0,
}) => {
  const { t } = useTranslation('common');
  const [selectedHour, setSelectedHour] = useState<string>(time.split(':')[0]);
  const [selectedMinute, setSelectedMinute] = useState<string>(time.split(':')[1]);
  const [selectedSeconds, setSelectedSeconds] = useState<string>(minSeconds.toString());

  useEffect(() => {
    setSelectedHour(time.split(':')[0]);
    setSelectedMinute(time.split(':')[1]);

    if (showSeconds) {
      setSelectedSeconds(time.split(':')[2]);
    }
  }, [open, showSeconds, time]);

  const hours = Array.from(Array(maxHours + 1).keys());
  const minutes = Array.from(Array(maxMinutes + 1).keys());
  const seconds = useMemo(
    () => Array.from({ length: 60 - minSeconds }, (_, index) => minSeconds + index),
    [minSeconds],
  );

  const secondsSlide = useMemo(() => {
    return seconds.findIndex((value) => value === parseInt(selectedSeconds));
  }, [seconds, selectedSeconds]);

  const onClose = () => setOpen(false);

  const onSubmit = () => {
    if (!showSeconds) onSave([selectedHour, selectedMinute].join(':'));
    else onSave([selectedHour, selectedMinute, selectedSeconds].join(':'));
    onClose();
  };

  return (
    <Dialog setShow={setOpen} show={open} className="dialog--full-width">
      <div className="dialog-time-picker">
        <div className="dialog-time-picker__header">
          {header && <h3 className="dialog-time-picker__heading">{header}</h3>}
        </div>
        <div className="dialog-time-picker__time-wrapper">
          <div className="time-picker-wrapper">
            {!disableHours && (
              <>
                <div className="picker">
                  <Swiper
                    initialSlide={Number(selectedHour)}
                    slidesPerView={3}
                    direction="vertical"
                    grabCursor
                    centeredSlides
                    loop
                    slideToClickedSlide
                    onSlideChange={(e) => {
                      setSelectedHour(leadingZero(e.realIndex));
                    }}
                  >
                    {hours.map((hour) => {
                      return (
                        <SwiperSlide key={hour}>
                          <div className="time">{('' + hour).padStart(2, '0')}</div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <div className="picker-divider">:</div>
              </>
            )}
            {!disableMinutes && (
              <div className="picker">
                <Swiper
                  initialSlide={Number(selectedMinute)}
                  slidesPerView={3}
                  direction="vertical"
                  grabCursor
                  centeredSlides
                  loop
                  slideToClickedSlide
                  onSlideChange={(e) => {
                    setSelectedMinute(leadingZero(e.realIndex));
                  }}
                >
                  {minutes.map((minute) => {
                    return (
                      <SwiperSlide key={minute}>
                        <div className="time">{('' + minute).padStart(2, '0')}</div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}

            {showSeconds && (
              <>
                <div className="picker-divider">:</div>
                <div className="picker">
                  <Swiper
                    initialSlide={secondsSlide}
                    slidesPerView={3}
                    direction="vertical"
                    grabCursor
                    centeredSlides
                    loop
                    slideToClickedSlide
                    onSlideChange={(e) => {
                      setSelectedSeconds(leadingZero(seconds[e.realIndex]));
                    }}
                  >
                    {seconds.map((second) => {
                      return (
                        <SwiperSlide key={second}>
                          <div className="time">{('' + second).padStart(2, '0')}</div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="dialog-time-picker__buttons">
          <button className="dialog-time-picker__button" onClick={onClose}>
            {t('buttons.cancel')}
          </button>
          <button onClick={onSubmit} className="dialog-time-picker__button dialog-time-picker__button--active">
            {t('buttons.save')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
