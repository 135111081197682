import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  DeviceResponseType,
  DeviceTaskType,
  IDeviceTaskTypeInfo,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { ROUTES } from '../../../../../routes';
import { ActionType } from '../../../../action-create/types';
import { ChannelType } from '../../../enums';
import { canOnlyChannelReturnAny } from '../../../utils';
import { GroupedChannel } from '../../channel-list/types';
import { useExalusCreateActionContext } from '../context';
import { ConditionSourceType, ConditionType } from '../types';

export const useActionConditions = () => {
  const history = useHistory();
  const { actionId } = useParams<{ actionId: string }>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { actionType, conditionType, sourceType, conditionList, builder, setConditionType, setSourceType } =
    useExalusCreateActionContext();

  useEffect(() => {
    if (actionType === ActionType.Undefined) history.replace(ROUTES.ActionList());
  }, []);

  const addNewCondition = () => history.push(ROUTES.ExalusActionConditionsTypes());

  const selectConditionType = (condition: ConditionType) => {
    setConditionType(condition);
    const id = new URLSearchParams(window.location.search).get('id');
    history.push(`${ROUTES.ExalusActionSources()}${id ? `?id=${id}` : ''}`);
  };

  const selectConditionSourceType = (source: ConditionSourceType) => {
    setSourceType(source);
    const id = new URLSearchParams(window.location.search).get('id');

    switch (conditionType) {
      case ConditionType.Devices:
        history.push(`${ROUTES.ExalusDeviceSource()}${id ? `?id=${id}` : ''}`);
        break;
      case ConditionType.Schedule:
        history.push(`${ROUTES.ExalusScheduleSource()}${id ? `?id=${id}` : ''}`);
        break;
      case ConditionType.Scenarios:
        history.push(`${ROUTES.ExalusScenarioSource()}${id ? `?id=${id}` : ''}`);
        break;
    }
  };

  const getSourceChannels = (groupedChannels: GroupedChannel[]) => {
    const remotes = groupedChannels.find((x) => x.type === ChannelType.Remote)?.channels || [];
    const all = groupedChannels.flatMap((x) => x.channels);

    switch (sourceType) {
      case ConditionSourceType.Remotes: {
        return remotes?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.RemoteButtonState]));
      }
      case ConditionSourceType.BlindRemotes: {
        return remotes?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.BlindRemoteButtonState]));
      }
      case ConditionSourceType.FacadeRemotes: {
        return remotes?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.FacadeRemoteButtonState]));
      }
      case ConditionSourceType.Bells: {
        return remotes?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.DoorBellState]));
      }
      case ConditionSourceType.Temperature: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.MeasuredTemperature]));
      }
      case ConditionSourceType.Humidity: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.HumiditySensorState]));
      }
      case ConditionSourceType.Brightness: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.MeasuredBrightness]));
      }
      case ConditionSourceType.Pressure: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.PressureSensorState]));
      }
      case ConditionSourceType.FloodSensor: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.FloodSensorState]));
      }
      case ConditionSourceType.WindThreshold: {
        return all?.filter(
          (x) =>
            canOnlyChannelReturnAny(x, [DeviceResponseType.WindThreshold]) &&
            !(
              x?.AvailableTaskTypes?.any((a: IDeviceTaskTypeInfo) => a.Type === DeviceTaskType.SetFacadePosition) ||
              x
                ?.GetDevice()
                .AvailableTaskTypes?.any((a: IDeviceTaskTypeInfo) => a.Type === DeviceTaskType.SetFacadePosition)
            ),
        );
      }
      case ConditionSourceType.WindSpeed: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.MeasuredWindSpeed]));
      }
      case ConditionSourceType.Energy: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.MeasuredEnergy]));
      }
      case ConditionSourceType.ReedSensor: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.ReedState]));
      }
      case ConditionSourceType.MovementSensor: {
        return all?.filter((x) => canOnlyChannelReturnAny(x, [DeviceResponseType.MovementSensorState]));
      }
      case ConditionSourceType.Gate: {
        return all?.filter((x) =>
          canOnlyChannelReturnAny(x, [DeviceResponseType.GatePosition, DeviceResponseType.GatewayPosition]),
        );
      }

      default: {
        return [];
      }
    }
  };

  const handleNextStep = () => {
    if (!conditionList.length) setDialogOpen(true);
    else {
      builder?.Commit();
      history.push(!actionId ? ROUTES.ActionCreateTasks() : ROUTES.ExalusActionEditTasks(actionId));
    }
  };

  const handleDialogClose = () => setDialogOpen(false);

  return {
    dialogOpen,
    addNewCondition,
    selectConditionType,
    selectConditionSourceType,
    getSourceChannels,
    handleNextStep,
    handleDialogClose,
  };
};
