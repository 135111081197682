import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateBox, Page } from '../../components';
import { UserGroup } from '../../data-access/gql-types/graphql';
import { useApi } from '../../hooks';
import { ROUTES } from '../../routes';
import { GroupSortBody, GroupSortHeader } from './components';

const GroupSort: React.FC = () => {
  const { t } = useTranslation('groups');
  const { groups } = useApi();
  const [sortableGroups, setSortableGroups] = useState<UserGroup[]>([]);

  useEffect(() => setSortableGroups(groups), [groups.length]);

  return (
    <Page isStickyHeader header={<GroupSortHeader />}>
      {sortableGroups.length ? (
        <GroupSortBody sortableGroups={sortableGroups} setSortableGroups={setSortableGroups} />
      ) : (
        <EmptyStateBox
          header={t('groups')}
          content={t('descriptionEmptyList')}
          btnText={t('create')}
          linkTo={ROUTES.GroupCreate()}
        />
      )}
    </Page>
  );
};

export default GroupSort;
