import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useGetInstallationBroker } from '../../../api/modules/installation/installation.hooks';
import {
  ExalusInstallationPayload,
  InstallationAccessType,
  IntegrationType,
  UserInstallation,
} from '../../../data-access/gql-types/graphql';
import { useInstallation, useProfile, useBackdropContext } from '../../../hooks';
import { useRefetchData } from '../../../hooks/refresh-data';
import { ControllerQrCode } from '../../../modules/exalus/components/controller-qr-code';
import { useExalusContext } from '../../../modules/exalus/context';
import { useExalusServicesContext } from '../../../modules/exalus/context/services';
import { useExalusConnection } from '../../../modules/exalus/hooks';
import { ExalusIntegrationForm } from '../../../modules/exalus/pages/integration-create/types';
import { getExalusInstallationCredentials } from '../../../modules/exalus/utils';
import { useMember } from '../../../modules/installation-share/hooks/use-member';
import { useShareRedirect } from '../../../modules/installation-share/hooks/use-share-redirect';
import { ROUTES } from '../../../routes';
import { InstallationIconTypeEnum } from '../../../types';
import { isLocalApp } from '../../../utils/helpers/local-app';
import * as storage from '../../../utils/storage/lavva';
import { DialogConfirmation } from '../../dialog';
import { InstallationIcon } from '../../edit-installation-icon';
import { IconSortHandle, IconStatus } from '../../icons';
import { PopUpNav } from '../../popup-nav';
import { IconDelete, IconExit, IconSettings, IconShare } from '../../popup-nav/icons';
import './index.scss';

interface InstallationItemProps {
  installation: UserInstallation;
  isDraggable?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
}

export const InstallationItem: React.FC<InstallationItemProps> = ({
  installation,
  isDraggable,
  listeners,
  attributes,
  setNodeRef,
  style,
}) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const history = useHistory();
  const { user } = useProfile();
  const { selectedInstallationId, setSelectedInstallationId, hardRedirect, selectInstallation } = useInstallation();
  const { deleteUserFromInstallation } = useMember();
  const [signOffPopup, setSignOffPopup] = useState<boolean>(false);
  const [exalusQrCode, setExalusQrCode] = useState<ExalusIntegrationForm>({ serialNumber: '', pin: '' });
  const { setSelectedExalusCredentials } = useExalusContext();
  const { connectionApi } = useExalusServicesContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchInstallations } = useRefetchData();
  const { connect } = useExalusConnection();
  const { getBroker } = useGetInstallationBroker();
  const { shareInstallation } = useShareRedirect();

  const redirectTo = (redirectRoute: string, exalus?: boolean) => {
    if (!exalus) history.push(redirectRoute);
    else {
      hardRedirect ? (window.location.href = redirectRoute) : history.push(redirectRoute);
    }
  };

  const onInstallationClick = () => {
    onClick(ROUTES.Root());
  };

  const openExalusControllerSettings = () => onClick(ROUTES.ExalusControllerSettings());
  const openInstallationSettings = async () => {
    if (installation?.integrationType === IntegrationType.Lavva) {
      await getBroker(installation?.installationId);
    }

    onClick(ROUTES.InstallationSettings(installation?.installationId));
  };

  const onClick = async (redirectRoute: string) => {
    if (installation?.integrationType === IntegrationType.Exalus) {
      const exalusCredentials = getExalusInstallationCredentials(installation.installationId);
      if (exalusCredentials) {
        turnOnBackdrop();
        const res = await connect(exalusCredentials, true);

        if (res) {
          if (!isLocalApp) {
            selectInstallation(installation?.installationId, () => {
              setSelectedExalusCredentials(exalusCredentials);
              redirectTo(redirectRoute, true);
              turnOffBackdrop();
            });
          } else {
            setSelectedInstallationId(installation.installationId);
            setSelectedExalusCredentials(exalusCredentials);
            redirectTo(redirectRoute, true);
            turnOffBackdrop();
          }
        } else {
          setSelectedExalusCredentials(null);
        }
      } else {
        history.push(
          ROUTES.ExalusLogin(
            (installation?.externalPayload as ExalusInstallationPayload).serialNumber,
            (installation?.externalPayload as ExalusInstallationPayload).pin,
            installation.installationId,
          ),
        );
      }
    } else {
      await connectionApi.DisconnectAsync();

      selectInstallation(installation?.installationId, () => {
        storage.setItem('lastActiveTabInChannelList', 0);
        storage.setItem('lastActiveTabInDeviceList', String(0));

        setSelectedExalusCredentials(null);
        redirectTo(redirectRoute);
        turnOffBackdrop();
      });
    }
  };

  if (!installation) return null;

  const share = (installation: UserInstallation) => {
    if (installation.integrationType === IntegrationType.Exalus) {
      setExalusQrCode({
        serialNumber: (installation.externalPayload as ExalusInstallationPayload).serialNumber || '',
        pin: (installation.externalPayload as ExalusInstallationPayload).pin || '',
      });
    } else {
      selectInstallation(installation?.installationId, () => {
        setSelectedInstallationId(installation.installationId);
        turnOffBackdrop();
        shareInstallation(installation.installationId);
      });
    }
  };

  const signOffFromInstallation = () => {
    deleteUserFromInstallation(user.profile.id, installation.installationId, () => {
      refetchInstallations();
      setSelectedInstallationId(undefined);
      setSignOffPopup(false);
    });
  };

  const goToRemovePage = async () => {
    if (installation?.integrationType === IntegrationType.Lavva) {
      await getBroker(installation?.installationId);
    }

    history.push(ROUTES.InstallationRemove(installation.installationId, installation.name));
  };

  return (
    <div className="installation-item" style={style} ref={setNodeRef}>
      <button className="p-l-0 p-r-0" onClick={onInstallationClick}>
        <InstallationIcon
          iconName={installation.iconName || InstallationIconTypeEnum.INSTALLATION_0}
          activeColor={installation.hexColor}
        />
        {installation.installationId === selectedInstallationId && (
          <IconStatus className="installation-item__active" isOn />
        )}
      </button>
      <h3 className="installation-item__name" onClick={onInstallationClick}>
        {installation.name}
      </h3>
      {isDraggable ? (
        <div className="installation-item__draggable-handle" {...attributes} {...listeners}>
          <IconSortHandle />
        </div>
      ) : (
        <div className="installation-item__menu">
          <PopUpNav
            className="popup-nav--reverse"
            links={
              installation.accessType === InstallationAccessType.Owner ||
              installation.accessType === InstallationAccessType.Admin
                ? [
                    ...(!isLocalApp
                      ? [{ label: t('settings'), onClick: openInstallationSettings, icon: <IconSettings /> }]
                      : []),
                    ...(installation.integrationType === IntegrationType.Exalus
                      ? [
                          {
                            label: t('exalus.settingsController'),
                            onClick: openExalusControllerSettings,
                            hasLineBelow: false,
                            icon: <IconSettings />,
                          },
                        ]
                      : []),
                    {
                      label: t('share'),
                      onClick: () => share(installation),
                      hasLineBelow: true,
                      icon: <IconShare />,
                    },
                    ...(installation.accessType === InstallationAccessType.Owner && !isLocalApp
                      ? [{ label: t('remove_installation'), onClick: goToRemovePage, icon: <IconDelete /> }]
                      : []),
                    ...(installation.accessType === InstallationAccessType.Admin
                      ? [{ label: t('signOffInstallation'), onClick: () => setSignOffPopup(true), icon: <IconExit /> }]
                      : []),
                  ]
                : [{ label: t('signOffInstallation'), onClick: () => setSignOffPopup(true), icon: <IconExit /> }]
            }
          />
        </div>
      )}
      <DialogConfirmation
        show={signOffPopup}
        setShow={setSignOffPopup}
        header={t('signOffTitle')}
        content={t('signOffContent', { name: installation.name })}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={t('signOffInstallation')}
        secondaryBtnAction={() => setSignOffPopup(false)}
        primaryBtnAction={signOffFromInstallation}
        direction="column"
      />
      <ControllerQrCode exalusQrCode={exalusQrCode} setShow={setExalusQrCode} />
    </div>
  );
};
