import { ExalusInstallationCredentials } from '../../../types/exalus';

const KEY = 'EXALUS_DATA_STORAGE';

export interface ExalusStorageInterface {
  installationsCredentials: ExalusInstallationCredentials[];
  remindUpdateController: Date | null;
}

// It should never have happened
if (!window) {
  console.error('Window Local Storage Missing.  No data will be stored locally.');
}

export const defaultState: ExalusStorageInterface = {
  installationsCredentials: [],
  remindUpdateController: null,
};

let data: ExalusStorageInterface = { ...defaultState };

const dataString = window.localStorage.getItem(KEY);

if (dataString) {
  try {
    const parsedData: ExalusStorageInterface = JSON.parse(dataString);
    if (parsedData) {
      data = parsedData;
    }
  } catch (e) {
    console.error(`LocalStorage value of key: "${KEY}" is not a valid JSON.`);
    // Temporary option of clearing cache on error
    window.localStorage.removeItem(KEY);
    data = defaultState;
  }
}

export const getItem = <Key extends keyof ExalusStorageInterface>(key: Key): ExalusStorageInterface[Key] => {
  return data[key];
};

export const setItem = <Key extends keyof ExalusStorageInterface>(
  key: Key,
  value: ExalusStorageInterface[Key],
): void => {
  data[key] = value;
  window.localStorage.setItem(KEY, JSON.stringify(data));
};

export const clearOneCredentials = (installationId?: string): void => {
  const installationsCredentials = getItem('installationsCredentials') || [];

  const deletedIndex = installationsCredentials.findIndex((x) => x.id === installationId);
  if (deletedIndex !== -1) {
    installationsCredentials.splice(deletedIndex, 1);
    window.localStorage.setItem(KEY, JSON.stringify({ ...defaultState, installationsCredentials }));
  }
};
