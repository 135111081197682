import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { LightTransitionTime } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/Rdp21ConfigParameters';
import { ISlr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/ISlr22ConfigService';
import { Slr22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/LedController/Slr22ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { DimmingTimeParams } from '../../../device-details/types';

interface ComponentProps {
  device: IDevice;
  time: LightTransitionTime | undefined;
  channel: IDeviceChannel;
}

const DimmingBrighteningTimeForm: React.FC<ComponentProps> = ({ device, time, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<DimmingTimeParams>();

  useEffect(() => {
    if (time !== undefined) form.setValue('dimmingTime', time);
  }, [time]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<ISlr22ConfigService>(
      Slr22ConfigService.ServiceName,
    );

    const result = await configService.SetDimmingAndBrighteningTimeAsync(device, channel.Number, values.dimmingTime);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="dimmingTime"
          label={t('exalus.params.OnOffParams.DimmingAndBrightening')}
          placeholder={t('exalus.params.OnOffParams.DimmingAndBrightening')}
          inputType="number"
          min={0}
          max={240}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default DimmingBrighteningTimeForm;
