import { useApolloClient } from '@apollo/client';

export const useRefetchData = () => {
  const apolloClient = useApolloClient();

  const refetchInstallations = async () => {
    apolloClient.refetchQueries({ include: ['InstallationGetAll'] });
  };

  const refetchChannels = async () => {
    apolloClient.refetchQueries({ include: ['ChannelsGetAll'] });
  };

  const refetchDevices = async () => {
    apolloClient.refetchQueries({ include: ['DevicesGetAll'] });
  };

  const refetchActions = async () => {
    apolloClient.refetchQueries({ include: ['AllActions'] });
  };

  const refetchDashboard = async () => {
    apolloClient.refetchQueries({ include: ['DashboardItems'] });
  };

  const refetchTriggers = async () => {
    apolloClient.refetchQueries({ include: ['AllTriggers'] });
  };

  const refetchAll = async () => {
    apolloClient.refetchQueries({
      include: ['InstallationGetAll', 'ChannelsGetAll', 'DevicesGetAll', 'DashboardItems', 'AllActions'],
    });
  };

  return {
    refetchInstallations,
    refetchChannels,
    refetchDevices,
    refetchActions,
    refetchDashboard,
    refetchTriggers,
    refetchAll,
  };
};
