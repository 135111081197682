import React from 'react';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { Header } from '../../../../../../components';
import { GroupedDevice } from '../../types';
import DeviceItem from '../device-item';

interface ComponentProps {
  groupDevice: GroupedDevice;
  hiddenHeader?: boolean;
}

const DeviceGroup: React.FC<ComponentProps> = ({ groupDevice, hiddenHeader = false }) => (
  <>
    {!hiddenHeader ? <Header title={groupDevice.label} subheader /> : null}
    <div className="page-list__item">
      <ul className="page-list__items-list items-list">
        {groupDevice.devices.map((device: IDevice) => (
          <li key={device.Guid} className="items-list__item">
            <DeviceItem device={device} />
          </li>
        ))}
      </ul>
    </div>
  </>
);

export default DeviceGroup;
