import { Dispatch, SetStateAction } from 'react';
import { NotificationCode, NotificationParam } from '../data-access/gql-types/graphql';

export enum NotificationTypeEnum {
  INVITATION = 'INVITATION',
  OTHER = 'OTHER',
}

export enum NotificationInvitationType {
  InvitationAcceptedByInvitee = NotificationCode.InvitationAcceptedByInvitee,
  InvitationCanceledByCreator = NotificationCode.InvitationCanceledByCreator,
  InvitationReceived = NotificationCode.InvitationReceived,
  InvitationRejectedByInvitee = NotificationCode.InvitationRejectedByInvitee,
}

export interface NotificationInstallationInvitationInterface {
  type: NotificationTypeEnum.INVITATION;
  invitationType: NotificationInvitationType;
  invitationId: string;
  installationName: string;
  params: NotificationParam[];
  userName?: string;
}

export interface NotificationOtherInterface {
  type: NotificationTypeEnum.OTHER;
  title: string;
  message: string;
  params: NotificationParam[];
}

export interface NotificationInterface {
  id: string;
  wasDisplayed: boolean;
  isHidden: boolean;
  createdTimestampUTC: number;
  data: NotificationInstallationInvitationInterface | NotificationOtherInterface;
}

export interface NotificationCallback {
  onSuccess?: () => void;
  onError?: () => void;
}

export interface HookNotificationInterface {
  notificationList: NotificationInterface[];
  setNotificationList: Dispatch<SetStateAction<NotificationInterface[]>>;
  markAsDisplayed: (ids: string[], callback?: NotificationCallback) => void;
  markAsHidden: (ids: string[], callback?: NotificationCallback) => void;
}
