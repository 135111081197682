import { AvailableIcon } from '../../../../../types';
import { coverIcons } from './icons/cover';
import { lightIcons } from './icons/light';
import { meterIcons } from './icons/meter';
import { optimizerIcons } from './icons/optimizer';
import { switchIcons } from './icons/switch';
import { gateIcons } from './icons/gate';

export const availableIcons = {
  BLIND: coverIcons(),
  SWITCH: switchIcons(),
  METER: meterIcons(),
  OPTIMIZER: optimizerIcons(),
  LIGHT: lightIcons(),
  GATE: gateIcons(),
} as Record<string, AvailableIcon[]>;
