import React, { PointerEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ControlWrapper, IconChevron, Slider, SubmitButton } from '../../../../components';
import { GateStateDirectionInternal } from '../../../../data-access/gql-types/graphql';
import './blind.scss';

interface ComponentProps {
  sliderValue: number;
  handleSliderEvent: (event: PointerEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSliderValue: (value: React.SetStateAction<number>) => void;
  handleConstPercentageChange: (pos: number) => void;
  handleSubmit: () => void;
  gateAction: GateStateDirectionInternal;
  setGateAction: (value: React.SetStateAction<GateStateDirectionInternal>) => void;
}

export const GateChannelSettings: React.FC<ComponentProps> = (props) => {
  const { t } = useTranslation('common');
  const { sliderValue, handleSliderEvent, onChange, setSliderValue, handleSubmit } = props;

  const isOpened = useMemo(() => {
    return props.gateAction === GateStateDirectionInternal.Open;
  }, [props.gateAction]);

  const isClosed = useMemo(() => {
    return props.gateAction === GateStateDirectionInternal.Closed;
  }, [props.gateAction]);

  const isStopped = useMemo(() => {
    return props.gateAction === GateStateDirectionInternal.Stopped;
  }, [props.gateAction]);

  return (
    <>
      <ControlWrapper>
        <Slider value={sliderValue} onPointerUp={handleSliderEvent} onChange={onChange} showValue wider />
      </ControlWrapper>
      <div className="blind-direction-buttons p-l-24 p-r-24">
        <button className={classNames('button-action', { active: isOpened })}>
          <IconChevron
            withCircle
            direction="up"
            onClick={() => {
              setSliderValue(0);
              props.setGateAction(GateStateDirectionInternal.Open);
            }}
          />
        </button>
        <button
          className={classNames('button-action', { active: isStopped })}
          onClick={() => {
            props.setGateAction(GateStateDirectionInternal.Stopped);
          }}
        >
          {t('status.stop').toUpperCase()}
        </button>
        <button className={classNames('button-action', { active: isClosed })}>
          <IconChevron
            withCircle
            direction="down"
            onClick={() => {
              setSliderValue(100);
              props.setGateAction(GateStateDirectionInternal.Closed);
            }}
          />
        </button>
      </div>

      <SubmitButton onClick={handleSubmit} />
    </>
  );
};
