import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetAggregateMeasurements } from '../../../../api/modules/meter/meter.hooks';
import { MeasurementChannelKindNumber } from '../../../../api/modules/meter/meter.types';
import { DialogConfirmation } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import { useBackdropContext } from '../../../../hooks';
import { useRefetchData } from '../../../../hooks/refresh-data';
import { useErrors } from '../../../../hooks/use-errors';
import { toastError, toastSuccess } from '../../../../utils/toast';

interface ComponentProps {
  meterChannelId: string;
}

const MeasurementsConfiguration: React.FC<ComponentProps> = ({ meterChannelId }) => {
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { handleLavvaResolve } = useErrors();
  const { mutate } = useResetAggregateMeasurements();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchChannels } = useRefetchData();

  const handleReset = () => {
    if (meterChannelId) {
      turnOnBackdrop();

      mutate(
        { channelId: meterChannelId, kind: MeasurementChannelKindNumber.METER },
        {
          onSuccess: ({ data }) => {
            turnOffBackdrop();

            setShowDialog(false);
            handleLavvaResolve(data.publishStatus, () => {
              refetchChannels();
              toastSuccess({ content: t('resetMeasurements.success') });
            });
          },
        },
      );
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  return (
    <>
      <ArrowButton title={t('resetMeasurements.button')} onClick={() => setShowDialog(true)} className="m-t-24" small />
      <DialogConfirmation
        show={showDialog}
        setShow={setShowDialog}
        header={t('resetMeasurements.popupHeader')}
        content={t('resetMeasurements.popupContent')}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleReset}
        secondaryBtnAction={() => setShowDialog(false)}
        timer
      />
    </>
  );
};

export default MeasurementsConfiguration;
