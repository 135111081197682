import React from 'react';
import { ChannelTypeInternal } from '../../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../../types';
import Cover from '../cover';
import Light from '../light';
import Meter from '../meter';
import Optimizer from '../optimizer';
import Switch from '../switch';
import Gate from '../gate';

interface PropsInterface {
  channel: ChannelInterface;
}

const ChannelControls: React.FC<PropsInterface> = ({ channel }) => {
  if (!channel) return null;

  switch (channel.data.type) {
    case ChannelTypeInternal.Blind:
      return <Cover channel={channel} />;
    case ChannelTypeInternal.Switch:
      return <Switch channel={channel} />;
    case ChannelTypeInternal.Meter:
      return <Meter channel={channel} />;
    case ChannelTypeInternal.Optimizer:
      return <Optimizer channel={channel} />;
    case ChannelTypeInternal.Light:
      return <Light channel={channel} />;
    case ChannelTypeInternal.Gate:
      return <Gate channel={channel} />;
    default:
      return null;
  }
};

export default ChannelControls;
