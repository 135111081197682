import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { PortosService } from 'lavva.exalushome.portos';
import { WebCamsService } from 'lavva.exalushome.webcams';
import { WektaIntercomsService } from 'lavva.exalushome.wektaintercoms';
import { SetGeolocationResponseCode } from 'lavva.exalushome/build/js/Services/Controller/IGeolocationService';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import * as uuid from 'uuid';
import { useMutation } from '@apollo/client';
import { useGetBrokerToCreateInstallation } from '../../../api/modules/installation/installation.hooks';
import {
  CreateInstallationMutation,
  CreateInstallationMutationVariables,
  IntegrationType,
  UserInstallation,
  ExalusInstallationPayload,
} from '../../../data-access/gql-types/graphql';
import { CREATE_INSTALLATION } from '../../../data-access/mutations/installations';
import { environment } from '../../../environment';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { IntegrationDefaultEnum } from '../../../types';
import { ExalusInstallationCredentials } from '../../../types/exalus';
import { integrationTypeByIntegrationDefault } from '../../../utils/helpers/integrations';
import { isLocalApp } from '../../../utils/helpers/local-app';
import * as storage from '../../../utils/storage/lavva';
import { toastError, toastSuccess } from '../../../utils/toast';
import { useExalusContext } from '../../exalus/context';
import { useExalusSetGeolocation, useHandleGeolocationError } from '../../exalus/hooks';
import { useExalusRemoteStorage } from '../../exalus/hooks/storage.hooks';
import { ExalusIntegrationForm } from '../../exalus/pages/integration-create/types';
import { LoginForm } from '../../exalus/pages/integration-login/types';
import { saveExalusCredentials } from '../../exalus/utils';
import { StateCreateInstallationType, initialState, useCreateInstallationContext } from '../context';
import {
  RESET_INSTALLATION_CREATE,
  SET_CREATE_LOADING,
  SET_EXALUS_CREDENTIALS,
  SET_HEX_COLOR,
  SET_ICON_NAME,
  SET_INSTALLATION_NAME,
  SET_INTEGRATION_TYPE,
  SET_LOCATION_LAT,
  SET_LOCATION_LONG,
  SET_TIME_ZONE,
} from '../context/constants';
import { CreateInstallationControlForm } from '../types';

export const useCreateInstallation = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { installationList, updateInstallationList, selectInstallation, setSelectedInstallationId } = useInstallation();
  const [createInstallation] = useMutation<CreateInstallationMutation, CreateInstallationMutationVariables>(
    CREATE_INSTALLATION,
  );
  const { dispatch, state } = useCreateInstallationContext();
  const { handleGeolocationError } = useHandleGeolocationError();
  const { setConnected, setConnectionExists } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { mutate: changeControllerGeolocation } = useExalusSetGeolocation();
  const { getBroker } = useGetBrokerToCreateInstallation();
  const { saveExalusDataStorage } = useExalusRemoteStorage();

  const nextStep = (installationState: StateCreateInstallationType) => {
    if (environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus) {
      history.push(ROUTES.ExalusIntegrationCreate(installationState));
    } else {
      history.push(ROUTES.IntegrationsList());
    }
  };

  const handleSaveCreateInstallationForm = (form: CreateInstallationControlForm) => {
    dispatch({ type: SET_INSTALLATION_NAME, payload: form.installation_name });
    dispatch({ type: SET_HEX_COLOR, payload: form.hex_color });
    dispatch({ type: SET_ICON_NAME, payload: form.icon_name });
    dispatch({ type: SET_TIME_ZONE, payload: form.time_zone });
    dispatch({ type: SET_INTEGRATION_TYPE, payload: integrationTypeByIntegrationDefault() });

    if (form.location) {
      const locationParts = form.location.trim().split(',');
      dispatch({ type: SET_LOCATION_LAT, payload: parseFloat(locationParts[0]) });
      dispatch({ type: SET_LOCATION_LONG, payload: parseFloat(locationParts[1]) });
    }

    nextStep({
      ...initialState,
      installationName: form.installation_name,
      iconName: form.icon_name,
      hexColor: form.hex_color,
      timeZone: form.time_zone,
      integrationType: integrationTypeByIntegrationDefault(),
      locationLat: form.location ? parseFloat(form.location.trim().split(',')[0]) : null,
      locationLong: form.location ? parseFloat(form.location.trim().split(',')[1]) : null,
    });
  };

  const setSubmitLoading = (value: boolean) => {
    dispatch({ type: SET_CREATE_LOADING, payload: value });
  };

  const editIntegration = (integrationtype: IntegrationType, exalusCredentials?: ExalusIntegrationForm) => {
    dispatch({
      type: SET_INTEGRATION_TYPE,
      payload: integrationtype,
    });

    if (exalusCredentials) {
      dispatch({
        type: SET_EXALUS_CREDENTIALS,
        payload: {
          serialNumber: exalusCredentials.serialNumber,
          pin: exalusCredentials.pin,
        },
      });
    }
  };

  const resetInstallationCreate = () => {
    dispatch({ type: RESET_INSTALLATION_CREATE });
  };

  const finishExalusInstallation = async (
    installationData: UserInstallation,
    newInstallationList: UserInstallation[],
    formData: LoginForm,
  ) => {
    setConnected(true);
    setConnectionExists(true);

    const credentials: ExalusInstallationCredentials = {
      ...formData,
      id: installationData.installationId,
      serialNumber: (installationData.externalPayload as ExalusInstallationPayload).serialNumber,
      pin: (installationData.externalPayload as ExalusInstallationPayload).pin,
    };

    saveExalusCredentials(credentials);

    if (!isLocalApp) {
      await saveExalusDataStorage('iconName', installationData.iconName);
      await saveExalusDataStorage('hexColor', installationData.hexColor);
      await saveExalusDataStorage('name', installationData.name);
    }

    turnOffBackdrop();
    ExtaLifeService.Init();
    NetworkConfigurationService.Init();
    PortosService.Init();
    WebCamsService.Init();
    WektaIntercomsService.Init();

    if (isLocalApp) {
      storage.setLocalItem('installationList', newInstallationList);
      setSelectedInstallationId(installationData.installationId);
      history.push(ROUTES.Root());
      return;
    }

    if (newInstallationList.filter((x) => x.integrationType === IntegrationType.Exalus).length > 1) {
      window.location.href = ROUTES.InstallationCreated();
    } else {
      history.push(ROUTES.InstallationCreated());
    }
  };

  const finishCreatingInstallation = (installationData: UserInstallation, formData?: LoginForm) => {
    const newInstallationList = Array.from(installationList);
    let installationIndex = newInstallationList.findIndex(
      ({ installationId }) => installationId === installationData.installationId,
    );

    if (installationIndex < 0) installationIndex = newInstallationList.length;

    newInstallationList[installationIndex] = { ...installationData, order: newInstallationList.length + 1 };
    updateInstallationList(newInstallationList);

    if (!isLocalApp) {
      toastSuccess({ content: t('installationCreateSuccess') });

      selectInstallation(installationData.installationId, () => {
        if (
          installationData.integrationType === IntegrationType.Exalus &&
          (installationData.externalPayload as ExalusInstallationPayload).installationId &&
          formData
        ) {
          finishExalusInstallation(installationData, newInstallationList, formData);
        } else {
          resetInstallationCreate();
          history.push(ROUTES.InstallationCreated());
        }
      });
    } else {
      if (
        installationData.integrationType === IntegrationType.Exalus &&
        (installationData.externalPayload as ExalusInstallationPayload).installationId &&
        formData
      ) {
        finishExalusInstallation(installationData, newInstallationList, formData);
      }
    }
  };

  const submitInstallation = async (formData?: LoginForm) => {
    turnOnBackdrop();
    setSubmitLoading(true);
    await getBroker();

    createInstallation({
      variables: {
        input: {
          installationId: uuid.v4(),
          iconName: state.iconName,
          hexColor: state.hexColor,
          name: state.installationName,
          timeZone: state.timeZone,
          integrationType: state.integrationType,
          externalInstallationPayload: {
            exalusInstallationPayload:
              state.exalusCredentials.serialNumber && state.exalusCredentials.pin
                ? {
                    serialNumber: state.exalusCredentials.serialNumber,
                    pin: state.exalusCredentials.pin,
                  }
                : null,
          },
          ...(state.locationLat && state.locationLong
            ? {
                coordinates: {
                  latitude: state.locationLat,
                  longitude: state.locationLong,
                },
              }
            : {}),
        },
      },
      onCompleted: (data) => {
        if (data.createInstallation) {
          if (state.integrationType === IntegrationType.Exalus && state.locationLat && state.locationLong) {
            changeControllerGeolocation(
              { Latitude: state.locationLat, Longitude: state.locationLong },
              {
                onSuccess: (result: ResponseResult<SetGeolocationResponseCode>) => {
                  if (result.Type === SetGeolocationResponseCode.OK) {
                    finishCreatingInstallation(data.createInstallation as UserInstallation, formData);
                  } else {
                    handleGeolocationError(result.Type);
                  }
                },
              },
            );
          } else {
            finishCreatingInstallation(data.createInstallation as UserInstallation, formData);
          }
        } else {
          toastError({ content: tc('errors.somethingWentWrong') });
          // TODO errors
          turnOffBackdrop();
        }

        setSubmitLoading(false);
      },
      onError: () => turnOffBackdrop(),
    });
  };

  return {
    handleSaveCreateInstallationForm,
    resetInstallationCreate,
    editIntegration,
    submitInstallation,
    setSubmitLoading,
    finishCreatingInstallation,
  };
};
